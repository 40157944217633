import React from 'react';


const Modal = ({ job, onClose }) => {
    if (!job) return null;

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-75 overflow-y-auto h-full w-full z-50">
            <div className="relative top-20 mx-auto p-5 border w-11/12 max-w-4xl shadow-lg rounded-md bg-white">
                <div className="text-center">
                    <h3 className="text-xl font-semibold text-gray-900">{job.jobCardNo} - Job Details</h3>
                    <div className="mt-4 space-y-6">
                        <div className="p-4 shadow rounded bg-white">
                            <h4 className="font-semibold text-lg">Vehicle Information</h4>
                            <p className="text-sm text-gray-600"><strong>Make and Model:</strong> {job.vehicleMake} {job.vehicleModel}</p>
                            <p className="text-sm text-gray-600"><strong>Registration Number:</strong> {job.registrationNumber}</p>
                            <p className="text-sm text-gray-600"><strong>Date Created:</strong> {job.createdAt}</p>
                            <p className="text-sm text-gray-600"><strong>Status:</strong> {job.status}</p>
                        </div>


                        <div className="p-4 shadow rounded bg-white">
                            <h4 className="font-semibold text-lg">Technician Observations</h4>
                            <ul className="text-left list-none">
                                {job.technicianObservation.split(', ').map((observation, index) => (
                                    <li key={index} className="text-sm text-gray-600">{observation}</li>
                                ))}
                            </ul>
                        </div>

                        <div className="p-4 shadow rounded bg-white">
                            <h4 className="font-semibold text-lg">Parts Used</h4>
                            <table className="min-w-full divide-y divide-gray-200 mt-2">
                                <thead>
                                    <tr>
                                        <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Part Name</th>
                                        <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Quantity</th>
                                        <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Cost</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200 text-left">
                                    {job?.partsRequired?.map((part, index) => (
                                        <tr key={index}>
                                            <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-600">{part.partName}</td>
                                            <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-600">{part.quantity}</td>
                                            <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-600">{part.bestCostPrice?.toLocaleString()}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="mt-5">
                        <button
                            onClick={onClose}
                            className="px-4 py-2 bg-red-500 text-white text-sm font-medium rounded hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;