import React, { useState } from 'react';
import JobDetailsModal from '../../Vehicles/JobDetailsModal';

const JobCardList = ({ jobCards }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);

  const openModal = (jobCard) => {
    setSelectedJob(jobCard);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedJob(null);
  };

  return (
    <>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {jobCards.map((jobCard) => (
        <div
          key={jobCard.id}
          className="p-6 bg-gray-50 shadow-sm rounded-lg border border-gray-200"
        >
          <h4 className="text-xl font-semibold mb-3 text-indigo-600">
            Job Card #{jobCard.jobCardNo}
          </h4>
          <p className="text-gray-700 mb-2">
            <span className="font-medium">Date:</span>{' '}
            {new Date(jobCard.createdAt.seconds * 1000).toLocaleDateString()}
          </p>
          <p className="text-gray-700 mb-2">
            <span className="font-medium">Job Type:</span> {jobCard.jobType}
          </p>
          <p className="text-gray-700">
            <span className="font-medium">Status:</span>{' '}
            <span
              className={`inline-block px-3 py-1 text-sm font-medium rounded-full ${
                jobCard.status === 'Completed'
                  ? 'bg-green-100 text-green-800'
                  : jobCard.status === 'In Progress'
                  ? 'bg-yellow-100 text-yellow-800'
                  : 'bg-red-100 text-red-800'
              }`}
            >
              {jobCard.status}
            </span>
          </p>
          <button
              onClick={() => openModal(jobCard)}
              className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              View Details
            </button>
        </div>
      ))}
    </div>

    {isModalOpen && (
        <JobDetailsModal
          isOpen={isModalOpen}
          onClose={closeModal}
          job={selectedJob}
        />
      )}

    </>
  );
};

export default JobCardList;
