import React from 'react';

const DefaultSettings = ({ settings, handleEdit, confirmDelete }) => {
  return (
    <div className="bg-white shadow-md rounded p-4">
      <h3 className="text-lg font-semibold mb-4">Default Settings</h3>
      <Card
        title="Default Vehicle Margin"
        margin={settings.vehicleDefaultMargin}
        onEdit={() => handleEdit('vehicleDefaultMargin')}
        onDelete={() => confirmDelete('vehicleDefaultMargin')}
      />
      <Card
        title="Default Service Margin"
        margin={settings.serviceDefaultMargin}
        onEdit={() => handleEdit('serviceDefaultMargin')}
        onDelete={() => confirmDelete('serviceDefaultMargin')}
      />
    </div>
  );
};

const Card = ({ title, margin, onEdit, onDelete }) => (
  <div className="border border-gray-200 p-3 rounded">
    <div className="flex justify-between items-center">
      <span>{title}: {margin}%</span>
      <div>
        <button onClick={onEdit} className="text-blue-500 hover:text-blue-700 mr-2">Edit</button>
        <button onClick={onDelete} className="text-red-500 hover:text-red-700">Delete</button>
      </div>
    </div>
  </div>
);

export default DefaultSettings;