import React, { useEffect, useReducer } from 'react';
import { collection, query, getDocs } from 'firebase/firestore';
import { db } from '../../firebase'; 
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

// Reducer function to manage state
const revenueReducer = (state, action) => {
    switch (action.type) {
        case 'SET_REVENUE':
            return {
                ...state,
                labels: action.payload.labels,
                datasets: [{ ...state.datasets[0], data: action.payload.data }]
            };
        default:
            return state;
    }
};

const RevenueByServiceType = () => {
    const initialState = {
        labels: [],
        datasets: [{
            label: 'Revenue by Service Type',
            data: [],
            backgroundColor: [
                '#FF6384',
                '#36A2EB',
                '#FFCE56',
                '#E7E9ED',
                '#4BC0C0'
            ],
            hoverBackgroundColor: [
                '#FF6384',
                '#36A2EB',
                '#FFCE56',
                '#E7E9ED',
                '#4BC0C0'
            ]
        }]
    };

    const [revenueData, dispatch] = useReducer(revenueReducer, initialState);

    useEffect(() => {
        const fetchRevenueData = async () => {
            const snapshot = await getDocs(query(collection(db, "newInvoices")));
            const serviceMap = {};

            snapshot.forEach(doc => {
                const data = doc.data();
                data.cartItems.forEach(item => {
                    if (!serviceMap[item.description]) serviceMap[item.description] = 0;
                    serviceMap[item.description] += item.price * item.quantity;
                });
            });

            dispatch({
                type: 'SET_REVENUE',
                payload: {
                    labels: Object.keys(serviceMap),
                    data: Object.values(serviceMap)
                }
            });
        };

        fetchRevenueData();
    }, []);

    return (
        <div>
            <h2>Revenue by Service Type</h2>
            <Pie data={revenueData} />
        </div>
    );
};

export default RevenueByServiceType;
