import React from 'react';
import Spinner from './Spinner'; // Import a spinner component

const CustomerVehiclesModal = ({ vehicles, onClose, isLoading }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 overflow-auto">
      <div className="bg-white w-11/12 md:w-3/4 lg:w-2/3 xl:w-1/2 2xl:w-1/3 p-6 rounded-lg shadow-xl max-h-screen overflow-auto">
        <h2 className="text-xl font-semibold text-gray-800 mb-6">Customer Vehicles</h2>

        {isLoading ? (
          <div className="flex justify-center items-center h-32">
            <Spinner />
          </div>
        ) : (
          <div className="overflow-x-auto">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              {vehicles.map((vehicle, index) => (
                <div key={index} className="p-4 border border-gray-200 rounded-lg shadow-sm">
                  <p className="font-medium text-gray-700">GIN: <span className="text-gray-600">{vehicle?.GIN}</span></p>
                  <p className="text-gray-700">VIN: <span className="text-gray-600">{vehicle.VIN}</span></p>
                  <p className="text-gray-700">Registration: <span className="text-gray-600">{vehicle.registrationNumber}</span></p>
                  <p className="text-gray-700">Make: <span className="text-gray-600">{vehicle.vehicleMake}</span></p>
                  <p className="text-gray-700">Model: <span className="text-gray-600">{vehicle.vehicleModel}</span></p>
                  <p className="text-gray-700">Year: <span className="text-gray-600">{vehicle.year}</span></p>
                </div>
              ))}
            </div>
          </div>
        )}

        <button
          className="mt-6 bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded focus:outline-none focus:shadow-outline"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default CustomerVehiclesModal;
