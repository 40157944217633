import { useState, useEffect } from 'react';
import EmployeeForm from './EmployeeForm';
import { collection, getDocs } from 'firebase/firestore';
import { doc,  updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { toast, ToastContainer } from 'react-toastify';
import { sendEmailVerification, getAuth } from 'firebase/auth';



function Employee() {
    const [employees, setEmployees] = useState([]);
    const [roles, setRoles] = useState([]);
    const [update, setUpdate] = useState(false);


    useEffect(() => {
        const fetchEmployeesAndRoles = async () => {           
          
            const employeeData = await getDocs(collection(db, "employees"));
            setEmployees(employeeData.docs.map(doc => ({...doc.data(), id: doc.id})));
            // Fetch roles
            const rolesSnapshot = await getDocs(collection(db, "roles"));
            setRoles(rolesSnapshot.docs.map(doc => doc.data().name));
        }
        fetchEmployeesAndRoles();
    }, [update]);

    const handleRoleChange = async (id, newRole) => {
        try {
            const employeeRef = doc(db, 'employees', id);
            await updateDoc(employeeRef, { role: newRole });
            setUpdate(!update); // Trigger a re-fetch of the data
            toast.success("Role updated successfully");
        } catch (error) {
            toast.error("Error updating role: " + error.message);
        }
    }
   
    const handleSendVerificationEmail = async (email) => {
        const auth = getAuth();
        const user = auth.currentUser;
    
        if (user && user.email === email && !user.emailVerified) {
            try {
                await sendEmailVerification(user);
                toast.success("Verification email sent successfully");
            } catch (error) {
                toast.error("Failed to send verification email: " + error.message);
            }
        } else {
            toast.error("User not logged in or email does not match the logged-in user");
        }
    };
    

  
    return (
        <div className="flex flex-row space-x-4">
    <div className="w-1/2 p-4 bg-white shadow rounded-lg">
        <EmployeeForm onEmployeeCreate={() => setUpdate(!update)} />
    </div>

                            <div className="w-1/2 p-2 bg-white shadow rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200"> 
                            <thead className="bg-gray-50">
                                                <tr>
                                                <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                                                   
                                                <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        Email</th>
                                                        <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        Current Role</th>

                                                        <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        Change Role</th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white divide-y divide-gray-200">
                                                {employees.map(emp => (
                                                    <tr key={emp.id} className="border-b border-gray-200 bg-gray-50">
                                                        <td className="px-2 py-2 whitespace-nowrap text-xs font-medium text-gray-900">{emp.firstName} {emp.lastName}</td>
                                                        <td className="px-2 py-2 whitespace-nowrap text-xs text-gray-500">{emp.email}</td>
                                                        <td className="px-2 py-2 whitespace-nowrap text-xs text-gray-500">{emp.role}</td>
                                                        <td className="px-2 py-2 whitespace-nowrap text-xs text-gray-500">
                                                            <select
                                                                value={emp.role}
                                                                onChange={(e) => handleRoleChange(emp.id, e.target.value)}
                                                                className="rounded p-1 border-gray-300 text-xs"
                                                            >
                                                                {roles.map(role => (
                                                                    <option key={role} value={role}>{role}</option>
                                                                ))}
                                                            </select>

                                                            <button
                                                            className="ml-2 py-2 px-2 bg-blue-500 hover:bg-blue-700 text-white text-xs rounded"
                                                            onClick={() => handleSendVerificationEmail(emp.email)}
                                                        >
                                                            Verify Email
                                                        </button>
                                                        </td>
                                                       
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                            </div>
                            <ToastContainer />
</div>

    )
}

export default Employee;
