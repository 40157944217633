import React, { useEffect, useState, useMemo } from 'react';
import { collection, query, getDocs } from 'firebase/firestore';
import { db } from '../../firebase'; 
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import moment from 'moment/moment';
import BreadCrumb from '../BreadCrumb';
import MoonLoader from 'react-spinners/MoonLoader';


ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const pages = [
    { name: 'Reports', to: '/analytics', component: Link, current: false },
    { name: 'Job Card Status ', to: '/analytics/jobs', component: Link, current: true }, 
  
  ];
  

const JobStatusDashboard = () => {
  const [jobs, setJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const [jobsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    setIsLoading(true);
    const fetchJobs = async () => {
      const querySnapshot = await getDocs(query(collection(db, "jobs")));
      const jobData = [];

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const createdAt = DateTime.fromJSDate(data.createdAt.toDate()).toISODate();
        let dateClosed = "";
        if (data.status === "Closed" && data.tracker && data.tracker.length > 0) {
          const lastEntry = data.tracker[data.tracker.length - 1];
          dateClosed = DateTime.fromJSDate(lastEntry.Date.toDate()).toISODate();
        }

        jobData.push({
          ...data,
          createdAt,
          dateClosed
        });
      });

      setJobs(jobData);
      setFilteredJobs(jobData); 
      setIsLoading(false);
    };

    fetchJobs();
  }, []);

 

  useEffect(() => {
    let sortedJobs = [...jobs].filter(job =>
      job.customerName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      job.jobCardNo?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (sortConfig !== null) {
      sortedJobs.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    setFilteredJobs(sortedJobs);
  }, [searchTerm, jobs, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };



  const chartData = useMemo(() => ({
    labels: ['Open', 'Closed'],
    datasets: [
      {
        label: 'Job Status',
        data: [
          jobs.filter(job => job.status === "Open").length,
          jobs.filter(job => job.status === "Closed").length
        ],
        backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)'],
        borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
        borderWidth: 1,
      },
    ],
  }), [jobs]);

  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = filteredJobs.slice(indexOfFirstJob, indexOfLastJob);

  const paginate = pageNumber => setCurrentPage(pageNumber);

  return (
    <>
    <BreadCrumb pages={pages} />
    <div className="flex flex-col w-full">
      <div className="flex flex-wrap">
      

        <div className="md:w-3/5 w-full  bg-white rounded-lg shadow p-2">
            <h3 className='uppercase text-xl font-semibold text-gray-500'> Open & Closed Job Cards</h3>
          <div className="mb-2 flex justify-between items-center">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search jobs..."
              className="block w-2/5 rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />            
          </div>
          <table className="min-w-full leading-normal">
            <thead>
              <tr>
              <th className="px-3 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider cursor-pointer" onClick={() => requestSort('jobCardNo')}>Job Card No</th>
                <th className="px-3 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider cursor-pointer" onClick={() => requestSort('customerName')}>Customer</th>
                <th className="px-3 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider cursor-pointer" onClick={() => requestSort('createdAt')}>Date Created</th>
                <th className="px-3 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider cursor-pointer" onClick={() => requestSort('dateClosed')}>Date Closed</th>
              </tr>
            </thead>
            {isLoading ? (
                       <div className="fixed inset-0 flex items-center justify-center">
                       <MoonLoader size={100} />
                     </div>
                         ) : (
            <tbody>
              {currentJobs.map((job, index) => (
                <tr key={index}>
                   <td className="px-3 py-2 border-b border-gray-200 bg-white text-sm">
                  <Link to={`/vehicles/jobdetail/${job.jobCardNo}`} className="text-blue-500 hover:text-blue-800">
                    {job.jobCardNo}
                  </Link>
                </td>
                  <td className="px-3 py-2 border-b border-gray-200 bg-white text-sm">{job.customerName}</td>
                  <td className="px-3 py-2 border-b border-gray-200 bg-white text-sm">{moment(job.createdAt).format("DD-MMM-YYYY")}</td>
                  <td className="px-3 py-2 border-b border-gray-200 bg-white text-sm">
  {moment(job.dateClosed).isValid() ? moment(job.dateClosed).format("DD-MMM-YYYY") : 'Not Available'}
</td>

                </tr>
              ))}
            </tbody>
             )}
          </table>
          <div className="px-3 py-4 bg-white border-t flex flex-row items-center justify-center space-x-2">
            {Array.from({ length: Math.ceil(filteredJobs.length / jobsPerPage) }, (_, i) => (
              <button
                key={i + 1}
                onClick={() => paginate(i + 1)}
                className={`text-xs text-gray-700 leading-none rounded border p-2 ${currentPage === i + 1 ? 'bg-blue-500 text-white' : 'bg-white'}`}
              >
                {i + 1}
              </button>
            ))}
          </div>
        </div>

        <div className="md:w-2/5 w-full bg-white rounded-lg shadow p-1 ">
          <Bar data={chartData} options={{ responsive: true, plugins: { legend: { position: 'top' }, title: { display: true, text: 'Job Status Count' } } }} />
        </div>

      </div>
    </div>
    </>
  );
};

export default JobStatusDashboard;