import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc, collection, getDocs, Timestamp } from 'firebase/firestore';
import { useState, useEffect } from 'react';
import { db,auth } from "../../firebase";
import { toast, ToastContainer } from 'react-toastify';



function EmployeeForm({onEmployeeCreate}) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [role, setRole] = useState('');
   const [roles, setRoles] = useState([]);
  const [manager, setManager] = useState('');
  const [managers, setManagers] = useState([]);
  
 

  useEffect(() => {
    const fetchRolesAndManagers = async () => {
      const rolesSnapshot = await getDocs(collection(db, "roles"));
      const managersSnapshot = await getDocs(collection(db, "employees")); 
      const fetchedRoles = rolesSnapshot.docs.map(doc => doc.data().name);
      const fetchedManagers = managersSnapshot.docs.map(doc => ({
        id: doc.id,
        name: `${doc.data().firstName} ${doc.data().lastName}`
      }));
      setRoles(fetchedRoles);
      setManagers(fetchedManagers);
    };
    fetchRolesAndManagers();
  }, []);


  

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!firstName || !lastName || !email || !role || !password || !manager) {
      toast.error("Please fill all fields");
      return;
    }

      // Check if the startDate is a valid date




    try {
      const createUser = await createUserWithEmailAndPassword(auth, email, password);
      const uid = createUser.user.uid;
      await setDoc(doc(db, "employees", uid), {
        firstName,
        lastName,
        email,
        role,
        uid,
        designatedManagerId: manager,
        startDate: Timestamp.fromDate(new Date()),
        annualLeaveDays: 0,
        casualLeaveDays: 0
      });
      onEmployeeCreate();
      toast.success("Employee Created Successfully");
      setEmail('');
      setPassword('');
      setFirstName('');
      setLastName('');
      setRole('');
      setManager('');
        } catch (error) {
      toast.error('Error creating the user: ' + error.message);
    }
  }

  return (
    <> 
      

     <form onSubmit={handleSubmit} className="space-y-4 bg-white p-5 rounded shadow">
      
     <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label htmlFor="firstName" className="block text-sm font-medium leading-6 text-gray-900">
                First name
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  autoComplete="firstName"
                  placeholder="First Name"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="lastName" className="block text-sm font-medium leading-6 text-gray-900">
                Last name
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Last Name"
                  autoComplete="lastName"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  type="email"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                Password
              </label>
              <div className="mt-2">
                <input
                type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>


            <div className="sm:col-span-2">
              <label htmlFor="role" className="block text-sm font-medium leading-6 text-gray-900">
                Role
                    </label>
                    <div className="mt-2">
                    <select
                value={role}
                onChange={(e) => setRole(e.target.value)}
                className="border-2 rounded w-full py-2 px-3"
                required
              >
                <option value="">Select a Role</option>
                {roles.map((role, index) => (
                  <option key={index} value={role}>{role}</option>
                ))}
              </select>
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label htmlFor="manager" className="block text-sm font-medium leading-6 text-gray-900">
                      Designated Manager
                    </label>
                    <div className="mt-2">
                      <select
                        value={manager}
                        onChange={(e) => setManager(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      >
                        <option value="">Select a Manager</option>
                        {managers.map((mgr) => (
                          <option key={mgr.id} value={mgr.id}>{mgr.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>


                  <div className="sm:col-span-2">
                  <div className="mt-10">
                  <button
          type="submit"
          className="text-sm bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Create Employee
        </button>
        </div>
        </div>
            

</div>
      </form>
      <ToastContainer />
    </>
  );
}

export default EmployeeForm;