import React, { useState } from 'react';

const InspectionChecklist = ({ updateChecklist }) => {
  // Initialize checklist with "OK" as default for all items
  const initializeChecklist = () => {
    
    const initialChecklist = {
      exteriorItems: createDefaultState(exteriorItems),
      underHoodItems: createDefaultState(underHoodItems),
      trunkItems: createDefaultState(trunkItems),
      interiorItems: createDefaultState(interiorItems),
    };
    return initialChecklist;
  };

  const createDefaultState = (items) => {
    const state = {};
    items.forEach(item => {
      state[item] = 'OK';
    });
    return state;
  };

  const [checklist, setChecklist] = useState(initializeChecklist());

  const handleRadioChange = (section, item, value) => {
    const updatedChecklist = {
      ...checklist,
      [section]: {
        ...checklist[section],
        [item]: value,
      },
    };
    setChecklist(updatedChecklist);
    updateChecklist(updatedChecklist);  // Ensure the latest data is passed to the parent
  };

  return (
    <div className="container mx-auto p-2 bg-white shadow-lg">
      <h2 className="text-center text-xl font-bold mb-2">WAC PHYSICAL INSPECTION CHECKLIST</h2>
      <div className="grid grid-cols-2 gap-4 text-sm">
        <Section 
          title="A. EXTERIOR - EXT SECTION" 
          items={exteriorItems} 
          sectionKey="exteriorItems" 
          checklist={checklist} 
          handleRadioChange={handleRadioChange} 
        />
        <Section 
          title="B. UNDER THE HOOD - UTH SECTION" 
          items={underHoodItems} 
          sectionKey="underHoodItems" 
          checklist={checklist} 
          handleRadioChange={handleRadioChange} 
        />
        <Section 
          title="C. IN THE TRUNK - ITT SECTION" 
          items={trunkItems} 
          sectionKey="trunkItems" 
          checklist={checklist} 
          handleRadioChange={handleRadioChange} 
        />
        <Section 
          title="D. INTERIOR - INT SECTION" 
          items={interiorItems} 
          sectionKey="interiorItems" 
          checklist={checklist} 
          handleRadioChange={handleRadioChange} 
        />
      </div>
    </div>
  );
};

const Section = ({ title, items, sectionKey, checklist, handleRadioChange }) => (
  <div className="p-4 border rounded-lg">
    <h3 className="font-semibold text-lg mb-2">{title}</h3>
    {items.map((item, index) => (
      <div key={index} className="flex items-center justify-between mb-1">
        <span className="flex-1 text-sm">{item}</span>
        <div className="flex space-x-2">
          <label className="flex items-center space-x-1">
            <input
              type="radio"
              name={`${sectionKey}-${item}`}
              value="OK"
              checked={checklist[sectionKey][item] === 'OK'}
              onChange={() => handleRadioChange(sectionKey, item, 'OK')}
              className="form-radio"
            />
            <span className="text-xs">OK</span>
          </label>
          <label className="flex items-center space-x-1">
            <input
              type="radio"
              name={`${sectionKey}-${item}`}
              value="X"
              checked={checklist[sectionKey][item] === 'X'}
              onChange={() => handleRadioChange(sectionKey, item, 'X')}
              className="form-radio"
            />
            <span className="text-xs">X</span>
          </label>
          <label className="flex items-center space-x-1">
            <input
              type="radio"
              name={`${sectionKey}-${item}`}
              value="N/A"
              checked={checklist[sectionKey][item] === 'N/A'}
              onChange={() => handleRadioChange(sectionKey, item, 'N/A')}
              className="form-radio"
            />
            <span className="text-xs">N/A</span>
          </label>
        </div>
      </div>
    ))}
  </div>
);

const exteriorItems = [
  "Bumper/Grille/Bonnet/Fenders",
  "Doors/Glass/Pillars/Running Boards",
  "Windshields/Wipers/Mirrors",
  "Headlights/Rear lights/Fender lights",
  "Roof rack/Door handles/Mudflaps",
  "Emblems/logos",
  "Wheel nuts/wheel covers"
];

const underHoodItems = [
  "Oil spill",
  "Brake fluid level",
  "Engine oil level",
  "Power steering fluid level",
  "Coolant level",
  "Reservoir covers - Brake, coolant, steering, engine",
  "Battery Marking",
  "Fuse box covers",
  "Bonnet shock and Mat"
];

const trunkItems = [
  "Spare tire and accessories",
  "Jack",
  "Wheel spanner",
  "Caution sign",
  "Extinguisher",
  "Upper boot rug",
  "Boot mat"
];

const interiorItems = [
  "Dashboard/Control buttons",
  "Instrument cluster/MI Lights",
  "Seats/Upholstery conditions",
  "Floor mats/Rugs/Seat covers",
  "Door handles/switches",
  "Radio/AC",
  "Horn/Power windows"
];

export default InspectionChecklist;
