import React, { useState } from 'react';
import '../VehicleInspectionReport.css'

const RecommendationModal = ({ isOpen, onClose, onSubmit }) => {
  const [recommendation, setRecommendation] = useState('');

  const handleSubmit = () => {
    onSubmit(recommendation);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-40 flex justify-center items-center p-4">
      <div className="bg-white rounded-lg shadow-xl p-6 w-full max-w-lg">
        <h2 className="text-xl font-semibold mb-4">Recommendations</h2>
        <textarea
          className="w-full p-3 border border-gray-300 rounded"
          rows="4"
          placeholder="Enter your recommendations"
          value={recommendation}
          onChange={(e) => setRecommendation(e.target.value)}
        />
        <div className="flex justify-end space-x-2 mt-4">
          <button 
            className="py-2 px-4 bg-gray-300 hover:bg-gray-400 text-gray-800 rounded"
            onClick={onClose}
          >
            Cancel
          </button>
          <button 
            className="py-2 px-4 bg-green-500 hover:bg-green-600 text-white rounded"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};


export default RecommendationModal;
