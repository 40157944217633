// CustomerTable.js
import React from 'react';
import MoonLoader from 'react-spinners/MoonLoader';
import ActionsButton from './ActionButtons';

const CustomerTable = ({
  customers,
  pagesVisited,
  customersPerPage,
  handleSort,
  sortConfig,
  isLoading,
  handleVehiclesButtonClick,
  openEditModal,
  deleteCustomer,
}) => {
  const displayCustomers = customers.slice(
    pagesVisited,
    pagesVisited + customersPerPage
  );

  return (
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          {[
            'customerNumber',
            'firstName',
            'lastName',
            'email',
            'phoneNumber',
            'customerType',           
          ].map((key) => (
            <th
              key={key}
              scope="col"
              className="px-4 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
              onClick={() => handleSort(key)}
            >
              {key.charAt(0).toUpperCase() + key.slice(1)}{' '}
              {sortConfig.key === key
                ? sortConfig.direction === 'asc'
                  ? '▲'
                  : '▼'
                : ''}
            </th>
          ))}
          <th
            scope="col"
            className="px-6 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Actions
          </th>
        </tr>
      </thead>

      {isLoading ? (
        <tbody>
          <tr>
            <td colSpan="8" className="text-center py-4">
              <MoonLoader size={100} />
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody className="bg-white divide-y divide-gray-200">
          {displayCustomers.map((customer, index) => (
            <tr key={index}>
              <td className="px-4 py-1 whitespace-nowrap">
                <div className="text-xs text-gray-900">
                  {customer.customerNumber}
                </div>
              </td>
              <td className="px-4 py-1 whitespace-nowrap">
                <div className="text-sm text-gray-900">
                  {customer.firstName}
                </div>
              </td>
              <td className="px-4 py-1 whitespace-nowrap">
                <div className="text-sm text-gray-900">
                  {customer.lastName}
                </div>
              </td>
              <td className="px-4 py-1 whitespace-nowrap">
                <div className="text-sm text-gray-900">{customer.email}</div>
              </td>
              <td className="px-4 py-1 whitespace-nowrap">
                <div className="text-xs text-gray-900">
                  {customer.phoneNumber}
                </div>
              </td>
              <td className="px-4 py-1 whitespace-nowrap">
                <div className="text-sm text-gray-900">
                  {customer.customerType}
                </div>
              </td>
             

              <td className="px-4 py-1 whitespace-nowrap text-sm text-gray-500">
                <ActionsButton
                  customer={customer}
                  handleVehiclesButtonClick={handleVehiclesButtonClick}
                  openEditModal={openEditModal}
                  deleteCustomer={deleteCustomer}
                />
              </td>
            </tr>
          ))}
        </tbody>
      )}
    </table>
  );
};

export default CustomerTable;
