import React from 'react';

import AllFeedback from './FeedbackImport/AllFeedback';
import FeedBackStats from './FeedbackImport/FeedbackStats';
import ComplaintsChart from './FeedbackImport/ComplaintsChart';
import EscalationMode from './FeedbackImport/EscalationMode';
import { Link } from 'react-router-dom';
import BreadCrumb from '../BreadCrumb';

const pages = [
  { name: 'Customer Care', to: '/customers', component: Link, current: false },
  { name: 'Customer Feedback Admin', to: '/customers/feedback', component: Link, current: true },  
  
];

const FeedbackManager = () => {
   

    return (
        <>
             <BreadCrumb pages={pages} />

         <div className="flex md:flex-row grid-gap-4 mt-4">

             <div className="md:w-4/5 pr-2 pl-2 grid-margin stretch-card">

             <AllFeedback />
                </div>

                <div className="md:w-1/5 pr-2 pl-4 grid-margin border-gray-300 stretch-card items-center mb-2 pt-4 text-black text-sm ">
                    <FeedBackStats />
                    </div>
                 </div>

                 <div className="flex md:flex-row grid-gap-4 mt-4 items-stretch"> 

                 <div className="md:w-2/5 pr-4 pl-4 grid-margin stretch-card">
                        <div className="relative flex flex-col min-w-0 rounded break-words border bg-white border-1 border-gray-300 h-full">
                          <div className="flex-auto p-6">
                            <div className="clearfix">
                              <h4 className="mb-4 float-left">Top Escalation Channel</h4>
                              <div id="vehicle-chart-legend" className="rounded-legend legend-vertical legend-bottom-left pt-4">
                               <EscalationMode />
                            </div>
                            </div>
                          
                          </div>
                        </div>
                      </div>


                      <div className="md:w-3/5 pr-4 pl-4 grid-margin stretch-card">
                        <div className="relative flex flex-col min-w-0 rounded break-words border bg-white border-1 border-gray-300 h-full">
                          <div className="flex-auto p-6">
                            <h4 className="mb-3">Top Complaints </h4>
                         
                            <div id="vehicle-chart-legend" className="rounded-legend legend-vertical legend-bottom-left pt-4">
                              < ComplaintsChart />
                            </div>
                          </div>
                        </div>
                  </div>
                 
                 
                 
                 </div>
      
        
        
        </>
        
    );
};

export default FeedbackManager;
