import Modal from 'react-modal';

const TaskDetailsModal = ({ isOpen, task, onClose }) => {
    return (
        <Modal isOpen={isOpen} onRequestClose={onClose} className="max-w-2xl mx-auto mt-10 p-5 bg-white rounded-lg shadow-xl">
            <h2 className="text-xl font-semibold mb-4">Task Details: {task.taskTitle}</h2>
            <table className="min-w-full leading-normal">
                <thead>
                    <tr>
                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                            Description
                        </th>
                        <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                            Status
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {task.subtasks.map((subtask, index) => (
                        <tr key={index}>
                            <td className="px-5 py-3 border-b border-gray-200 bg-white text-sm">
                                {subtask.description}
                            </td>
                            <td className="px-5 py-3 border-b border-gray-200 bg-white text-sm">
                                {subtask.status}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <button onClick={onClose} className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition">
                Close
            </button>
        </Modal>
    );
};

export default TaskDetailsModal;