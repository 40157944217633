import React from 'react';
import {  PlusCircleIcon,HomeModernIcon,  ShoppingCartIcon,BanknotesIcon } from '@heroicons/react/20/solid'
import BreadCrumb from '../BreadCrumb';
import { useAuth } from '../Auth/Auth';

  import { Link, Navigate} from 'react-router-dom'

  const actions = [


    {
      title: 'New Job Card Prices',
      to: '/procurement/jobcardpricing',
      desc: "Add Cost Price to New Job Cards",
      icon: BanknotesIcon,
      iconForeground: 'text-rose-700',
      iconBackground: 'bg-rose-50',
    },   

    
    {
      title: 'Edit Job Card Prices',
      to: '/procurement/editjobcardpricing',
      desc: "Edit Cost Price for Open Job Cards",
      icon: HomeModernIcon,
      iconForeground: 'text-rose-700',
      iconBackground: 'bg-rose-50',
    },   
   
    {
      title: 'Suppliers',
      to: '/procurement/suppliers',
      desc: "Register, Update or Delete Supplier Contact Details",
      icon: PlusCircleIcon,
      iconForeground: 'text-rose-700',
      iconBackground: 'bg-rose-50',
    },   

    {
      title: 'Inventory',
      to: '/procurement/inventory',
      icon: HomeModernIcon,
      component: Link,
      desc: "Add Items Directly Purchased to Inventory",
      iconForeground: 'text-sky-700',
      iconBackground: 'bg-sky-50',
    },  
      
    { 
      title: 'Make Purchase Request',
      to: '/procurement/buyItem',
      desc: "Requisition from a Registered Vendor",
      component: Link,
      icon: ShoppingCartIcon,
      iconForeground: 'text-purple-700',
      iconBackground: 'bg-purple-50',
    },

    

    {
      title: 'Update Delivery Status',
      to: '/procurement/manage',
      desc: "Update the Status of Items Ordered.",
      component: Link,
      icon: ShoppingCartIcon,
      iconForeground: 'text-purple-700',
      iconBackground: 'bg-purple-50',
    },               
     
      
  ]
  
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const pages = [
    { name: 'Procurement', to: '/procurement', component: Link, current: true },
     ];


const Procurement = () => {
  const { role, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>; 
  }

  if (role !== 'Procurement' && role !== 'Admin') {
   
    return <Navigate to="/unauthorized" replace />; 
  }


  return (
    <>
    <BreadCrumb pages={pages} />

     <div className='flex justify-center items-center pt-6'>
    <div className="divide-y divide-gray-50 grid grid-cols-3 gap-3 overflow-hidden rounded-lg bg-gray-200 shadow">
        {actions.map((action, actionIdx) => (
            <div
                key={action.title}
                className={classNames(
                    'group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 transform transition duration-500 ease-in-out hover:scale-105'
                )}
            >
                <div className="flex items-center justify-center">
                    <span
                        className={classNames(
                            action.iconBackground,
                            action.iconForeground,
                            'inline-flex rounded-lg p-3 ring-4 ring-white'
                        )}
                    >
                        <action.icon className="h-6 w-6" aria-hidden="true" />
                    </span>
                </div>
                <div className="mt-8">
                    <h3 className="text-base font-semibold text-center leading-6 text-gray-900">
                        <Link to={action.to} className="focus:outline-none">
                            {/* Extend touch target to entire panel */}
                            <span className="absolute inset-0" aria-hidden="true" />
                            {action.title}
                        </Link>
                    </h3>
                    <p className="mt-2 text-sm text-center text-gray-500">
                        {action.desc} 
                    </p>
                </div>
                <span
                    className="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400"
                    aria-hidden="true"
                >
                    <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                    </svg>
                </span>
            </div>
        ))}
    </div>
</div>


 

</>
  )
}

export default Procurement