import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Completion = ({ formData,  onBack, onComplete, vehicle }) => {
  const [isCompleted, setIsCompleted] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    onComplete();
    setIsCompleted(true);
  };

  const handleViewReport = () => {
    navigate('/vehicles/inspectionReport', { state: { formData, vehicle } });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6 bg-white shadow-md rounded-lg px-8 pt-6 pb-8 mb-4">
      <div className="flex justify-center items-center mt-4">
        <div className="card w-full max-w-md">
          <div className="w-full h-full bg-white border rounded-md shadow-md flex items-center justify-center">
            <svg className="w-24 h-24 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
            </svg>
            <h1 className="text-2xl font-bold text-gray-800 mt-8 text-center">Vehicle Inspection Completed!</h1>
          </div>
          
          <p className="text-lg text-gray-600 text-center mb-4 mt-4">
            Click the "Complete" button to Generate a report
          </p>
        </div>
      </div>
      <div className="flex justify-between">
        <button
          type="button"
          onClick={onBack}
          className="mt-2 bg-amber-500 text-white text-sm hover:bg-green-600 px-4 py-2 rounded font-medium rounded-md shadow-sm"
        >
          Back
        </button>
        <button
          type="submit"
          className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm 
          text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Complete
        </button>
      </div>
      {isCompleted && (
        <button
          onClick={handleViewReport}
          className="mt-4 inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm 
          text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
        >
          View Report
        </button>
      )}
    </form>
  );
};

export default Completion;
