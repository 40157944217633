// ProcurementsTable.js

import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { formatCurrency } from '../Help/helper';
import MoonLoader from 'react-spinners/MoonLoader';
import RequisitionDetails from './RequisitionDetails';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

const ProcurementsTable = () => {
  const [procurements, setProcurements] = useState([]);
  const [filteredProcurements, setFilteredProcurements] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  const [selectedProcurement, setSelectedProcurement] = useState(null);
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Search state
  const [searchQuery, setSearchQuery] = useState('');

  // Sort state
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });

  const openModal = (procurement) => {
    setSelectedProcurement(procurement);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProcurement(null);
  };

  useEffect(() => {
    const fetchProcurements = async () => {
      setIsLoading(true);
      const q = query(collection(db, 'procurements'), where('status', '==', 'Requested'));
      const querySnapshot = await getDocs(q);
      const fetchedProcurements = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setProcurements(fetchedProcurements);
      setIsLoading(false);
    };

    fetchProcurements();
  }, []);

  // Handle search
  useEffect(() => {
    const filtered = procurements.filter((procurement) => {
      const query = searchQuery.toLowerCase();
      return (
        procurement.procurementNumber.toLowerCase().includes(query) ||
        procurement.customerName.toLowerCase().includes(query) ||
        procurement.category.toLowerCase().includes(query) ||
        procurement.supplier.toLowerCase().includes(query)
      );
    });
    setFilteredProcurements(filtered);
    setCurrentPage(1); // Reset to first page on new search
  }, [searchQuery, procurements]);

  // Handle sorting
  const sortedProcurements = React.useMemo(() => {
    let sortableProcurements = [...filteredProcurements];
    if (sortConfig.key !== '') {
      sortableProcurements.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        // If sorting by grandTotal, ensure values are numbers
        if (sortConfig.key === 'grandTotal') {
          aValue = parseFloat(aValue);
          bValue = parseFloat(bValue);
        } else {
          aValue = aValue.toString().toLowerCase();
          bValue = bValue.toString().toLowerCase();
        }

        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableProcurements;
  }, [filteredProcurements, sortConfig]);

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentProcurements = sortedProcurements.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(sortedProcurements.length / itemsPerPage);

  // Handle sorting request
  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key !== key) {
      return null;
    }

    if (sortConfig.direction === 'ascending') {
      // Up arrow
      return <span className="ml-1">&#9650;</span>;
    } else {
      // Down arrow
      return <span className="ml-1">&#9660;</span>;
    }
  };

  return (
    <>
     
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center mb-2">
          <div className="sm:flex-auto">
                        <p className="mt-1 text-sm text-gray-700">
              All pending requisitions that require approval
            </p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <input
              type="text"
              placeholder="Search..."
              className="block w-full sm:text-sm border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>
        <div className="mt-2 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            {isLoading ? (
              <div className="flex items-center justify-center">
                <MoonLoader size={100} />
              </div>
            ) : (
              <div className="inline-block min-w-full py-1 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full divide-y bg-slate-600 divide-gray-300">
                  <thead>
                    <tr>
                      <th
                        className="py-1 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-white cursor-pointer sm:pl-6"
                        onClick={() => requestSort('procurementNumber')}
                      >
                        Procurement Number {getSortIcon('procurementNumber')}
                      </th>
                      <th
                        className="px-3 py-1 text-left text-xs font-medium uppercase tracking-wide text-white cursor-pointer"
                        onClick={() => requestSort('customerName')}
                      >
                        Customer Name {getSortIcon('customerName')}
                      </th>
                      <th
                        className="px-3 py-1 text-left text-xs font-medium uppercase tracking-wide text-white cursor-pointer"
                        onClick={() => requestSort('category')}
                      >
                        Category {getSortIcon('category')}
                      </th>
                      <th
                        className="px-3 py-1 text-left text-xs font-medium uppercase tracking-wide text-white cursor-pointer"
                        onClick={() => requestSort('supplier')}
                      >
                        Supplier {getSortIcon('supplier')}
                      </th>
                      <th
                        className="px-3 py-1 text-left text-xs font-medium uppercase tracking-wide text-white cursor-pointer"
                        onClick={() => requestSort('grandTotal')}
                      >
                        Grand Total {getSortIcon('grandTotal')}
                      </th>
                      <th
                        className="px-3 py-1 text-left text-xs font-medium uppercase tracking-wide text-white cursor-pointer"
                        onClick={() => requestSort('dateRequired')}
                      >
                        Date Required {getSortIcon('dateRequired')}
                      </th>
                      <th className="relative py-1 pl-3 pr-4 sm:pr-6 text-white font-medium uppercase text-xs">Actions</th>
                    </tr>
                  </thead>

                  <tbody className="divide-y divide-gray-200 bg-white">
                    {currentProcurements.map((procurement) => (
                      <tr key={procurement.id}>
                        <td className="whitespace-nowrap py-1 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {procurement.procurementNumber}
                        </td>
                        <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">
                          {procurement.customerName}
                        </td>
                        <td className="whitespace-nowrap px-3 py-1 text-sm uppercase text-gray-500">
                          {procurement.category}
                        </td>
                        <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">
                          {procurement.supplier}
                        </td>
                        <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">
                          {formatCurrency(procurement.grandTotal)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">
                          {procurement.dateRequired}
                        </td>
                        <td className="relative whitespace-nowrap py-1 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <button
                            onClick={() => openModal(procurement)}
                            className="px-4 py-1 text-sm text-white bg-blue-500 rounded hover:bg-blue-700"
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {/* Pagination controls */}
                <div className="mt-4 flex items-center justify-between">
                  <div>
                    <p className="text-sm text-gray-700">
                      Showing <span className="font-medium">{indexOfFirstItem + 1}</span> to{' '}
                      <span className="font-medium">
                        {Math.min(indexOfLastItem, sortedProcurements.length)}
                      </span>{' '}
                      of <span className="font-medium">{sortedProcurements.length}</span> results
                    </p>
                  </div>
                  <div>
                    <nav
                      className="inline-flex -space-x-px rounded-md shadow-sm"
                      aria-label="Pagination"
                    >
                      <button
                        onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                        disabled={currentPage === 1}
                        className="relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-l-md hover:bg-gray-50 disabled:opacity-50"
                      >
                        <span className="sr-only">Previous</span>
                        <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                      {[...Array(totalPages).keys()].map((number) => (
                        <button
                          key={number + 1}
                          onClick={() => setCurrentPage(number + 1)}
                          className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium ${
                            currentPage === number + 1
                              ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600'
                              : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'
                          }`}
                        >
                          {number + 1}
                        </button>
                      ))}
                      <button
                        onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                        disabled={currentPage === totalPages}
                        className="relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-r-md hover:bg-gray-50 disabled:opacity-50"
                      >
                        <span className="sr-only">Next</span>
                        <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </nav>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Modal */}
          {isModalOpen && selectedProcurement && (
            <RequisitionDetails procurementId={selectedProcurement.id} onClose={closeModal} />
          )}

        </div>
      </div>
    </>
  );
};

export default ProcurementsTable;
