import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from '../../firebase';
import { useNavigate, Link, Navigate } from 'react-router-dom';
import { ChevronDownIcon, ChevronUpIcon, PencilSquareIcon } from '@heroicons/react/20/solid';
import MoonLoader from 'react-spinners/MoonLoader';
import BreadCrumb from '../BreadCrumb';
import { useAuth } from '../Auth/Auth';
import { formatDate } from '../Help/helper';


const pages = [
  { name: 'Service Advisor', to: '/vehicles', component: Link, current: false },
  { name: 'Closed Job Cards', to: '/vehicles/closedjobs', component: Link, current: true },  
];



const ClosedJobs = () => {
  const [jobs, setJobs] = useState([]);
  const { role } = useAuth();
  const [sortConfig, setSortConfig] = useState({ key: 'jobCardNo', direction: 'ascending' });
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [jobsPerPage] = useState(10);
  const navigate = useNavigate();
  const [searchCategory, setSearchCategory] = useState('customerName');
  const [searchQuery, setSearchQuery] = useState('');
 

  useEffect(() => {
    fetchJobs();
  }, []);
 

  const fetchJobs = async () => {
    setIsLoading(true);
    const jobsCollection = collection(db, 'jobs');
    const openJobsQuery = query(jobsCollection, where('status', '==', 'Closed'));
    const jobsSnapshot = await getDocs(openJobsQuery);
    const jobsList = await Promise.all(
      jobsSnapshot.docs.map(async (docSnapshot) => {
        const jobData = docSnapshot.data();
        return {
          ...jobData,
          id: docSnapshot.id,
        };
      })
    );
    setJobs(jobsList);
    setIsLoading(false);
  };

  const handleSearch = async () => {
    if (!searchQuery) {
      fetchJobs();
      return;
    }

    setIsLoading(true);

    const jobsCollection = collection(db, 'jobs');
    let searchQueryRef;

    if (searchCategory === 'customerName') {
      searchQueryRef = query(jobsCollection, where('customerName', '>=', searchQuery), where('customerName', '<=', searchQuery + '\uf8ff'));
    } else if (searchCategory === 'VIN') {
      searchQueryRef = query(jobsCollection, where('VIN', '>=', searchQuery), where('VIN', '<=', searchQuery + '\uf8ff'));
    } else if (searchCategory === 'jobCardNo') {
      searchQueryRef = query(jobsCollection, where('jobCardNo', '>=', searchQuery), where('jobCardNo', '<=', searchQuery + '\uf8ff'));
    } else if (searchCategory === 'registrationNumber') {
      searchQueryRef = query(jobsCollection, where('registrationNumber', '>=', searchQuery), where('registrationNumber', '<=', searchQuery + '\uf8ff'));
    }

    const jobsSnapshot = await getDocs(searchQueryRef);
    const jobsList = jobsSnapshot.docs.map((docSnapshot) => ({
      id: docSnapshot.id,
      ...docSnapshot.data(),
    }));

    setJobs(jobsList);
    setIsLoading(false);
  };

  const sortData = (key, direction) => {
    setSortConfig({
      key: key,
      direction: direction
    });
  };

  const sortedJobs = () => {
    if (!sortConfig) return jobs;
    return [...jobs].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'ascending' ? -1 : 1;
      if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'ascending' ? 1 : -1;
      return 0;
    });
  };

  const currentJobs = sortedJobs().slice((currentPage - 1) * jobsPerPage, currentPage * jobsPerPage);
  const totalPages = Math.ceil(jobs.length / jobsPerPage);
  const changePage = (pageNumber) => setCurrentPage(pageNumber);

  const navigateToJobDetail = (jobCardNo) => {
    navigate(`/vehicles/jobupdate/${jobCardNo}`);
  };

  if (role !== 'Service Advisor' && role !== 'Admin') {
    return <Navigate to="/unauthorized" replace />;
  }


  return (
    <>
      <BreadCrumb pages={pages} />
      <div className="container mx-auto px-4">
        <div className="container mx-auto px-4 mt-4">
          {isLoading ? (
            <div className="flex justify-center items-center h-64">
              <MoonLoader size={50} color="#000" />
            </div>
          ) : (
            <div>
              <div className="flex items-center mb-4">
                <select
                  value={searchCategory}
                  onChange={(e) => setSearchCategory(e.target.value)}
                  className="mr-2 border rounded px-2 py-1 text-sm"
                >
                  <option value="customerName">Customer Name</option>
                  <option value="VIN">VIN</option>
                  <option value="jobCardNo">Job Card No</option>
                  <option value="registrationNumber">Registration No</option>
                </select>
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder={`Search by ${searchCategory}`}
                  className="border rounded px-2 py-1 text-sm flex-grow"
                />
                <button
                  onClick={handleSearch}
                  className="ml-2 bg-blue-500 text-white px-4 py-1 rounded text-sm"
                >
                  Search
                </button>
              </div>

              <table className="min-w-full divide-y divide-gray-200 shadow-sm border-b border-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {[
                      { label: 'Job No', key: 'jobCardNo' },
                      { label: 'Customer Name', key: 'customerName' },
                      { label: 'Reg No', key: 'registrationNumber' },
                      { label: 'VIN', key: 'VIN' },
                      { label: 'Vehicle Type', key: 'vehicleMake' },
                      { label: 'Date Created', key: 'createdAt' },
                    ].map((header) => (
                      <th
                        key={header.key}
                        className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                        onClick={() => {
                          const newDirection = sortConfig.key === header.key && sortConfig.direction === 'ascending' ? 'descending' : 'ascending';
                          sortData(header.key, newDirection);
                        }}
                      >
                        {header.label}
                        <span className="inline-block ml-2">
                          {sortConfig.key === header.key && (
                            sortConfig.direction === 'ascending'
                              ? <ChevronUpIcon className="h-4 w-4" />
                              : <ChevronDownIcon className="h-4 w-4" />
                          )}
                        </span>
                      </th>
                    ))}
                  
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {currentJobs.map(job => (
                    <tr key={job.id}>
                      <td className="px-2 py-1 whitespace-nowrap text-xs text-gray-500">{job.jobCardNo}</td>
                      <td className="px-2 py-1 whitespace-nowrap text-sm text-gray-900">{job.customerName}</td>
                      <td className="px-2 py-1 whitespace-nowrap text-sm text-gray-500">{job.registrationNumber}</td>
                      <td className="px-2 py-1 whitespace-nowrap text-sm text-gray-500">{job?.VIN}</td>
                      <td className="px-2 py-1 whitespace-nowrap text-sm text-gray-500">{`${job.vehicleMake} ${job.vehicleModel}`}</td>
                      <td className="px-2 py-1 whitespace-nowrap text-sm text-gray-500">{formatDate(job.createdAt.toDate())}</td>
                      <td className="px-2 py-1">
                        {/* Replace DropdownMenu with Icon */}
                        <button onClick={() => navigateToJobDetail(job.jobCardNo)} className="text-gray-500 hover:text-blue-500">
                          <PencilSquareIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
        <div className="flex justify-center mt-4">
          {Array.from({ length: totalPages }, (_, i) => (
            <button key={i} onClick={() => changePage(i + 1)} className={`px-2 py-1 mx-1 ${i + 1 === currentPage ? 'bg-blue-500 text-white' : 'bg-white text-blue-500'} border border-blue-500 rounded`}>
              {i + 1}
            </button>
          ))}
        </div>
      </div>
    </>
  );
};


export default ClosedJobs;

