import React, { useState, useEffect } from 'react';
import { collection, query, where, onSnapshot, getDoc, updateDoc, doc, Timestamp, addDoc } from 'firebase/firestore';
import { db } from '../../../firebase';
import { PlusCircleIcon, MinusCircleIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import moment from 'moment';

const generateId = () => {
    return Date.now().toString(36) + Math.random().toString(36).substring(2);
};

const AssignTask = ({ jobCard, employees }) => {
    const [taskTitle, setTaskTitle] = useState('');
    const [startDate, setStartDate] = useState('');
    const [startTime, setStartTime] = useState('');
    const [deadlineDate, setDeadlineDate] = useState('');
    const [deadlineTime, setDeadlineTime] = useState('');
    const [assignedTo, setAssignedTo] = useState('');
    const [subtasks, setSubtasks] = useState([{ id: generateId(), description: '', status: 'pending' }]);
    const [tasks, setTasks] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTask, setSelectedTask] = useState(null);

    useEffect(() => {
        if (jobCard?.jobCardNo) {
            const q = query(collection(db, 'tasks'), where('jobCardNo', '==', jobCard.jobCardNo));
            const unsubscribe = onSnapshot(q, (snapshot) => {
                const taskList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setTasks(taskList);
            });
            return () => unsubscribe();
        }
    }, [jobCard?.jobCardNo]);

    const handleSubtaskChange = (e, index) => {
        const updatedSubtasks = subtasks.map((subtask, i) => i === index ? { ...subtask, description: e.target.value } : subtask);
        setSubtasks(updatedSubtasks);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const selectedEmployee = employees.find(emp => emp.id === assignedTo);
        if (!selectedEmployee) {
            toast.error("Required fields are missing");
            return;
        }

        try {
            const startDateTime = new Date(`${startDate}T${startTime}`);
            const deadlineDateTime = new Date(`${deadlineDate}T${deadlineTime}`);
            await addDoc(collection(db, "tasks"), {
                taskTitle,
                startDate: Timestamp.fromDate(startDateTime),
                deadline: Timestamp.fromDate(deadlineDateTime),
                assignedTo: selectedEmployee.id,
                assignedToName: `${selectedEmployee.firstName} ${selectedEmployee.lastName}`,
                assignedBy: jobCard?.assignedBy || '', 
                status: "new",
                subtasks,
                jobCardNo: jobCard?.jobCardNo || '', 
                additionalObservations: '',
                createdAt: Timestamp.fromDate(new Date()),
                updatedAt: Timestamp.fromDate(new Date())
            });
            toast.success("Task assigned successfully");
            setTaskTitle('');
            setStartDate('');
            setStartTime('');
            setDeadlineDate('');
            setDeadlineTime('');
            setAssignedTo('');
            setSubtasks([{ id: generateId(), description: '', status: 'pending' }]);
        } catch (error) {
            console.error("Error adding task: ", error);
            toast.error("Error Adding Task");
        }
    };

    const openModal = (task) => {
        setSelectedTask(task);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedTask(null);
    };

    // const updateTaskStatus = async (taskId, newStatus) => {
    //     const taskRef = doc(db, "tasks", taskId);
    //     try {
    //         await updateDoc(taskRef, {
    //             status: newStatus,
    //             updatedAt: Timestamp.now(),
    //             ...(newStatus === 'completed' && { completedAt: Timestamp.now() })
    //         });
    //         toast.success('Task status updated');
    //         setTasks(tasks.map(task => task.id === taskId ? { ...task, status: newStatus } : task));
    //     } catch (error) {
    //         console.error('Error updating task status:', error);
    //         toast.error('Failed to update task status');
    //     }
    // };

    const updateSubtaskStatus = async (taskId, subtaskId, newStatus) => {
        const taskRef = doc(db, "tasks", taskId);
        const taskSnapshot = await getDoc(taskRef);
        if (taskSnapshot.exists()) {
            const taskData = taskSnapshot.data();
            const updatedSubtasks = taskData.subtasks.map(subtask =>
                subtask.id === subtaskId ? { ...subtask, status: newStatus } : subtask
            );

            const allSubtasksCompleted = updatedSubtasks.every(subtask => subtask.status === 'completed');

            await updateDoc(taskRef, {
                subtasks: updatedSubtasks,
                updatedAt: Timestamp.now(),
                ...(allSubtasksCompleted && { status: 'completed', completedAt: Timestamp.now() })
            });

            toast.success('Subtask status updated successfully');
            setTasks(tasks.map(task => task.id === taskId ? { ...task, subtasks: updatedSubtasks } : task));
            setSelectedTask(current => current && current.id === taskId ? { ...current, subtasks: updatedSubtasks } : current);

            // Update the tracker in the job if all subtasks are completed
            if (allSubtasksCompleted) {
                const jobRef = doc(db, 'jobs', jobCard.id);
                const jobSnapshot = await getDoc(jobRef);
                if (jobSnapshot.exists()) {
                    const updatedTracker = [
                        ...jobSnapshot.data().tracker,
                        {
                            Date: Timestamp.now(),
                            State: 'Repair Concluded',
                            comments: `All subtasks for task "${taskData.taskTitle}" have been completed.`
                        }
                    ];
                    await updateDoc(jobRef, { tracker: updatedTracker });
                }
            }
        }
    };

    return (
        <>
            <div className="bg-white p-6 rounded-lg shadow">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    {/* Left Column: Assign Task to Mechanic */}
                    <div>
                        <h2 className="text-xl font-bold text-gray-800 mb-6">Assign Task to Mechanic</h2>
                        <form onSubmit={handleSubmit} className="space-y-6">
                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Task Title</label>
                                    <input
                                        type="text"
                                        value={taskTitle}
                                        onChange={(e) => setTaskTitle(e.target.value)}
                                        placeholder="Task Title"
                                        className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Assign To</label>
                                    <select
                                        value={assignedTo}
                                        onChange={(e) => setAssignedTo(e.target.value)}
                                        className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    >
                                        <option value="">Select Mechanic</option>
                                        {employees.map(employee => (
                                            <option key={employee.id} value={employee.id}>{employee.firstName} {employee.lastName}</option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Start Date</label>
                                    <input
                                        type="date"
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                        className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Start Time</label>
                                    <input
                                        type="time"
                                        value={startTime}
                                        onChange={(e) => setStartTime(e.target.value)}
                                        className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Deadline Date</label>
                                    <input
                                        type="date"
                                        value={deadlineDate}
                                        onChange={(e) => setDeadlineDate(e.target.value)}
                                        className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Deadline Time</label>
                                    <input
                                        type="time"
                                        value={deadlineTime}
                                        onChange={(e) => setDeadlineTime(e.target.value)}
                                        className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                </div>
                            </div>

                            <div className="mt-6">
                                <h3 className="text-lg font-semibold text-gray-800">Add Itemized List of Tasks</h3>
                                {subtasks.map((subtask, index) => (
                                    <div key={index} className="flex items-center mb-3">
                                        <input
                                            type="text"
                                            value={subtask.description}
                                            onChange={(e) => handleSubtaskChange(e, index)}
                                            placeholder="Task description"
                                            className="flex-grow mt-1 block rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        />
                                        {subtasks.length > 1 && (
                                            <MinusCircleIcon
                                                className="h-6 w-6 ml-2 text-red-500 cursor-pointer"
                                                onClick={() => setSubtasks(subtasks.filter((_, i) => i !== index))}
                                            />
                                        )}
                                        {subtasks.length - 1 === index && (
                                            <PlusCircleIcon
                                                className="h-6 w-6 ml-2 text-green-500 cursor-pointer"
                                                onClick={() => setSubtasks([...subtasks, { id: generateId(), description: '', status: 'pending' }])}
                                            />
                                        )}
                                    </div>
                                ))}
                            </div>

                            <button type="submit" className="w-full px-4 py-2 mt-6 text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500">
                                Assign Task
                            </button>
                        </form>
                    </div>

                    {/* Right Column: Previously Assigned Tasks */}
                    <div>
                        <h2 className="text-xl font-bold text-gray-800 mb-6">Previously Assigned Tasks</h2>
                        {tasks.length > 0 ? (
                            <ul className="space-y-4">
                                {tasks.map(task => (
                                    <li key={task.id} className="p-4 bg-gray-100 rounded-lg shadow-md">
                                        <h4 className="text-md font-semibold">{task.taskTitle}</h4>
                                        <p className="text-sm text-gray-700">Assigned To: {task.assignedToName}</p>
                                        <p className="text-sm text-gray-700">Status: {task.status}</p>
                                        <p className="text-sm text-gray-700">Start: {moment(task.startDate.toDate()).format('LLL')}</p>
                                        <p className="text-sm text-gray-700">Deadline: {moment(task.deadline.toDate()).format('LLL')}</p>
                                        <button
                                            onClick={() => openModal(task)}
                                            className="mt-2 px-3 py-1 text-sm text-white bg-blue-500 rounded-md hover:bg-blue-600"
                                        >
                                            View / Edit Details
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p className="text-sm text-gray-600">No tasks assigned yet for this job card.</p>
                        )}
                    </div>
                </div>
            </div>

            {/* Modal for Viewing and Editing Task Details */}
            <Transition.Root show={isModalOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                    <div>
                                        <div className="mt-3 text-center sm:mt-5">
                                            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                                Task Details: {selectedTask?.taskTitle} -  {selectedTask?.assignedToName}
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <h4 className="text-md font-semibold">Subtasks</h4>
                                                <ul className="space-y-2 mt-2">
                                                    {selectedTask?.subtasks.map((subtask) => (
                                                        <li key={subtask.id} className="flex justify-between items-center">
                                                            <span>{subtask.description}</span>
                                                            <select
                                                                value={subtask.status}
                                                                onChange={(e) => updateSubtaskStatus(selectedTask.id, subtask.id, e.target.value)}
                                                                className="px-2 py-1 rounded bg-white border text-sm border-gray-300 shadow-inner focus:border-blue-500 focus:ring focus:ring-blue-300 focus:ring-opacity-50"
                                                            >
                                                                <option value="pending">Pending</option>
                                                                <option value="in-progress">In Progress</option>
                                                                <option value="completed">Completed</option>
                                                            </select>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5 sm:mt-6">
                                        <button
                                            type="button"
                                            className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-blue-600 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
                                            onClick={closeModal}
                                        >
                                            Close
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
};

export default AssignTask;