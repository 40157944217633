import React, { useState, useEffect } from 'react';
import { doc, setDoc, getDoc, collection, addDoc } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import { db } from '../../../firebase';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', 'image', 'video'],
    ['clean'],
  ],
};

const formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet',
  'link', 'image', 'video'
];


const ArticleForm = ({ isEdit, article, onSave, onCancel }) => {
  const { id } = useParams();
  const [title, setTitle] = useState(article ? article.title : '');
  const [content, setContent] = useState(article ? article.content : '');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isEdit && id && !article) {
      const fetchArticle = async () => {
        try {
          const docRef = doc(db, 'articles', id);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const articleData = docSnap.data();
            setTitle(articleData.title);
            setContent(articleData.content);
          }
        } catch (error) {
          console.error('Error fetching article: ', error);
        }
      };
      fetchArticle();
    }
  }, [isEdit, id, article]);

  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (isEdit) {
        const docRef = doc(db, 'articles', article.id);
        await setDoc(docRef, { title, content });
        toast.success('Article updated successfully!');
      } else {
        await addDoc(collection(db, 'articles'), { title, content });
        toast.success('Article created successfully!');
      }
      onSave({ id: id || article?.id, title, content });
      navigate('/help');
    } catch (error) {
      console.error('Error saving article: ', error);
      toast.error('Error saving article. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    <ToastContainer />
    <div className="max-w-3xl mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">{isEdit ? 'Edit Article' : 'Create New Article'}</h1>
      <form onSubmit={handleSave}>
        <div className="mb-4">
          <label className="block mb-2">Title</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2 bg-white">Content</label>
          <ReactQuill
            value={content}
            onChange={setContent}
            modules={modules}
            formats={formats}
          />
        </div>
        <button type="button" onClick={onCancel} className="bg-gray-500 text-white py-2 px-4 rounded mr-4">
          Cancel
        </button>
        <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded" disabled={loading}>
          {loading ? 'Saving...' : 'Save Article'}
        </button>
      </form>
    </div>
    </>
  );
};

export default ArticleForm;