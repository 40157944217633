// AllocationForm.js
import React, { useEffect } from 'react';

const AllocationForm = ({
  handleAllocationSubmit,
  handleAllocationChange,
  allocation,
  inventoryItems,
  employees,
  jobCards,
  selectedItemId,
}) => {

  useEffect(() => {
    if (selectedItemId) {
      handleAllocationChange({
        target: { name: 'itemId', value: selectedItemId },
      });
    }
  }, [selectedItemId, handleAllocationChange]);

  return (
    <form onSubmit={handleAllocationSubmit} className="mb-8">
      <h3 className="text-xl font-semibold mb-4">Allocate Item</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
      <div>
          <label className="block text-gray-700 font-semibold mb-1 text-sm">
            Selected Item
          </label>
          <p className="p-2 border text-sm border-gray-300 rounded-md bg-gray-100">
            {inventoryItems.find((item) => item.id === allocation.itemId)?.partName ||
              inventoryItems.find((item) => item.id === allocation.itemId)?.description}
          </p>
          </div>
        <div>
          <label className="block text-gray-700 font-semibold mb-1 text-sm">Quantity</label>
          <input
            type="number"
            name="quantity"
            value={allocation.quantity}
            onChange={handleAllocationChange}
            required
            className="block w-full p-2 border text-sm border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label className="block text-gray-700 font-semibold mb-1 text-sm">Purpose</label>
          <select
            name="purpose"
            value={allocation.purpose}
            onChange={handleAllocationChange}
            required
            className="block w-full p-2 border text-sm border-gray-300 rounded-md"
          >
            <option value="">Select Purpose</option>
            <option value="Office Consumable">Office Consumable</option>
            <option value="Job Card">Job Card</option>
          </select>
        </div>
        {allocation.purpose === 'Job Card' && (
          <div>
            <label className="block text-gray-700 font-semibold mb-1 text-sm">Job Card No</label>
            <select
              name="jobCardId"
              value={allocation.jobCardId}
              onChange={handleAllocationChange}
              required
              className="block w-full p-2 border text-sm border-gray-300 rounded-md"
            >
              <option value="">Select Job Card</option>
              {jobCards.map((job) => (
                <option key={job.id} value={job.id}>
                  {job.jobCardNo}
                </option>
              ))}
            </select>
          </div>
        )}
        <div>
          <label className="block text-gray-700 font-semibold mb-1 text-sm">Employee</label>
          <select
            name="employeeId"
            value={allocation.employeeId}
            onChange={handleAllocationChange}
            required
            className="block w-full p-2 border text-sm border-gray-300 rounded-md"
          >
            <option value="">Select Employee</option>
            {employees.map((emp) => (
              <option key={emp.id} value={emp.id}>
                {emp.firstName} {emp.lastName}
              </option>
            ))}
          </select>
        </div>
      </div>
      <button
        type="submit"
        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
      >
        Allocate Item
      </button>
    </form>
  );
};

export default AllocationForm;
