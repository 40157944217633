import { useEffect, useState } from 'react';
import { collection, getDocs,query } from 'firebase/firestore';
import { db } from '../../firebase';
import { Link } from 'react-router-dom';
import { CreditCardIcon, CurrencyDollarIcon, CheckCircleIcon } from '@heroicons/react/20/solid';
import { formatCurrency } from '../Help/helper';
import Pagination from './Pagination';
import moment from 'moment';
import BreadCrumb from '../BreadCrumb';



const pages = [
  { name: 'Finance', to: '/finance', component: Link, current: false },
  { name: 'Track Invoices', to: '/finance/trackInvoice', component: Link, current: true },
];

const InvoiceTracker = () => {
  const [invoices, setInvoices] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("");
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [unpaidInvoices, setUnpaidInvoices] = useState({ count: 0, totalValue: 0 });
  const [partPaymentInvoices, setPartPaymentInvoices] = useState({ count: 0, totalValue: 0 });
  const [fullyPaidInvoices, setFullyPaidInvoices] = useState({ count: 0, totalValue: 0 });
  const [selectedInvoiceTotalReceived, setSelectedInvoiceTotalReceived] = useState(0);
  const [marginPercentage, setMarginPercentage] = useState(0);



  const [currentPage, setCurrentPage] = useState(1);
  const invoicesPerPage = 30;

  useEffect(() => {
    const fetchInvoices = async () => {
      const q = query(collection(db, "newInvoices")); // Adjust as needed
      const querySnapshot = await getDocs(q);
      const loadedInvoices = [];
      let unpaidCount = 0, unpaidTotal = 0;
      let partPaymentCount = 0, partPaymentTotal = 0;
      let fullyPaidCount = 0, fullyPaidTotal = 0;

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        loadedInvoices.push({ id: doc.id, ...data });

        // Calculate totals based on invoice status
        if (data.receivable > 0 && data.paymentsReceived?.length > 0) {
          // Partially paid invoices
          partPaymentCount++;
          partPaymentTotal += data.totalSale;
        } else if (data.receivable === 0) {
          // Fully paid invoices
          fullyPaidCount++;
          fullyPaidTotal += data.totalSale;
        } else {
          // Unpaid invoices
          unpaidCount++;
          unpaidTotal += data.totalSale;
        }
      });

      setInvoices(loadedInvoices);
      setUnpaidInvoices({ count: unpaidCount, totalValue: unpaidTotal });
      setPartPaymentInvoices({ count: partPaymentCount, totalValue: partPaymentTotal });
      setFullyPaidInvoices({ count: fullyPaidCount, totalValue: fullyPaidTotal });
    };

    fetchInvoices();
  }, []);


 


  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const handleInvoiceClick = (invoice) => {
    setSelectedInvoice(invoice);
    setIsModalVisible(true);
    const totalPaymentsReceived = calculateTotalPaymentsReceived(invoice.paymentsReceived);
    setSelectedInvoiceTotalReceived(totalPaymentsReceived);

      // Assuming invoice.totalCost is available and correct
  const margin = invoice.totalSale - invoice.totalCost;
  const marginPercent = (margin / invoice.totalCost) * 100;
  setMarginPercentage(marginPercent);
  };

  const filteredInvoices = invoices.filter((invoice) => 
    invoice.invoiceStatus.includes(filter) &&
    JSON.stringify(invoice).toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastInvoice = currentPage * invoicesPerPage;
  const indexOfFirstInvoice = indexOfLastInvoice - invoicesPerPage;
  const currentInvoices = filteredInvoices.slice(indexOfFirstInvoice, indexOfLastInvoice);

 
  function calculateTotalPaymentsReceived(paymentsReceived) {
    if (!paymentsReceived || paymentsReceived.length === 0) return 0;
  
    const total = paymentsReceived.reduce((acc, payment) => acc + payment.amountReceived, 0);
    return total;
  }


 

  return (

    <>  
       <BreadCrumb pages={pages} />


        <div className="flex justify-around p-4">
            <div className="card bg-white-200 hover:bg-red-400 shadow-lg rounded-lg p-6 max-w-sm transition duration-300 ease-in-out flex flex-col items-center">
                <CurrencyDollarIcon className="h-8 w-8 text-red-800 mb-2" />
                <h3 className="text-lg font-semibold mb-2 text-red-800">Unpaid Invoices</h3>
                <p className="text-md text-sm text-black-700">Count: {unpaidInvoices.count}</p>
                <p className="text-md text-sm text-red-700">Total Value: {formatCurrency(unpaidInvoices.totalValue)}</p>
            </div>

            <div className="card bg-white-400 hover:bg-green-400 shadow-lg rounded-lg p-6 max-w-sm transition duration-300 ease-in-out flex flex-col items-center mb-4">
                <CheckCircleIcon className="h-8 w-8 text-green-800 mb-2" />
                <h3 className="text-lg font-semibold mb-2 text-green-800">Fully Paid Invoices</h3>
                <p className="text-md text-black-700">Count: {fullyPaidInvoices.count}</p>
                <p className="text-md text-green-700">Total Value: {formatCurrency(fullyPaidInvoices.totalValue)}</p>
            </div>


            <div className="card bg-white-500 hover:bg-orange-400 shadow-lg rounded-lg p-6 max-w-sm transition duration-300 ease-in-out flex flex-col items-center">
                <CreditCardIcon className="h-8 w-8 text-orange-800 mb-2" />
                <h3 className="text-lg font-semibold mb-2 text-orange-800">Partial Invoices</h3>
                <p className="text-md text-black-700">Count: {partPaymentInvoices.count}</p>
                <p className="text-md text-orange-700">Total Value: {formatCurrency(partPaymentInvoices.totalValue)}</p>
            </div>
        </div>


        <div className="flex flex-col items-start space-y-6 p-6 bg-white shadow rounded-lg">
              <div className="w-full flex items-center space-x-4">
                <input 
                  type="text" 
                  value={searchTerm} 
                  onChange={handleSearchChange} 
                  className="flex-1 px-4 py-2 border border-gray-300 rounded" 
                  placeholder="Search" 
                />

                <select 
                  value={filter} 
                  onChange={handleFilterChange} 
                  className="px-4 py-2 border border-gray-300 rounded"
                  >
                  <option value="">All</option>
                  <option value="Fully Paid">Fully Paid</option>
                  <option value="Part Payment">Part Payment</option>
                  <option value="Pending">Unpaid</option>
                </select>
              </div>

            <div className="w-full space-y-4">
                  {currentInvoices.map((invoice) => {
                                                  
                          return (
                                <div key={invoice.id} 
                                  onClick={() => handleInvoiceClick(invoice)}   className="w-full p-1 border border-gray-200 rounded hover:bg-gray-100 cursor-pointer transition-colors"
                                >
                                  <h3 className="text-lg font-semibold">{invoice.invoiceNumber}</h3>
                                  <p>Job Card No: {invoice.jobCardNo}</p>
                                  <p>Total Sale: {formatCurrency(invoice.totalSale)}</p>
                                
                                </div>
                                );
                           })}
             </div>

            <Pagination
              totalItems={filteredInvoices.length}
              itemsPerPage={invoicesPerPage}
              currentPage={currentPage}
              onPageChange={setCurrentPage}
               />

{isModalVisible && (
  <div className="fixed inset-0 flex items-center justify-center p-4 bg-black bg-opacity-50 z-10">
    <div className="w-full max-w-2xl p-4 bg-white border border-gray-200 rounded space-y-2">
      <button className="mt-2 bg-indigo-500 text-white text-sm hover:bg-indigo-600 px-5 py-3 rounded font-medium shadow-sm" 
                onClick={() => setIsModalVisible(false)}>Close</button>
    <h2 className="font-bold text-xl text-gray-700">Invoice Details</h2>
    <p className="text-gray-600"><span className="font-semibold">Invoice Date: {" "}</span>
     {moment(selectedInvoice.invoiceDate.toDate()).format('MMMM DD, YYYY')}</p>
    <p className="text-gray-600"><span className="font-semibold">Customer Name:</span> {selectedInvoice.customerName} </p>
    <p className="text-gray-600"><span className="font-semibold">Status:</span> {selectedInvoice.invoiceStatus.toUpperCase()}</p>
    <p className="text-gray-600"><span className="font-semibold">Job Card No:</span> {selectedInvoice.jobCardNo} </p>
    <p className="text-gray-600"><span className="font-semibold">Invoice Value:</span> {formatCurrency(selectedInvoice.totalSale)}</p>
    <p className="text-gray-600"><span className="font-semibold">Amount Received:</span>  {formatCurrency(selectedInvoiceTotalReceived)}</p>
    <p className="text-gray-600"><span className="font-semibold">Net Margin (%):</span> {marginPercentage.toFixed(2)}%</p>
   <div>
      <h3 className="text-gray-900 font-semibold pb-1">Invoice Items:</h3>
      <table className="table-auto w-full">
        <thead>
          <tr>
            <th className="border px-4 py-2">Description</th>
            <th className="border px-4 py-2">Quantity</th>
            <th className="border px-4 py-2">Unit Price</th>
            <th className="border px-4 py-2">Total Price</th>
          </tr>
        </thead>
        <tbody>
          {selectedInvoice.cartItems.map((item, index) => (
            <tr key={index}>
              <td className="border px-4 py-2 text-sm text-center">{item.description}</td>
              <td className="border px-4 py-2 text-sm text-center">{item.quantity}</td>
              <td className="border px-4 py-2 text-sm text-center">{formatCurrency(item.price)}</td>
              <td className="border px-4 py-2 text-sm text-center">{formatCurrency(item.price * item.quantity)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
  </div>
  )}

</div>
</>
   
);

};
  
  
export default InvoiceTracker;