import React from 'react';

const statusStages = [
  'Quotation Raised',
  'Approved',
  'Repair Concluded',
  'Test & QA',
  'Delivered'
];

export default function StatusBar({ jobCard }) {
  let currentStatusIndex = 0;

  // Check approval status
  if (jobCard?.approvalStatus === 'Yes') {
    currentStatusIndex = 1; // Move to "Approved" stage
  }

  // Check the tracker array to find the latest state
  if (jobCard?.tracker && jobCard?.tracker.length > 0) {
    // Find the last state in the tracker
    const lastTrackerState = jobCard.tracker[jobCard.tracker.length - 1].State;
    const trackerIndex = statusStages.findIndex(stage => stage === lastTrackerState);
    // Update the current status index if the tracker state is further along
    if (trackerIndex > currentStatusIndex) {
      currentStatusIndex = trackerIndex;
    }
  }

  const progressPercentage = ((currentStatusIndex + 1) / statusStages.length) * 100;

  return (
    <div className="shadow p-4 mb-2">
      <h4 className="text-sm font-semibold text-gray-900">Job Card Status: {statusStages[currentStatusIndex]}</h4>
      <div aria-hidden="true" className="mt-4">
        <div className="overflow-hidden rounded-full bg-gray-200">
          <div
            style={{ width: `${progressPercentage}%` }}
            className="h-2 rounded-full bg-indigo-600 transition-all duration-300"
          />
        </div>
        <div className="mt-4 grid grid-cols-5 text-sm font-medium text-gray-600">
          {statusStages.map((stage, index) => (
            <div
              key={stage}
              className={`text-center ${index <= currentStatusIndex ? 'text-indigo-600' : ''}`}
            >
              {stage}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
