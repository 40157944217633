import React from 'react';
import VehicleDetails from './VehicleDetails';

const VehicleList = ({ vehicles }) => {
  return (
    <div className="grid grid-cols-1 gap-8">
      {vehicles.map((vehicle) => (
        <VehicleDetails key={vehicle.id} vehicle={vehicle} />
      ))}
    </div>
  );
};

export default VehicleList;
