import React, { useState } from 'react';


const TechDetails = ({ jobCard, updateJobCard }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedJobCard, setEditedJobCard] = useState({ ...jobCard });
  const isJobClosed = jobCard?.status === 'Closed';

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedJobCard((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

 

  const handleSave = () => {
    updateJobCard(editedJobCard);
    setIsEditing(false);
  };

  return (
    <>
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold">Job Details</h2>
        {isEditing ? (
          <button
            className="bg-green-500 text-white px-4 py-1 rounded"
            onClick={handleSave}
          >
            Save
          </button>
        ) : (
          <button
          disabled={isJobClosed} 
          className={`px-4 py-2 ${isJobClosed ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-600'}`}
            onClick={() => setIsEditing(true)}
          >
            Edit
          </button>
        )}
      </div>
      
      <div className='gap-4'>
        <div className='text-sm mb-2'>
          <strong>Customer Complaint:</strong> 
          {isEditing ? (
            <textarea
              name="ownerRequest"
              value={editedJobCard?.ownerRequest}
              onChange={handleInputChange}
              className="w-full rounded-md border-gray-300"
            />
          ) : (
            <span> {jobCard?.ownerRequest}</span>
          )}
        </div>

        <div className='text-sm mb-2'>
          <strong>Technical Diagnosis:</strong> 
          {isEditing ? (
            <textarea
              name="technicianObservation"
              value={editedJobCard.technicianObservation}
              onChange={handleInputChange}
              className="w-full rounded-md border-gray-300"
            />
          ) : (
            <span> {jobCard?.technicianObservation}</span>
          )}
        </div>

        <div className='text-sm mb-2'>
          <strong>Work Required:</strong> 
          {isEditing ? (
            <textarea
              name="workRequired"
              value={editedJobCard.workRequired}
              onChange={handleInputChange}
              className="w-full rounded-md border-gray-300"
            />
          ) : (
            <span> {jobCard.workRequired}</span>
          )}
        </div>

       

      </div>

      <div className="mt-4">
        <h3 className="text-sm font-medium text-gray-700">Additional Observations:</h3>
        <textarea
          name="additionalObservations"
          value={editedJobCard.additionalObservations || ''}
          onChange={handleInputChange}
          rows={3}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          placeholder="Add any additional observations here"
        />
      </div>
    </>
  );
};

export default TechDetails;
