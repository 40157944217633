import Modal from 'react-modal';
import { useState } from 'react';
import { updateDoc, doc } from 'firebase/firestore';
import { db } from '../../../firebase';
import { toast, ToastContainer } from 'react-toastify';

const RescheduleAppointmentModal = ({ isOpen, onClose, appointmentId, currentAppointmentDate, currentAppointmentTime }) => {
    const [newDate, setNewDate] = useState(currentAppointmentDate);
    const [newTime, setNewTime] = useState(currentAppointmentTime);

    const handleReschedule = async () => {
        try {
            const appointmentRef = doc(db, 'appointments', appointmentId);
            await updateDoc(appointmentRef, {
                date: newDate,
                time: newTime,
            });
            toast.success('Appointment rescheduled successfully');
            onClose();
        } catch (error) {
            console.error('Error rescheduling appointment:', error);
            toast.error('Error rescheduling appointment');
        }
    };

    return (
        <Modal 
          isOpen={isOpen} 
          onRequestClose={onClose}
          className="max-w-md mx-auto mt-10 overflow-hidden rounded-lg shadow-lg bg-white p-6"
        >
            <h2 className="text-lg font-semibold mb-4">Reschedule Appointment</h2>
            <div className="mb-3">
                <label className="block text-sm font-medium text-gray-700">New Date</label>
                <input 
                    type="date" 
                    value={newDate} 
                    onChange={(e) => setNewDate(e.target.value)} 
                    className="w-full px-3 py-2 border rounded shadow-sm mt-1"
                />
            </div>
            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">New Time</label>
                <input 
                    type="time" 
                    value={newTime} 
                    onChange={(e) => setNewTime(e.target.value)}
                    className="w-full px-3 py-2 border rounded shadow-sm mt-1"
                />
            </div>
            <div className="flex justify-between">
                <button 
                    onClick={handleReschedule} 
                    className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded"
                >
                    Reschedule
                </button>
                <button 
                    onClick={onClose} 
                    className="px-4 py-2 bg-gray-500 hover:bg-gray-600 text-white font-semibold rounded"
                >
                    Close
                </button>

                <ToastContainer />
            </div>
        </Modal>
    );
};

export default RescheduleAppointmentModal;
