import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, updateDoc, deleteDoc, query, where, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { toast } from 'react-toastify';
import { MoonLoader } from 'react-spinners';

const LeaveRequestApproval = () => {
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchLeaveRequests = async () => {
      setLoading(true);
      try {
        const leaveRequestsQuery = query(collection(db, 'leaveRequests'), where('status', '==', 'Pending'));
        const leaveRequestsSnapshot = await getDocs(leaveRequestsQuery);
        
        const requests = await Promise.all(leaveRequestsSnapshot.docs.map(async (leaveDoc) => {
          const requestData = leaveDoc.data();

          // Fetch employee's name
          const employeeDocRef = doc(db, 'employees', requestData.employeeId);
          const employeeDocSnap = await getDoc(employeeDocRef);
          const employeeName = employeeDocSnap.exists() 
            ? `${employeeDocSnap.data().firstName} ${employeeDocSnap.data().lastName}`
            : 'Unknown Employee';

          // Fetch temporary designate's name
          const tempDesignateDocRef = doc(db, 'employees', requestData.tempDesignateId);
          const tempDesignateDocSnap = await getDoc(tempDesignateDocRef);
          const tempDesignateName = tempDesignateDocSnap.exists()
            ? `${tempDesignateDocSnap.data().firstName} ${tempDesignateDocSnap.data().lastName}`
            : 'Unknown Designate';

          return {
            id: leaveDoc.id,
            ...requestData,
            employeeName,
            tempDesignateName,
          };
        }));

        setLeaveRequests(requests);
      } catch (error) {
        toast.error('Failed to fetch leave requests: ' + error.message);
      }
      setLoading(false);
    };

    fetchLeaveRequests();
  }, []);

  const handleApprove = async (id) => {
    try {
      const requestDocRef = doc(db, 'leaveRequests', id);
      await updateDoc(requestDocRef, { status: 'Approved' });
      setLeaveRequests(leaveRequests.filter(request => request.id !== id));
      toast.success('Leave request approved successfully');
    } catch (error) {
      toast.error('Failed to approve leave request: ' + error.message);
    }
  };

  const handleReject = async (id) => {
    try {
      const requestDocRef = doc(db, 'leaveRequests', id);
      await updateDoc(requestDocRef, { status: 'Rejected' });
      setLeaveRequests(leaveRequests.filter(request => request.id !== id));
      toast.success('Leave request rejected successfully');
    } catch (error) {
      toast.error('Failed to reject leave request: ' + error.message);
    }
  };

  const handleDelete = async (id) => {
    try {
      const requestDocRef = doc(db, 'leaveRequests', id);
      await deleteDoc(requestDocRef);
      setLeaveRequests(leaveRequests.filter(request => request.id !== id));
      toast.success('Leave request deleted successfully');
    } catch (error) {
      toast.error('Failed to delete leave request: ' + error.message);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <MoonLoader size={50} color={"#4A90E2"} />
      </div>
    );
  }

  return (
    <div className="p-4 bg-white rounded-md shadow-md">
      <h2 className="text-lg font-medium text-gray-900">Pending Leave Requests</h2>
      
      {leaveRequests.length === 0 ? (
        <p>No pending leave requests</p>
      ) : (
        <ul className="space-y-4">
          {leaveRequests.map(request => (
            <li key={request.id} className="p-4 bg-gray-100 rounded-md">
              <p><strong>Employee:</strong> {request.employeeName}</p>
              <p><strong>Type:</strong> {request.leaveType}</p>
              <p><strong>Dates:</strong></p>
              <ul className="list-disc ml-6">
                {request.leaveDates && request.leaveDates.map((date, index) => (
                  <li key={index}>{new Date(date.seconds * 1000).toLocaleDateString()}</li>
                ))}
              </ul>
              <p><strong>Temporary Designate:</strong> {request.tempDesignateName}</p>
              <div className="mt-4 flex space-x-4">
                <button
                  onClick={() => handleApprove(request.id)}
                  className="bg-green-500 text-white px-4 py-2 rounded-md"
                >
                  Approve
                </button>
                <button
                  onClick={() => handleReject(request.id)}
                  className="bg-red-500 text-white px-4 py-2 rounded-md"
                >
                  Reject
                </button>
                <button
                  onClick={() => handleDelete(request.id)}
                  className="bg-gray-500 text-white px-4 py-2 rounded-md"
                >
                  Delete
                </button>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LeaveRequestApproval;
