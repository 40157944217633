import React, { useState, useEffect } from 'react';
import { collection, addDoc, Timestamp, getDocs, where, query } from 'firebase/firestore';
import { db } from '../../firebase';
import TaskList from './TaskList';
import { useAuth } from '../Auth/Auth';
import { PlusCircleIcon, MinusCircleIcon } from '@heroicons/react/24/outline';
import { toast, ToastContainer } from 'react-toastify';
import { Link , Navigate} from 'react-router-dom';
import BreadCrumb from '../BreadCrumb';
import Select from 'react-select';

const pages = [
  { name: 'Service Advisor', to: '/vehicles', component: Link, current: false },
  { name: 'Assign Task to Mechanic', to: '/vehicles/assigntask', component: Link, current: true },
];

const generateId = () => {
  return Date.now().toString(36) + Math.random().toString(36).substring(2);
};

const CreateTask = () => {
  const [taskTitle, setTaskTitle] = useState('');
  const [startDate, setStartDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [deadlineDate, setDeadlineDate] = useState('');
  const [deadlineTime, setDeadlineTime] = useState('');
  const [assignedTo, setAssignedTo] = useState('');
  const [employees, setEmployees] = useState([]);
  const [jobCards, setJobCards] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [subtasks, setSubtasks] = useState([{ id: generateId(), description: '', status: 'pending' }]);
  const { user, role, loading } = useAuth();
  const assignedBy = user?.email;

  useEffect(() => {
    const fetchJobCards = async () => {
      const querySnapshot = await getDocs(collection(db, "jobs"));
      const fetchedJobCards = querySnapshot.docs.map(doc => ({
        label: `${doc.data().jobCardNo} - ${doc.data().registrationNumber}`,
        value: doc.id,
        ...doc.data()
      }));
      setJobCards(fetchedJobCards);
    };

    fetchJobCards();
  }, []);

  useEffect(() => {
    const fetchEmployees = async () => {
      const q = query(collection(db, "employees"), where("role", "==", "Mechanic"));
      const querySnapshot = await getDocs(q);
      setEmployees(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    fetchEmployees();
  }, []);

  const addSubtask = () => {
    setSubtasks([...subtasks, { id: generateId(), description: '', status: 'pending' }]);
  };

  const handleSubtaskChange = (e, index) => {
    const updatedSubtasks = subtasks.map((subtask, i) => i === index ? { ...subtask, description: e.target.value } : subtask);
    setSubtasks(updatedSubtasks);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const selectedEmployee = employees.find(emp => emp.id === assignedTo);
    if (!selectedEmployee || !selectedJob) {
      toast.error("Required fields are missing");
      return;
    }

    try {
      const startDateTime = new Date(`${startDate}T${startTime}`);
      const deadlineDateTime = new Date(`${deadlineDate}T${deadlineTime}`);
      await addDoc(collection(db, "tasks"), {
        taskTitle,
        startDate: Timestamp.fromDate(startDateTime),
        deadline: Timestamp.fromDate(deadlineDateTime),
        assignedTo: selectedEmployee.id,
        assignedToName: `${selectedEmployee.firstName} ${selectedEmployee.lastName}`,
        assignedBy,
        status: "new",
        subtasks,
        jobCardNo: selectedJob.jobCardNo,
        createdAt: Timestamp.fromDate(new Date()),
        updatedAt: Timestamp.fromDate(new Date())
      });
      toast.success("Task assigned Successfully");
      setTaskTitle('');
      setStartDate('');
      setStartTime('');
      setDeadlineDate('');
      setDeadlineTime('');
      setAssignedTo('');
      setSelectedJob(null);
      setSubtasks([{ id: generateId(), description: '', status: 'pending' }]);
    } catch (error) {
      console.error("Error adding task: ", error);
      toast.error("Error Adding Task");
    }
  };

  const deleteSubtask = (index) => {
    setSubtasks(subtasks.filter((_, i) => i !== index));
  };

  if (loading) {
    return <div>Loading...</div>; 
  }

  if (role !== 'Service Advisor' && role !== 'Admin' && role !== 'Supervisor') {
    return <Navigate to="/unauthorized" replace />; 
  }

  return (
    <>
      <BreadCrumb pages={pages} />
      <div className="container mx-auto p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="bg-white p-4 rounded-lg shadow max-w-full">
          <form onSubmit={handleSubmit} className="space-y-4">
            <h2 className="text-base font-semibold leading-7 text-gray-900">Task Assignment</h2>
            <p className="text-sm leading-6 text-gray-600">For Accountability and ease of tracking this form to assign detailed tasks to employees.</p>
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label className="block text-sm font-medium text-gray-700">Task Title</label>
                <div className="mt-2">
                  <input type="text" value={taskTitle} onChange={(e) => setTaskTitle(e.target.value)} placeholder="Task Title" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                </div>
              </div>
              <div className="sm:col-span-3">
                <label className="block text-sm font-medium text-gray-700">Start Date</label>
                <div className="mt-2">
                  <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                </div>
              </div>
              <div className="sm:col-span-3">
                <label className="block text-sm font-medium text-gray-700">Start Time</label>
                <div className="mt-2">
                  <input type="time" value={startTime} onChange={(e) => setStartTime(e.target.value)} className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                </div>
              </div>
              <div className="sm:col-span-3">
                <label className="block text-sm font-medium text-gray-700">Deadline Date</label>
                <div className="mt-2">
                  <input type="date" value={deadlineDate} onChange={(e) => setDeadlineDate(e.target.value)} className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                </div>
              </div>
              <div className="sm:col-span-3">
                <label className="block text-sm font-medium text-gray-700">Deadline Time</label>
                <div className="mt-2">
                  <input type="time" value={deadlineTime} onChange={(e) => setDeadlineTime(e.target.value)} className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                </div>
              </div>
              <div className="sm:col-span-3">
                <label className="block text-sm font-medium text-gray-700">Assign To</label>
                <select value={assignedTo} onChange={(e) => setAssignedTo(e.target.value)} className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                  <option value="">Select Employee</option>
                  {employees.map(employee => (
                    <option key={employee.id} value={employee.id}>{employee.firstName} {employee.lastName}</option>
                  ))}
                </select>
              </div>
              <div className="sm:col-span-3">
                <label htmlFor="jobCard" className="block text-sm font-medium text-gray-700">Select Job Card</label>
                <Select id="jobCard" options={jobCards} getOptionLabel={(option) => `${option.jobCardNo} - ${option.vehicleMake} ${option.vehicleModel}`} getOptionValue={(option) => option.jobCardNo} onChange={(selectedOption) => setSelectedJob(selectedOption)} placeholder="Select a job card" isClearable isSearchable className="mt-1 block text-xs w-full" />
              </div>
            </div>
            <div className="flex items-center">
              <div className="grow border-b border-teal-600"></div>
              <span className="shrink px-1 pb-1 text-teal-600 text-sm">Add Itemized List of Tasks Below</span>
              <div className="grow border-b border-teal-600"></div>
            </div>
            {subtasks.map((subtask, index) => (
              <div key={index} className="flex items-center mb-2">
                <input type="text" value={subtask.description} onChange={(e) => handleSubtaskChange(e, index)} className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Add Task" />
                <MinusCircleIcon className="h-6 w-6 ml-2 text-red-500 cursor-pointer" onClick={() => deleteSubtask(index)} />
                {index === subtasks.length - 1 && (
                  <PlusCircleIcon className="h-6 w-6 ml-2 text-blue-500 cursor-pointer" onClick={addSubtask} />
                )}
              </div>
            ))}
            <button type="submit" className="w-full px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500">Assign Task</button>
          </form>
        </div>
        <div className="bg-white p-4 rounded-lg shadow">
          <TaskList />
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default CreateTask;
