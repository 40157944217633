import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../../firebase'; 

export  async function generateInvoiceNumber() {
    let isUnique = false;
    let invoiceNo;
  
    while (!isUnique) {
      invoiceNo = 'INV' + Date.now().toString().substr(-8) + Math.random().toString(36).substr(2, 3).toUpperCase();
  
      const q = query(collection(db, 'invoices'), where('invoiceNumber', '==', invoiceNo));
      const querySnapshot = await getDocs(q);
  
      isUnique = querySnapshot.empty; 
    }
  
    return invoiceNo;
  }
  