import React, { useEffect, useState, useCallback } from 'react';
import { collection, getDocs, doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import Select from 'react-select';
import { formatCurrency } from '../Help/helper';
import { nanoid } from 'nanoid';

const Taxes = () => {
  const [invoices, setInvoices] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [vatAmount, setVatAmount] = useState('');
  const [dateReceived, setDateReceived] = useState('');
  const [grossValue, setGrossValue] = useState(0);
  const [taxes, setTaxes] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [sortOrder, setSortOrder] = useState('desc');

  const getInvoices = async () => {
    const invoicesCol = collection(db, 'newInvoices');
    const invoiceSnapshot = await getDocs(invoicesCol);
    const invoiceData = invoiceSnapshot.docs.map(doc => ({ label: doc.data().invoiceNumber, value: doc.id, ...doc.data() }));
    setInvoices(invoiceData);
  };

  // Wrap getTaxes in useCallback to avoid redefinition on every render
  const getTaxes = useCallback(async () => {
    const taxesCol = collection(db, 'taxes');
    const taxesSnapshot = await getDocs(taxesCol);
    let taxesData = taxesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    if (sortOrder === 'asc') {
      taxesData = taxesData.sort((a, b) => new Date(a.dateReceived) - new Date(b.dateReceived));
    } else {
      taxesData = taxesData.sort((a, b) => new Date(b.dateReceived) - new Date(a.dateReceived));
    }

    setTaxes(taxesData);
  }, [sortOrder]);

  useEffect(() => {
    getInvoices();
    getTaxes();
  }, [sortOrder, getTaxes]);

  useEffect(() => {
    if (!showConfirmation) {
      getTaxes();
    }
  }, [showConfirmation, getTaxes]);

  const handleInvoiceSelect = (selectedInvoice) => {
    setSelectedInvoice(selectedInvoice);
    calculateGrossValue(selectedInvoice.cartItems);
  };

  const calculateGrossValue = (cartItems) => {
    let total = 0;
    for (let item of cartItems) {
      total += parseFloat(item.price) * parseFloat(item.quantity);
    }
    setGrossValue(total);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedInvoice || !dateReceived || !vatAmount) {
      alert("Please fill in all fields.");
      return;
    }
    setSubmitting(true);

    const payload = {
      createdAt: serverTimestamp(),
      dateReceived: dateReceived,
      description: `VAT payment for invoiceNumber ${selectedInvoice.label}`,
      vatAmount: parseFloat(vatAmount)
    };

    await setDoc(doc(db, "taxes", nanoid()), payload);

    setSubmitting(false);
    setShowConfirmation(true);
    setSelectedInvoice("");
    setDateReceived("");
    setVatAmount("");
    getTaxes();  // Reload the taxes data
  };

  // Toggle function for sorting
  const toggleSortOrder = () => {
    setSortOrder(prev => prev === 'asc' ? 'desc' : 'asc');
  };

  return (
    <div className="flex">
      <div className="w-1/2 p-4">
        <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Invoice Number</label>
            <Select options={invoices} onChange={handleInvoiceSelect} />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">Gross Value</label>
            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="number" value={grossValue} readOnly />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">VAT Amount</label>
            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="number" value={vatAmount} onChange={(e) => setVatAmount(e.target.value)} />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">Date Received</label>
            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="date" value={dateReceived} onChange={(e) => setDateReceived(e.target.value)} />
          </div>
          <div className="flex items-center justify-between">
            <button className="w-full p-2 mt-4 bg-blue-600 text-white rounded" type="submit" disabled={submitting}>
              {submitting ? "Processing..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
      <div className="w-1/2 p-4">
        <h2 className="text-lg font-semibold">Tax Entries</h2>
        <button onClick={toggleSortOrder} className="mb-4 px-2 py-1 bg-indigo-500 text-white rounded">
          Toggle Sort Order ({sortOrder === 'asc' ? 'Ascending' : 'Descending'})
        </button>
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Date Posted
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Date Received
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Description
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                VAT
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {taxes.map((tax, index) => (
              <tr key={index} className="hover:bg-gray-100">
                <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                  {tax.createdAt.toDate().toLocaleDateString()}
                </td>
                <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                  {tax.dateReceived}
                </td>
                <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                  {tax.description}
                </td>
                <td className="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                  {formatCurrency(tax.vatAmount)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showConfirmation && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                      Confirmation
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Your data has been saved.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm" onClick={() => setShowConfirmation(false)}>
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Taxes;
