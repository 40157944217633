import React, { useEffect, useState } from 'react';
import { db } from '../../../firebase';
import { query, collection, where, onSnapshot } from 'firebase/firestore';
import { formatCurrency } from '../../Help/helper';

const JobCardInvoice = ({ jobCard }) => {
  const [invoices, setInvoices] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  useEffect(() => {
    if (jobCard?.jobCardNo) {
      const q = query(collection(db, 'newInvoices'), where('jobCardNo', '==', jobCard.jobCardNo));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const invoiceList = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setInvoices(invoiceList);
      });
      return () => unsubscribe();
    }
  }, [jobCard?.jobCardNo]);

  const handleViewDetails = (invoice) => {
    setSelectedInvoice(invoice);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
       
        <div>
          <h2 className="text-lg font-bold text-gray-800 mb-2">Invoices</h2>
          {invoices.length > 0 ? (
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Invoice Number
                  </th>
                  <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Date
                  </th>
                  <th className="py-3 px-6 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="py-3 px-6"></th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {invoices.map((invoice) => (
                  <tr key={invoice.id}>
                    <td className="py-4 px-6 text-sm text-gray-900">{invoice.invoiceNumber}</td>
                    <td className="py-4 px-6 text-sm text-gray-500">
                      {invoice.invoiceDate?.toDate().toLocaleDateString()}
                    </td>
                    <td className="py-4 px-6 text-sm text-gray-500">{invoice.invoiceStatus}</td>
                    <td className="py-4 px-6 text-sm text-right">
                      <button
                        className="text-blue-600 hover:text-blue-800"
                        onClick={() => handleViewDetails(invoice)}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="text-sm text-gray-600">No invoices available for this job card.</p>
          )}
        </div>

     
        <div>
          <h2 className="text-lg font-bold text-gray-800 mb-4">Invoice Details -  <span className="text-sm text-gray-600">
                 {selectedInvoice?.invoiceNumber} </span></h2>
          {selectedInvoice ? (
            <div className="space-y-2">
             
            
              <p className="text-sm text-gray-600">               
                <strong>Total Sale:</strong> {formatCurrency(selectedInvoice.totalSale)}  
              </p>
                         

          
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="py-1 px-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Description
                    </th>
                    <th className="py-1 px-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Qty
                    </th>
                    <th className="py-1 px-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Price
                    </th>
                    <th className="py-1 px-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Extended Price
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {selectedInvoice.cartItems.map((item, index) => (
                    <tr key={index}>
                      <td className="py-2 px-2 text-sm text-gray-900">{item.description}</td>
                      <td className="py-2 px-2 text-sm items-center text-gray-500">{item.quantity}</td>
                      <td className="py-2 px-2 text-sm text-gray-500">{formatCurrency(item.price)}</td>
                      <td className="py-2 px-2 text-sm text-gray-500 items-center">
                        {formatCurrency(item.price * item.quantity)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p className="text-sm text-gray-600">Select an invoice to view details.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default JobCardInvoice;
