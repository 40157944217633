import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc, collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { toast } from 'react-toastify';

const LeavePolicyManager = () => {
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [selectedManager, setSelectedManager] = useState('');
  const [employees, setEmployees] = useState([]);
  const [annualLeaveDays, setAnnualLeaveDays] = useState(0);
  const [casualLeaveDays, setCasualLeaveDays] = useState(0);
  const [eligibilityStartDate, setEligibilityStartDate] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Fetch all employees from Firestore
    const fetchEmployees = async () => {
      try {
        const employeesSnapshot = await getDocs(collection(db, 'employees'));
        const employeesList = employeesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEmployees(employeesList);
      } catch (error) {
        toast.error('Failed to fetch employees: ' + error.message);
      }
    };

    fetchEmployees();
  }, []);

  useEffect(() => {
    if (selectedEmployee) {
      // Fetch the leave policy and manager for the selected employee
      const fetchEmployeeData = async () => {
        setLoading(true);
        try {
          const employeeDocRef = doc(db, 'employees', selectedEmployee);
          const employeeDocSnap = await getDoc(employeeDocRef);
          if (employeeDocSnap.exists()) {
            const data = employeeDocSnap.data();
            setAnnualLeaveDays(data.annualLeaveDays || 0);
            setCasualLeaveDays(data.casualLeaveDays || 0);
            setEligibilityStartDate(data.eligibilityStartDate || '');
            setSelectedManager(data.designatedManagerId || '');
          } else {
            // Reset if no specific policy is set
            setAnnualLeaveDays(0);
            setCasualLeaveDays(0);
            setEligibilityStartDate('');
            setSelectedManager('');
          }
        } catch (error) {
          toast.error('Failed to fetch employee data: ' + error.message);
        }
        setLoading(false);
      };

      fetchEmployeeData();
    }
  }, [selectedEmployee]);

  const handleSave = async () => {
    setLoading(true);
    try {
      const employeeDocRef = doc(db, 'employees', selectedEmployee);

      // Fetch current leave taken data if exists
      const employeeDocSnap = await getDoc(employeeDocRef);
      let annualLeaveTaken = 0;
      let casualLeaveTaken = 0;

      if (employeeDocSnap.exists()) {
        const data = employeeDocSnap.data();
        annualLeaveTaken = data.annualLeaveTaken || 0;
        casualLeaveTaken = data.casualLeaveTaken || 0;
      }

      // Calculate remaining leave days
      const annualLeaveRemaining = annualLeaveDays - annualLeaveTaken;
      const casualLeaveRemaining = casualLeaveDays - casualLeaveTaken;

      await setDoc(employeeDocRef, {
        annualLeaveDays,
        casualLeaveDays,
        annualLeaveTaken,
        casualLeaveTaken,
        annualLeaveRemaining,
        casualLeaveRemaining,
        eligibilityStartDate,
        designatedManagerId: selectedManager,
        isManager: !!selectedManager, // Set isManager to true if a manager is assigned
      }, { merge: true });
  
      // Update the isManager flag for the selected manager
      if (selectedManager) {
        const managerDocRef = doc(db, 'employees', selectedManager);
        await setDoc(managerDocRef, {
          isManager: true,
        }, { merge: true });
      }
  
      toast.success('Leave policy and manager updated successfully');
    } catch (error) {
      toast.error('Failed to update leave policy and manager: ' + error.message);
    }
    setLoading(false);
  };

  return (
    <div className="flex flex-col lg:flex-row lg:space-x-8 p-4 bg-white rounded-md shadow-md">
      {/* Left Column: Form */}
      <div className="lg:w-1/2">
        <h2 className="text-lg font-medium text-gray-900">Manage Leave Policies</h2>
        
        {loading ? (
          <p>Loading...</p>
        ) : (
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="mt-4">
              <label className="block text-sm font-medium text-gray-700">Select Employee</label>
              <select 
                value={selectedEmployee} 
                onChange={(e) => setSelectedEmployee(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                required
              >
                <option value="">Select Employee</option>
                {employees.map(employee => (
                  <option key={employee.id} value={employee.id}>
                    {employee.firstName} {employee.lastName}
                  </option>
                ))}
              </select>
            </div>

            <div className="mt-4">
              <label className="block text-sm font-medium text-gray-700">Assign Manager</label>
              <select 
                value={selectedManager} 
                onChange={(e) => setSelectedManager(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                required
              >
                <option value="">Select Manager</option>
                {employees.map(employee => (
                  <option key={employee.id} value={employee.id}>
                    {employee.firstName} {employee.lastName}
                  </option>
                ))}
              </select>
            </div>

            <div className="mt-4">
              <label className="block text-sm font-medium text-gray-700">Maximum Annual Leave Days</label>
              <input
                type="number"
                value={annualLeaveDays}
                onChange={(e) => setAnnualLeaveDays(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>

            <div className="mt-4">
              <label className="block text-sm font-medium text-gray-700">Maximum Casual Leave Days</label>
              <input
                type="number"
                value={casualLeaveDays}
                onChange={(e) => setCasualLeaveDays(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>

            <div className="mt-4">
              <label className="block text-sm font-medium text-gray-700">Eligibility Start Date</label>
              <input
                type="date"
                value={eligibilityStartDate}
                onChange={(e) => setEligibilityStartDate(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>

            <div className="mt-6 flex justify-end">
              <button
                type="button"
                onClick={handleSave}
                className="bg-blue-500 text-white px-4 py-2 rounded-md"
                disabled={loading || !selectedEmployee}
              >
                {loading ? 'Saving...' : 'Save Policy'}
              </button>
            </div>
          </form>
        )}
      </div>

      {/* Right Column: Employee Data */}
      <div className="lg:w-1/2 mt-8 lg:mt-0">
        <h2 className="text-lg font-medium text-gray-900">Employee Data</h2>
        <div className="mt-4">
          {employees.length === 0 ? (
            <p>No employees found</p>
          ) : (
            <ul className="space-y-4">
              {employees.map(employee => (
                <li key={employee.id} className="p-4 bg-gray-100 rounded-md">
                  <p><strong>Name:</strong> {employee.firstName} {employee.lastName}</p>
                  <p><strong>Manager:</strong> {employee.designatedManagerId ? (employees.find(emp => emp.id === employee.designatedManagerId)?.firstName || 'Unknown') : 'None'}</p>
                  <p><strong>Annual Leave Days:</strong> {employee.annualLeaveDays || 0}</p>
                  <p><strong>Casual Leave Days:</strong> {employee.casualLeaveDays || 0}</p>
                  <p><strong>Annual Leave Remaining:</strong> {employee.annualLeaveRemaining || 0}</p>
                  <p><strong>Casual Leave Remaining:</strong> {employee.casualLeaveRemaining || 0}</p>
                  <p><strong>Eligibility Start Date:</strong> {employee.eligibilityStartDate ? new Date(employee.eligibilityStartDate).toLocaleDateString() : 'Not Set'}</p>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default LeavePolicyManager;
