// InventoryItemDetail.js

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from 'firebase/firestore';
import { db } from '../../firebase';
import { formatCurrency } from '../Help/helper';
import MoonLoader from 'react-spinners/MoonLoader';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const InventoryItemDetail = () => {
  const { itemId } = useParams();
  const [itemData, setItemData] = useState(null);
  const [supplierData, setSupplierData] = useState(null);
  const [procurementData, setProcurementData] = useState(null);
  const [allocationHistory, setAllocationHistory] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchItemDetails = async () => {
      try {
        // Fetch inventory item data
        const itemRef = doc(db, 'inventory', itemId);
        const itemSnap = await getDoc(itemRef);

        if (itemSnap.exists()) {
          const data = itemSnap.data();
          setItemData(data);

          // Fetch supplier data if supplierId is available
          if (data.supplierId) {
            const supplierRef = doc(db, 'suppliers', data.supplierId);
            const supplierSnap = await getDoc(supplierRef);
            if (supplierSnap.exists()) {
              setSupplierData(supplierSnap.data());
            } else {
              toast.error('Supplier data not found.');
            }
          } else if (data.supplier) {
            // If supplier name is stored directly
            setSupplierData({ name: data.supplier });
          }

          // Fetch procurement data if procurementId is available
          if (data.procurementId) {
            const procurementRef = doc(db, 'procurements', data.procurementId);
            const procurementSnap = await getDoc(procurementRef);
            if (procurementSnap.exists()) {
              setProcurementData(procurementSnap.data());
            } else {
              toast.error('Procurement data not found.');
            }
          }

          // Fetch allocation history from allocations collection
          const q = query(
            collection(db, 'allocations'),
            where('itemId', '==', itemId)
          );
          const allocationsSnap = await getDocs(q);
          const allocations = allocationsSnap.docs.map((doc) => doc.data());
          setAllocationHistory(allocations);
        } else {
          toast.error('Inventory item not found.');
          setItemData(null);
        }
      } catch (error) {
        console.error('Error fetching item details:', error);
        toast.error('Error fetching item details. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchItemDetails();
  }, [itemId]);

  if (loading) {
    return (
      <div className="flex justify-center items-center">
        <MoonLoader size={50} />
      </div>
    );
  }

  if (!itemData) {
    return (
      <div className="container mx-auto p-6 bg-white shadow rounded-lg">
        <ToastContainer />
        <h2 className="text-2xl font-semibold mb-6">Inventory Item Details</h2>
        <p className="text-red-500">Item not found.</p>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-6 bg-white shadow rounded-lg">
      <ToastContainer />
      <h2 className="text-2xl font-semibold mb-6">Inventory Item Details</h2>

      {/* Item Information */}
      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-4">Item Information</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <p>
              <strong>Name:</strong>{' '}
              {itemData.partName || itemData.description}
            </p>
            <p>
              <strong>Type:</strong> {itemData.type}
            </p>
            <p>
              <strong>Quantity:</strong> {itemData.quantity}
            </p>
            <p>
              <strong>Cost Price:</strong>{' '}
              {formatCurrency(itemData.costPrice)}
            </p>
            <p>
              <strong>Purchase Price:</strong>{' '}
              {formatCurrency(itemData.purchasePrice)}
            </p>
            <p>
              <strong>Acquired At:</strong>{' '}
              {itemData.acquiredAt &&
                itemData.acquiredAt.toDate().toLocaleString()}
            </p>
            <p>
              <strong>Registered By:</strong> {itemData.registeredBy}
            </p>
          </div>
          {itemData.type === 'part' && (
            <div>
              <p>
                <strong>Part Number:</strong> {itemData.partNumber}
              </p>
              <p>
                <strong>Make:</strong> {itemData.make}
              </p>
              <p>
                <strong>Model:</strong> {itemData.model}
              </p>
              <p>
                <strong>Year:</strong> {itemData.year}
              </p>
            </div>
          )}
        </div>
      </div>

      {/* Supplier Information */}
      {supplierData ? (
        <div className="mb-8">
          <h3 className="text-xl font-semibold mb-4">Supplier Information</h3>
          <p>
            <strong>Name:</strong> {supplierData.name}
          </p>
          {supplierData.contact && (
            <p>
              <strong>Contact:</strong> {supplierData.contact}
            </p>
          )}
          {/* Add more supplier fields if available */}
        </div>
      ) : (
        <p>No supplier information available.</p>
      )}

      {/* Procurement Link */}
      {procurementData ? (
        <div className="mb-8">
          <h3 className="text-xl font-semibold mb-4">Procurement Details</h3>
          <p>
            <strong>Procurement Number:</strong>{' '}
            {procurementData.procurementNumber}
          </p>
          <p>
            <strong>Supplier:</strong> {procurementData.supplier}
          </p>
          <p>
            <strong>Status:</strong> {procurementData.status}
          </p>
          {/* Link to procurement details page */}
          <a
            href={`/procurements/${itemData.procurementId}`}
            className="text-blue-500 hover:underline"
          >
            View Procurement Details
          </a>
        </div>
      ) : (
        <p>No procurement information available.</p>
      )}

      {/* Allocation History */}
      <div>
        <h3 className="text-xl font-semibold mb-4">Allocation History</h3>
        {allocationHistory.length > 0 ? (
          <table className="min-w-full divide-y divide-gray-200 border">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">
                  Date
                </th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">
                  Quantity
                </th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">
                  Employee
                </th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">
                  Purpose
                </th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">
                  Job Card No
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {allocationHistory.map((alloc, index) => (
                <tr key={index}>
                  <td className="px-4 py-2">
                    {alloc.allocatedAt.toDate
                      ? alloc.allocatedAt.toDate().toLocaleString()
                      : new Date(alloc.allocatedAt).toLocaleString()}
                  </td>
                  <td className="px-4 py-2">{alloc.quantity}</td>
                  <td className="px-4 py-2">{alloc.employeeId}</td>
                  <td className="px-4 py-2">{alloc.purpose}</td>
                  <td className="px-4 py-2">{alloc.jobCardId || 'N/A'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No allocation history available.</p>
        )}
      </div>
    </div>
  );
};

export default InventoryItemDetail;
