export const JobDetails = ({ job }) => {
    
    return (
        <>
        <div>
        <p className="font-semibold text-sm text-gray-700">Vehicle: <span className="font-normal text-sm">{job?.vehicleYear} {job?.vehicleMake} {job?.vehicleModel}</span></p> 
      </div>
      <div>
        <p className="font-semibold text-sm text-gray-700">Customer: <span className="font-normal text-sm">{job?.customerName}</span></p>
      </div>
      <div>
        <p className="font-semibold text-sm text-gray-700">Job Card No: <span className="font-normal text-sm">{job?.jobCardNo}</span></p>
      </div>
      <div>
        <p className="font-semibold text-sm text-gray-700">Service Advisor Comments: <span className="font-normal text-sm">{job?.workRequired}</span></p>
      </div>
      <div>
        <p className="font-semibold text-sm text-gray-700">Estimated Work Duration: <span className="font-normal text-sm">{job?.estimatedDuration}</span></p>
      </div>
</>
    )
  };