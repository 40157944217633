import React from 'react';
import { XCircleIcon } from '@heroicons/react/24/solid';
import { formatCurrency } from '../../Help/helper';

export const CartItemsList = ({ cartItems, handleQuantityChange, handleCostPriceChange, handleTypeChange, handleRemoveFromCart, handleSalePriceChange }) => {
    // Helper function to safely parse numeric inputs
    const safeParseFloat = (value) => {
        const parsed = parseFloat(value);
        return isNaN(parsed) ? '' : parsed;
    };

    return (
        <div className="container mx-auto p-1">
            <div className="overflow-x-auto">
                <table className="min-w-full table-auto divide-y divide-gray-200 text-sm">
                    <thead className="bg-gray-100">
                        <tr>
                            {['Type', 'Part Name', 'Qty', 'Cost Price', 'Max Cost', 'Unit Sale', 'Total Sale'].map((header, index) => (
                                <th key={index} scope="col" className="px-3 py-3 text-left font-semibold text-gray-700 uppercase tracking-wider">
                                    {header}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {cartItems.length > 0 ? (
                            cartItems.map((item, index) => (
                                <tr key={index} className="hover:bg-gray-50">
                                    <td className="px-1 py-4">
                                        <select
                                            value={item.type || ''}
                                            onChange={(e) => handleTypeChange(index, e.target.value)}
                                            className="block w-full rounded-md border-gray-300 shadow-sm text-xs focus:ring-indigo-500 focus:border-indigo-500"
                                        >
                                            <option value="default">Default Margin</option>
                                            <option value="vehicle-specific">Vehicle Part</option>
                                            <option value="service">Service</option>
                                        </select>
                                    </td>
                                    <td className="px-3 py-4">
                                        <div className="text-gray-900 text-xs">{item.partName}</div>
                                    </td>
                                    <td className="px-3 py-4">
                                        <select
                                            value={item.quantity || '1'}
                                            onChange={(e) => handleQuantityChange(index, e.target.value)}
                                            className="block  rounded-md border-gray-300 shadow-sm text-xs focus:ring-indigo-500 focus:border-indigo-500"
                                        >
                                            {[1, 2, 3, 4, 5, 6, 7, 8].map((q) => (
                                                <option key={q} value={q}>{q}</option>
                                            ))}
                                        </select>
                                    </td>
                                    <td className="px-1 py-4">
                                        <input
                                            type="number"
                                            value={safeParseFloat(item.costPrice)}
                                            onChange={(e) => handleCostPriceChange(index, e.target.value)}
                                            className="block w-full rounded-md border-gray-300 shadow-sm text-xs focus:ring-indigo-500 focus:border-indigo-500"
                                            placeholder="Cost Price"
                                        />
                                    </td>
                                    <td className="px-3 py-4">
                                        <input
                                            type="text"
                                            value={safeParseFloat(item.worstPrice)}
                                            readOnly
                                            className="block w-full rounded-md border-gray-300 bg-gray-100 shadow-sm text-xs focus:ring-indigo-500 focus:border-indigo-500"
                                            placeholder="Highest Cost"
                                        />
                                    </td>
                                    <td className="px-3 py-4">
                                        <input
                                            type="number"
                                            value={safeParseFloat(item.salePrice)}
                                            onChange={(e) => handleSalePriceChange(index, e.target.value)}
                                            className="block w-full rounded-md border-gray-300 shadow-sm text-xs focus:ring-indigo-500 focus:border-indigo-500"
                                            placeholder="Sale Price"
                                        />
                                    </td>
                                    <td className="px-3 py-4">
                                        {formatCurrency(safeParseFloat(item.salePrice) * safeParseFloat(item.quantity))}
                                    </td>
                                    <td className="px-3 py-4 text-right">
                                        <button 
                                            onClick={() => handleRemoveFromCart(index)} 
                                            className="text-red-600 hover:text-red-900 transition-colors duration-150 ease-in-out"
                                        >
                                            <XCircleIcon className="h-6 w-6" />
                                        </button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="8" className="px-6 py-4 text-center text-gray-500">No items in the cart.</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};