import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, updateDoc, deleteDoc, doc, where, query } from "firebase/firestore";
import { db } from '../../firebase'; 
import { ToastContainer, toast } from 'react-toastify';
import DeleteConfirmationModal from './Subs/DeleteConfirmationModal';
import MarginSettingsForm from './Subs/MarginSettingsForm';
import DefaultSettings from './Subs/DefaultSettings';
import ServiceMargins from './Subs/ServiceMargins';
import VehicleMargins from './Subs/VehicleMargins';




const AdminMarginSettings = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [settings, setSettings] = useState({
    default: { serviceMargin: 0, partsMargin: 0 },
    vehicleDefaultMargin: 0,
    serviceDefaultMargin: 0,
    vehicles: [],
    services: []
  });
  const [formState, setFormState] = useState({
    type: 'default', // 'default', 'vehicles', 'services', 'default-vehicle', 'default-service'
    make: '',
    year: '',
    description: '',
    margin: 0
  });
  const [editMode, setEditMode] = useState(false);
  const [editId, setEditId] = useState(null);
  const [vehicleDefaultMarginId, setVehicleDefaultMarginId] = useState('');
  const [serviceDefaultMarginId, setServiceDefaultMarginId] = useState('');

  // Define fetchSettings as a standalone function
  const fetchSettings = async () => {
    try {
      const settingsSnapshot = await getDocs(collection(db, 'marginSettings'));
      const fetchedSettings = settingsSnapshot.docs.reduce((acc, docSnap) => {
        const setting = { id: docSnap.id, ...docSnap.data() };
        // Convert margin to a number
        setting.margin = Number(setting.margin);
  
        switch (setting.type) {
          case 'default':
            acc.default = { ...acc.default, ...setting };
            break;
          case 'default-vehicle':
            acc.vehicleDefaultMargin = setting.margin;
            break;
          case 'default-service':
            acc.serviceDefaultMargin = setting.margin;
            break;
          case 'vehicles':
            acc.vehicles.push(setting);
            break;
          case 'services':
            acc.services.push(setting);
            break;
          default:
            break;
        }
        return acc;
      }, {
        default: { serviceMargin: 0, partsMargin: 0 },
        vehicleDefaultMargin: 0,
        serviceDefaultMargin: 0,
        vehicles: [],
        services: []
      });
      console.log('Fetched Settings:', fetchedSettings);

      setSettings(fetchedSettings);
    } catch (error) {
      toast.error("Error fetching margin settings: " + error.message);
    }
  };
  
  
  const fetchDefaultMargins = async () => {
    const defaultVehicleQuery = query(collection(db, 'marginSettings'), where('type', '==', 'default-vehicle'));
    const defaultServiceQuery = query(collection(db, 'marginSettings'), where('type', '==', 'default-service'));

    const [vehicleMarginSnapshot, serviceMarginSnapshot] = await Promise.all([
      getDocs(defaultVehicleQuery),
      getDocs(defaultServiceQuery)
    ]);

    vehicleMarginSnapshot.forEach((doc) => {
      setVehicleDefaultMarginId(doc.id);
      setSettings((prevState) => ({ ...prevState, vehicleDefaultMargin: doc.data().margin }));
    });

    serviceMarginSnapshot.forEach((doc) => {
      setServiceDefaultMarginId(doc.id);
      setSettings((prevState) => ({ ...prevState, serviceDefaultMargin: doc.data().margin }));
    });
  };

  useEffect(() => {
    fetchDefaultMargins();
    fetchSettings();
  }, []);

  const handleEditDefaultMargin = (type) => {
    // Set the form state based on the type of default margin being edited
    if (type === 'vehicleDefaultMargin') {
      setFormState({ type: 'default-vehicle', margin: settings.vehicleDefaultMargin });
      setEditId(vehicleDefaultMarginId);
    } else if (type === 'serviceDefaultMargin') {
      setFormState({ type: 'default-service', margin: settings.serviceDefaultMargin });
      setEditId(serviceDefaultMarginId);
    }
    setEditMode(true);
  };

  const confirmDeleteDefaultMargin = async (type) => {
    // Set the deleteId based on the type of default margin being deleted
    if (type === 'vehicleDefaultMargin') {
      setDeleteId(vehicleDefaultMarginId);
    } else if (type === 'serviceDefaultMargin') {
      setDeleteId(serviceDefaultMarginId);
    }
    setShowDeleteModal(true);
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState(prevState => ({ ...prevState, [name]: value }));
  };

  const handleYearChange = (event) => {
    setFormState(prevState => ({ ...prevState, year: event.target.value }));
  };

  const handleMakeChange = (event) => {
    setFormState(prevState => ({ ...prevState, make: event.target.value }));
  };


  const isDuplicate = async (formData, excludeId = null) => {
    let queryRef = collection(db, 'marginSettings');
  
    queryRef = query(queryRef, where('type', '==', formData.type));

    if (formData.type === 'vehicles') {
      queryRef = query(queryRef, 
                       where('type', '==', 'vehicles'), 
                       where('make', '==', formData.make), 
                       where('year', '==', formData.year));
    } else if (formData.type === 'services') {
      queryRef = query(queryRef, 
                       where('type', '==', 'services'), 
                       where('description', '==', formData.description));
    } else if (formData.type === 'default-vehicle' || formData.type === 'default-service') {
      queryRef = query(queryRef, where('type', '==', formData.type));
    }
  
    const querySnapshot = await getDocs(queryRef);
   const duplicates = querySnapshot.docs.filter(doc => doc.id !== excludeId);
  return duplicates.length > 0;

  };
  
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formData = {};

    if (formState.type === 'default-vehicle') {
      formData = { type: 'default-vehicle', margin: formState.margin, description: 'Default Vehicle Margin' };
    } else if (formState.type === 'default-service') {
      formData = { type: 'default-service', margin: formState.margin, description: 'Default Service Margin' };
    } else {
      formData = { ...formState };
    }

    const checkingForDuplicates = !editMode || (editMode && formState.type.includes('default'));
  const duplicateExists = checkingForDuplicates && await isDuplicate(formData, editId);

  if (duplicateExists) {
    toast.error('A similar margin setting already exists.');
    return;
  }

  try {
    if (editMode) {
      // Updating an existing document
      const docRef = doc(db, 'marginSettings', editId);
      await updateDoc(docRef, formData);
      toast.success('Margin updated successfully.');
    } else {
      // Adding a new document
      await addDoc(collection(db, 'marginSettings'), formData);
      toast.success('Margin added successfully.');
    }
  } catch (error) {
    toast.error('An error occurred: ' + error.message);
  }

  setFormState({ type: 'default', make: '', year: '', description: '', margin: 0 });
  setEditId(null);
  setEditMode(false);
  await fetchSettings();
};

  const handleEdit = (setting) => {
    setEditMode(true);
    setEditId(setting.id);
    setFormState({ ...setting });
  };

  const confirmDelete = (id) => {
    setShowDeleteModal(true);
    setDeleteId(id);
  };

  const handleDelete = async () => {
    if (!deleteId) return;
    try {
      await deleteDoc(doc(db, 'marginSettings', deleteId));
      toast.success("Margin setting deleted successfully");
      setShowDeleteModal(false);
      setDeleteId(null);
      await fetchSettings();
    } catch (error) {
      toast.error("Error deleting margin setting: " + error.message);
    }
  };

  return (
    <>

    
    <div className="container mx-auto p-1 bg-gray-100">
       <ToastContainer />
      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <DeleteConfirmationModal
          showModal={showDeleteModal}
          onCancel={() => setShowDeleteModal(false)}
          onDelete={handleDelete}
        />
      )}
  
      {/* Form and Default Settings Section */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 mb-2">
        <div className="card bg-white p-2 rounded-lg shadow">
          <h2 className="text-2xl font-semibold mb-4">Margin Settings</h2>
          <MarginSettingsForm
            formState={formState}
            setFormState={setFormState} 
            handleSubmit={handleSubmit}
            handleInputChange={handleInputChange}
            handleMakeChange={handleMakeChange}
            handleYearChange={handleYearChange}
          />
        </div>
  
        <div className="card bg-white p-6 rounded-lg shadow">
          <h3 className="text-lg font-semibold">Default Settings</h3>
          <DefaultSettings
            settings={settings}
            handleEdit={handleEditDefaultMargin}
            confirmDelete={confirmDeleteDefaultMargin}
          />

        </div>
      </div>
  
      {/* Vehicle and Service Margins Section */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
        <div className="card bg-white p-2 rounded-lg shadow">
          <h3 className="text-lg font-semibold">Vehicle Specific Margins</h3>
          <VehicleMargins settings={settings} handleEdit={handleEdit} confirmDelete={confirmDelete} />
        </div>
  
        <div className="card bg-white p-2 rounded-lg shadow">
          <h3 className="text-lg font-semibold">Service Specific Margins</h3>
          <ServiceMargins settings={settings} handleEdit={handleEdit} confirmDelete={confirmDelete} />
        </div>
      </div>
    </div>
    </>
  );
  
  
                        };

export default AdminMarginSettings