import React from 'react';
import { Pie } from 'react-chartjs-2';

const GenderPieChart = ({ data }) => {
  const chartData = {
    labels: ['Male', 'Female'],
    datasets: [
      {
        label: '# of Customers',
        data: [data.Male, data.Female],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    legend: {
      display: true,
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
    },
  };

  return (
    <div>
      <Pie data={chartData} options={options} />
    </div>
  );
};

export default GenderPieChart;

