import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../../firebase';

const EscalationMode = () => {
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [{
            label: 'Top Escalation Channels',
            data: [],
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1,
        }]
    });

    useEffect(() => {
        const fetchEscalationMode = async () => {
            const querySnapshot = await getDocs(collection(db, "feedbacks"));
            const escalationTally = {};

            querySnapshot.forEach((doc) => {
                const escalationMode = doc.data().escalationMode;
                if (escalationTally[escalationMode]) {
                    escalationTally[escalationMode] += 1;
                } else {
                    escalationTally[escalationMode] = 1;
                }
            });

            // Use a functional update to avoid needing 'chartData' in the dependency array
            setChartData((prevData) => ({
                ...prevData,
                labels: Object.keys(escalationTally),
                datasets: [{
                    ...prevData.datasets[0],
                    data: Object.values(escalationTally),
                }]
            }));
        };

        fetchEscalationMode();
    }, []);

    return (
        <div>
            <Bar data={chartData} />
        </div>
    );
};

export default EscalationMode;
