import React, { useState, useEffect, useCallback} from 'react';
import { collection, addDoc, getDocs, updateDoc, deleteDoc, doc, getFirestore } from "firebase/firestore";
import app from '../../firebase';

const Services = () => {
  const [formState, setFormState] = useState({
    serviceName: '',
    serviceDescription: '',
  });
  const [services, setServices] = useState([]);
  const [currentId, setCurrentId] = useState('');

  const db = getFirestore(app);

  const fetchServices = useCallback(async () => {
    const querySnapshot = await getDocs(collection(db, "services"));
    setServices(
      querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
    );
  }, [db]);

  useEffect(() => {
    fetchServices();
  }, [fetchServices]); 
  

  const handleInputChange = (event) => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (currentId) {
      await updateDoc(doc(db, "services", currentId), {
        name: formState.serviceName,
        description: formState.serviceDescription,
      });
      setCurrentId('');
    } else {
      await addDoc(collection(db, "services"), {
        name: formState.serviceName,
        description: formState.serviceDescription,
      });
    }
    setFormState({
      serviceName: '',
      serviceDescription: '',
    });
    fetchServices();
  };

  const handleUpdate = (service) => {
    setFormState({ serviceName: service.name, serviceDescription: service.description });
    setCurrentId(service.id);
  };

  const handleDelete = async (id) => {
    await deleteDoc(doc(db, "services", id));
    fetchServices();
  };

  return (
    <div className="space-y-6 bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <h2 className="font-bold text-2xl mb-4">Services</h2>
      <form onSubmit={handleSubmit}>
       <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">Service Name</label>
        <input
          type="text"
          name="serviceName"
          value={formState.serviceName}
          onChange={handleInputChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2">Service Description</label>
        <textarea
          name="serviceDescription"
          value={formState.serviceDescription}
          onChange={handleInputChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          rows="4"
        />
      </div>
      <div className="flex items-center justify-between">
        <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
          Add Service
        </button>
      </div>
      </form>
      <div className="space-y-4">
        {services.map(service => (
          <div key={service.id} className="flex justify-between items-center border-b pb-2">
            <div>
              <h3 className="font-bold">{service.name}</h3>
              <p>{service.description}</p>
            </div>
            <div>
              <button onClick={() => handleUpdate(service)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2">
                Edit
              </button>
              <button onClick={() => handleDelete(service.id)} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
