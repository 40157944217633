import React from 'react';
import { ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight } from 'lucide-react';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const PageButton = ({ page, label, icon: Icon }) => (
        <button
            onClick={() => onPageChange(page)}
            disabled={page < 0 || page >= totalPages || page === currentPage}
            className={`flex items-center justify-center w-10 h-10 rounded-full transition-colors ${
                page === currentPage
                    ? 'bg-blue-600 text-white'
                    : 'bg-white text-gray-700 hover:bg-gray-100'
            } ${
                page < 0 || page >= totalPages ? 'opacity-50 cursor-not-allowed' : ''
            } focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
        >
            {Icon ? <Icon size={18} /> : label}
        </button>
    );

    const PageIndicator = ({ page }) => (
        <button
            onClick={() => onPageChange(page)}
            className={`w-10 h-10 rounded-full transition-colors ${
                page === currentPage
                    ? 'bg-blue-600 text-white'
                    : 'bg-white text-gray-700 hover:bg-gray-100'
            } focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
        >
            {page + 1}
        </button>
    );

    const renderPageIndicators = () => {
        const indicators = [];
        const range = 2;

        for (let i = Math.max(0, currentPage - range); i <= Math.min(totalPages - 1, currentPage + range); i++) {
            indicators.push(<PageIndicator key={i} page={i} />);
        }

        return indicators;
    };

    return (
        <div className="flex items-center justify-between mt-4 bg-white p-4 rounded-lg shadow-md">
            <div className="flex space-x-2">
                <PageButton page={0} icon={ChevronsLeft} />
                <PageButton page={currentPage - 1} icon={ChevronLeft} />
            </div>
            <div className="flex space-x-2">
                {renderPageIndicators()}
            </div>
            <div className="flex space-x-2">
                <PageButton page={currentPage + 1} icon={ChevronRight} />
                <PageButton page={totalPages - 1} icon={ChevronsRight} />
            </div>
        </div>
    );
};

export default Pagination;