export const JobDetails = ({ selectedJob }) => {
    
    return (
        <>
        <div>
        <p className="font-semibold text-sm text-gray-700">Vehicle: <span className="font-normal text-sm">{selectedJob?.vehicleYear} {selectedJob?.vehicleMake} {selectedJob?.vehicleModel}</span></p> 
      </div>
      <div>
        <p className="font-semibold text-sm text-gray-700">Customer: <span className="font-normal text-sm">{selectedJob?.customerName}</span></p>
      </div>
      <div>
        <p className="font-semibold text-sm text-gray-700">Job Card No: <span className="font-normal text-sm">{selectedJob?.jobCardNo}</span></p>
      </div>
      <div>
        <p className="font-semibold text-sm text-gray-700">Pricing Posted By: <span className="font-normal text-sm">{selectedJob?.pricingProvidedBy}</span></p>
      </div>
      <div>
        <p className="font-semibold text-sm text-gray-700">Estimated Work Duration: <span className="font-normal text-sm">{selectedJob?.estimatedDuration}</span></p>
      </div>
</>
    )
  };