import React from 'react'

const DeleteConfirmationModal = ({onDelete, setShowDeleteModal}) => {
  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-smoke-light flex">
    <div className="relative p-8 bg-white w-full max-w-md m-auto flex-col flex rounded-lg">
      <div>
        <h3>Are you sure you want to delete this setting?</h3>
      </div>
      <div className="mt-4">
        <button onClick={() => setShowDeleteModal(false)} className="bg-gray-300 hover:bg-gray-400 mr-2 text-black font-bold py-2 px-4 rounded">
          Cancel
        </button>
        <button onClick={onDelete} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
          Delete
        </button>
      </div>
    </div>
  </div>
  )
}

export default DeleteConfirmationModal