import React from 'react';

const ImageUpload = ({ onCapture }) => {
  const handleImageUpload = async (event) => {
    // Correctly define 'files' from the event target
    const files = event.target.files;
    if (!files.length) {
      return;
    }

    Array.from(files).forEach(file => {
      // Convert each image file to a data URL
      const reader = new FileReader();
      reader.onloadend = () => {
        // Use callback to send the data URL to the parent component
        onCapture(reader.result);
      };
      reader.readAsDataURL(file);
    });
  };

  return (
   
    <div className="mt-4">
        <label className="block mb-2 text-sm font-medium text-gray-700">
          Upload Image
        </label>
        <input
          className="block w-full text-sm text-gray-500
                    file:mr-4 file:py-2 file:px-4
                    file:rounded-full file:border-0
                    file:text-sm file:font-semibold
                    file:bg-violet-50 file:text-violet-700
                    hover:file:bg-violet-100
                    cursor-pointer"
          type="file"
          accept="image/*"
          capture="environment"
          multiple
          onChange={handleImageUpload}
       />

</div>
  
  );
};

export default ImageUpload;
