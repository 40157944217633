import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../../firebase';
import JobCardList from './JobCardList';

const VehicleDetails = ({ vehicle }) => {
  const [jobCards, setJobCards] = useState([]);

  useEffect(() => {
    // Fetch the job cards for the selected vehicle
    const fetchJobCards = async () => {
      const q = query(
        collection(db, 'jobs'),
        where('vehicleId', '==', vehicle.id)
      );
      const jobCardSnapshot = await getDocs(q);
      const jobCardList = jobCardSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setJobCards(jobCardList);
    };

    if (vehicle) {
      fetchJobCards();
    }
  }, [vehicle]);

  return (
    <div className="p-6 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        {vehicle.vehicleMake} {vehicle.vehicleModel} (
        {vehicle.registrationNumber})
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
        <div>
          <p className="text-gray-700 mb-2">
            <span className="font-medium">Vehicle Make:</span>{' '}
            {vehicle.vehicleMake}
          </p>
          <p className="text-gray-700 mb-2">
            <span className="font-medium">Vehicle Model:</span>{' '}
            {vehicle.vehicleModel}
          </p>
          <p className="text-gray-700 mb-2">
            <span className="font-medium">Registration Number:</span>{' '}
            {vehicle.registrationNumber}
          </p>
        </div>
        <div>
          <p className="text-gray-700 mb-2">
            <span className="font-medium">VIN:</span> {vehicle.VIN}
          </p>
          <p className="text-gray-700 mb-2">
            <span className="font-medium">GIN:</span> {vehicle.GIN}
          </p>
          <p className="text-gray-700 mb-2">
            <span className="font-medium">Number of Visits:</span>{' '}
            {jobCards.length}
          </p>
        </div>
      </div>

      <h3 className="text-xl font-semibold mb-4 text-gray-800">Job Cards</h3>
      {jobCards.length > 0 ? (
        <JobCardList jobCards={jobCards} />
      ) : (
        <p className="text-gray-500">No job cards found for this vehicle.</p>
      )}
    </div>
  );
};

export default VehicleDetails;
