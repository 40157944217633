import React from "react";

const PrintFriendlyComponentDetails = ({ components }) => {
    return (
      <div className="print-component-details">
        <h2>Component Details</h2>
        <table>
          <thead>
            <tr>
              <th>Component</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(components).map(([component, status], index) => (
              <tr key={index}>
                <td>{component.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}</td>
                <td>{status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  export default PrintFriendlyComponentDetails;