import React from "react";

const PrintFriendlyImageGallery = ({ inspectionData }) => {
    return (
      <div className="print-image-gallery">
        <h2>Inspection Images</h2>
        <div className="images">
          {Object.entries({
            acImageUrl: "AC and Cooling Inspection",
            // ... other image URL fields
          }).map(([key, caption]) => (
            inspectionData[key] && (
              <div key={key} className="image-container">
                <img src={inspectionData[key]} alt={caption} />
                <p>{caption}</p>
              </div>
            )
          ))}
        </div>
      </div>
    );
  };

  export default PrintFriendlyImageGallery;