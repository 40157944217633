import React, { useState, useEffect } from 'react';
import { collection, getDocs } from "firebase/firestore";
import { db } from '../../firebase'; // assuming that your Firebase app is exported as 'db'
import { Bar } from 'react-chartjs-2';
import { Chart, BarController, CategoryScale, LinearScale, BarElement } from 'chart.js';

Chart.register(BarController, CategoryScale, LinearScale, BarElement);


const VehicleBarChart = () => {
    const [vehicleData, setVehicleData] = useState({});

    useEffect(() => {
        const fetchVehicles = async () => {
            const vehicleCollection = collection(db, 'vehicles');
            const vehicleSnapshot = await getDocs(vehicleCollection);
            const vehicles = vehicleSnapshot.docs.map(doc => doc.data());
            setVehicleData(countByVehicleMake(vehicles));
        };

        fetchVehicles();
    }, []);

    const countByVehicleMake = (vehicles) => {
        const count = vehicles.reduce((acc, vehicle) => {
            const make = vehicle.vehicleMake;
            if (!acc[make]) {
                acc[make] = 1;
            } else {
                acc[make]++;
            }
            return acc;
        }, {});

        return {
            labels: Object.keys(count),
            datasets: [
                {
                    label: 'Vehicles by Make',
                    data: Object.values(count),
                    backgroundColor: 'rgba(75,192,192,0.4)',
                    borderColor: 'rgba(75,192,192,1)',
                    borderWidth: 1
                }
            ]
        };
    }

    return (
        <div>
            {vehicleData && vehicleData.datasets && vehicleData.labels && vehicleData.datasets.length > 0 ?
                <Bar
                    data={vehicleData}
                    options={{
                        responsive: true,
                        scales: {
                            y: {
                                beginAtZero: true
                            }
                        }
                    }}
                /> 
                : 
                <div>No data to display</div>
            }
        </div>
    );
    
}

export default VehicleBarChart;
