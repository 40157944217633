import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc as firestoreDoc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { CSVLink } from 'react-csv';
import { format } from 'date-fns';

const LeaveReports = () => {
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [employeeId, setEmployeeId] = useState('');
  const [leaveType, setLeaveType] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const employeesSnapshot = await getDocs(collection(db, 'employees'));
        const employeesList = employeesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEmployees(employeesList);
      } catch (error) {
        console.error('Failed to fetch employees:', error);
      }
    };

    const fetchLeaveRequests = async () => {
      try {
        const leaveRequestsSnapshot = await getDocs(collection(db, 'leaveRequests'));
        const requests = await Promise.all(
          leaveRequestsSnapshot.docs.map(async (leaveDoc) => {
            const data = leaveDoc.data();

            // Fetch employee name
            const employeeDocRef = firestoreDoc(db, 'employees', data.employeeId);
            const employeeDocSnap = await getDoc(employeeDocRef);
            const employeeName = employeeDocSnap.exists()
              ? `${employeeDocSnap.data().firstName} ${employeeDocSnap.data().lastName}`
              : 'Unknown Employee';

            // Fetch temporary designate name
            const designateDocRef = firestoreDoc(db, 'employees', data.tempDesignateId);
            const designateDocSnap = await getDoc(designateDocRef);
            const designateName = designateDocSnap.exists()
              ? `${designateDocSnap.data().firstName} ${designateDocSnap.data().lastName}`
              : 'Unknown Designate';

            return {
              id: leaveDoc.id,
              ...data,
              employeeName,
              designateName,
            };
          })
        );
        setLeaveRequests(requests);
        setFilteredRequests(requests);
      } catch (error) {
        console.error('Failed to fetch leave requests:', error);
      }
    };

    fetchEmployees();
    fetchLeaveRequests();
  }, []);

  const handleFilter = () => {
    let filtered = leaveRequests;

    if (employeeId) {
      filtered = filtered.filter(request => request.employeeId === employeeId);
    }
    if (leaveType) {
      filtered = filtered.filter(request => request.leaveType === leaveType);
    }
    if (startDate && endDate) {
      filtered = filtered.filter(request =>
        request.leaveDates.some(date =>
          new Date(date.seconds * 1000) >= new Date(startDate) &&
          new Date(date.seconds * 1000) <= new Date(endDate)
        )
      );
    }

    setFilteredRequests(filtered);
  };

  const headers = [
    { label: 'Employee Name', key: 'employeeName' },
    { label: 'Leave Type', key: 'leaveType' },
    { label: 'Leave Dates', key: 'leaveDates' },
    { label: 'Temporary Designate', key: 'designateName' },
    { label: 'Status', key: 'status' },
  ];

  const data = filteredRequests.map(request => ({
    employeeName: request.employeeName,
    leaveType: request.leaveType,
    leaveDates: request.leaveDates.map(date => format(new Date(date.seconds * 1000), 'yyyy-MM-dd')).join(', '),
    designateName: request.designateName,
    status: request.status,
  }));

  return (
    <div className="p-6 bg-white rounded-md shadow-md">
      <h2 className="text-lg font-semibold text-gray-900 mb-4">Leave Reports</h2>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Left Column - Filters */}
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Employee</label>
            <select 
              value={employeeId} 
              onChange={(e) => setEmployeeId(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option value="">All Employees</option>
              {employees.map(employee => (
                <option key={employee.id} value={employee.id}>
                  {employee.firstName} {employee.lastName}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Leave Type</label>
            <select 
              value={leaveType} 
              onChange={(e) => setLeaveType(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option value="">All Types</option>
              <option value="Annual Leave">Annual Leave</option>
              <option value="Casual Leave">Casual Leave</option>
              <option value="Paternity Leave">Paternity Leave</option>
              <option value="Maternity Leave">Maternity Leave</option>
              <option value="Compassionate Leave">Compassionate Leave</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Start Date</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">End Date</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>

          <div className="flex justify-between">
            <button
              onClick={handleFilter}
              className="bg-blue-600 text-white px-4 py-2 rounded-md shadow-sm hover:bg-blue-700"
            >
              Filter Reports
            </button>
            <CSVLink 
              data={data} 
              headers={headers} 
              filename="leave_reports.csv"
              className="bg-green-600 text-white px-4 py-2 rounded-md shadow-sm hover:bg-green-700"
            >
              Download CSV
            </CSVLink>
          </div>
        </div>

        {/* Right Column - Filtered Results */}
        <div>
          {filteredRequests.length > 0 ? (
            <div className="space-y-4">
              <h3 className="text-md font-semibold text-gray-900">Filtered Results</h3>
              <ul className="space-y-2">
                {filteredRequests.map(request => (
                <li key={request.id} className="p-6 bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 ease-in-out border border-gray-200">
                <div className="space-y-3">
                  <p className="text-lg font-semibold text-gray-800">
                    <span className="text-indigo-600">Employee:</span> {request.employeeName}
                  </p>
                  <p className="text-md text-gray-700">
                    <span className="font-medium text-indigo-600">Leave Type:</span> {request.leaveType}
                  </p>
                  <div className="space-y-2">
                    <p className="text-sm text-gray-600">
                      <span className="font-medium text-indigo-600">Dates:</span> 
                    </p>
                    <ul className="list-disc ml-6">
                      {request.leaveDates.map((date, index) => (
                        <li key={index}>{format(new Date(date.seconds * 1000), 'dd-MM-yyyy')}</li>
                      ))}
                    </ul>
                  </div>
                  <p className="text-sm text-gray-700">
                    <span className="font-medium text-indigo-600">Designate:</span> {request.designateName}
                  </p>
                  <p className="mt-2">
                    <span className="font-medium text-indigo-600">Status:</span> 
                    <span className={`inline-block px-3 py-1 text-sm font-semibold rounded-full ${
                      request.status === 'Approved' ? 'bg-green-200 text-green-800' :
                      request.status === 'Rejected' ? 'bg-red-200 text-red-800' :
                      'bg-yellow-200 text-yellow-800'
                    }`}>
                      {request.status}
                    </span>
                  </p>
                </div>
              </li>
                ))}
              </ul>
            </div>
          ) : (
            <p className="text-gray-600">No leave requests match the selected filters.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default LeaveReports;
