import React from "react";


const StatusSummary = ({ counts }) => {
    return (
      <div className='status-summary w-full'>
        <table className="min-w-full divide-y divide-gray-200 shadow overflow-hidden border-b border-gray-200 pb-2 sm:rounded-lg">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
               Vehicle Component Status
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Count
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            <tr>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                Ok
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {counts.Ok}
              </td>
            </tr>
            <tr>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                Defective
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {counts.Defective}
              </td>
            </tr>
            <tr>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                Service
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {counts.Service}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };
  
  export default StatusSummary;