import React from 'react';
import { formatCurrency } from "../../Help/helper";
import { PlusCircleIcon } from '@heroicons/react/24/solid';

export const NewItemForm = ({ newPart, setNewPart, addNewPartToCart, services }) => {
  // Ensure all form fields have initial values
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPart(prev => ({ ...prev, [name]: value }));
  };

  return (
    <div className="bg-white shadow-md rounded-lg overflow-hidden mb-6">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            {['Type', 'Description', 'Qty', 'Cost Price', 'Sale Price', 'Total', ''].map((header, index) => (
              <th key={index} className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="px-1 py-4 whitespace-nowrap">
              <select
                name="type"
                value={newPart.type || ''}
                onChange={handleInputChange}
                className="block w-full rounded-md border-gray-300 shadow-sm text-xs focus:ring-indigo-500 focus:border-indigo-500"
                                        >
                <option value="">Select Type</option>
                <option value="generic">Generic Part</option>
                <option value="vehicle-specific">Vehicle-Specific Part</option>
                <option value="service">Service</option>
              </select>
              {newPart.type === 'service' && (
                <select
                  name="serviceName"
                  value={newPart.serviceName || ''}
                  onChange={handleInputChange}
                  className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                >
                  <option value="">Select Service</option>
                  {services.map((service, index) => (
                    <option key={index} value={service.name}>
                      {service.name}
                    </option>
                  ))}
                </select>
              )}
            </td>
            <td className="px-1 py-3 whitespace-nowrap">
              <input 
                type="text"
                name="partName"
                value={newPart.partName || ''}
                onChange={handleInputChange}
                placeholder="Part or Service Name"
                className="block rounded-md border-gray-300 shadow-sm text-xs focus:ring-indigo-500 focus:border-indigo-500"
              />
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <select
                name="quantity"
                value={newPart.quantity || '1'}
                onChange={handleInputChange}
                className="block  rounded-md border-gray-300 shadow-sm text-xs focus:ring-indigo-500 focus:border-indigo-500"
                >
                {[1, 2, 3, 4, 5, 6, 7, 8].map(q => <option key={q} value={q}>{q}</option>)}
              </select>
            </td>
            <td className="px-2 py-4 whitespace-nowrap">
              <input 
                type="number"
                name="costPrice"
                value={newPart.costPrice || ''}
                onChange={handleInputChange}
                placeholder="Cost Price"
                className="block rounded-md border-gray-300 shadow-sm text-xs focus:ring-indigo-500 focus:border-indigo-500"
               
            />
            </td>
            <td className="px-2 py-4 whitespace-nowrap">
              <input 
                type="number"
                name="salePrice"
                value={newPart.salePrice || ''}
                onChange={handleInputChange}
                placeholder="Sale Price"
                className="block rounded-md border-gray-300 shadow-sm text-xs focus:ring-indigo-500 focus:border-indigo-500"
                  />
            </td>
            <td className="px-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              {newPart.salePrice && newPart.quantity ? formatCurrency(newPart.salePrice * newPart.quantity) : ''}
            </td>
            <td className="px-1 py-4 whitespace-nowrap text-right text-sm font-medium">
              <button
                onClick={addNewPartToCart}
                className="inline-flex items-center px-1 py-2 border border-transparent text-xs font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <PlusCircleIcon className="h-5 w-5 text-xs" />
                Add
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};