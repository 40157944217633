import React, { useRef } from 'react';
import { formatCurrency } from '../../Help/helper';
import Logo from "../../../images/bigAyden.jpg";
import '../Utils/QuotationModalPrint.css';
import '../Utils/Print.css';
import { TermsAndConditions } from '../Utils/TermsAndConditions';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const PrintableQuotation = ({ quotation, totals, onClose }) => {
  const printRef = useRef();

  if (!quotation) return null;


  const handlePrint = () => {
    const element = printRef.current;

    // Hide the buttons
    const buttons = element.querySelectorAll('.hide-on-print');
    buttons.forEach(button => button.style.display = 'none');

    html2canvas(element, { scale: 2 }).then(canvas => {
        const imgData = canvas.toDataURL('image/jpeg');
        const pdf = new jsPDF('p', 'mm', 'a4');

        // Define the dimensions of the PDF
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgProps = pdf.getImageProperties(imgData);
        const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

        const bottomMargin = 20; // Ensure there's a margin at the bottom of the page
        const contentHeight = pdfHeight - bottomMargin - 20; // Account for margins

        let position = 0;
        let firstPage = true;

        // Manually manage page breaks and ensure content fits above the bottom margin
        while (position < imgHeight) {
            if (!firstPage) {
                position -= 20; // Add a small gap between pages for content continuation
            }

            pdf.addImage(imgData, 'JPEG', 0, -position, pdfWidth, imgHeight);
            position += contentHeight;

            // Add new page if there's more content
            if (position < imgHeight) {
                pdf.addPage();
                firstPage = false;

                // Optionally, repeat the table header or other content on new page
                pdf.setFontSize(10);
                pdf.text("Item", 10, 20);
                pdf.text("Quantity", 50, 20);
                pdf.text("Price", 90, 20);
                pdf.text("Extended Price", 130, 20);
            }
        }

        // Save the PDF
        pdf.save('Aydenquotation.pdf');

        // Show the buttons again
        buttons.forEach(button => button.style.display = '');
    });
};



  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full print:bg-white" id="print-invoice-modal">
  <div className="bg-white p-5 rounded-lg max-w-4xl w-full overflow-auto" id="printableArea" ref={printRef}>
    <div className="flex justify-between items-start p-2">
      <div className="flex flex-col justify-between">
        <div className="flex">
          <img src={Logo} alt="Logo" className="w-40 h-40 object-contain" />
        </div>
        <div className='ml-2'>
          <h2 className="text-3xl leading-6 font-medium uppercase mb-1 text-blue-800">QUOTATION</h2>
          <p className="text-sm text-gray-500"> <span className='text-black font-bold'>Job Card No:</span> {quotation.jobCardNo}</p>
          <p className="text-sm text-gray-500"> <span className='text-black font-bold'>Date:</span> {new Date().toLocaleDateString()}</p>
        </div>
      </div>
      <div className="text-right">
        <h2 className="text-2xl font-bold">AYDENS Garage</h2>
        <p className="text-sm">Block 7, Plot 7, Etal Avenue, Off Kudirat Abiola Way,<br/> Oregun, Lagos, Nigeria.</p>
        <p className="text-sm">+234-7045972414</p>
        <p className="text-sm">info@aydensgarage.com</p>
      </div>
    </div>

    <div className="mt-2 mb-4 px-4">
      <h3 className="leading-6 font-medium text-gray-900">CUSTOMER:</h3>
      <p className="text-sm text-gray-700">{quotation.customerName}</p>
      <p className="text-sm text-gray-700">{quotation.customerEmail}</p>
      <p className="text-sm text-gray-700">{quotation.customerPhone}</p>
    </div>

    <table className="min-w-full leading-normal">
      <thead>
        <tr>
          <th className="px-4 py-2 border-b-2 border-gray-200 bg-gray-100 text-xs font-semibold text-gray-600 uppercase tracking-wider">Item</th>
          <th className="px-4 py-2 border-b-2 border-gray-200 bg-gray-100 text-xs font-semibold text-gray-600 uppercase tracking-wider">Quantity</th>
          <th className="px-4 py-2 border-b-2 border-gray-200 bg-gray-100 text-xs font-semibold text-gray-600 uppercase tracking-wider">Price</th>
          <th className="px-4 py-2 border-b-2 border-gray-200 bg-gray-100 text-xs font-semibold text-gray-600 uppercase tracking-wider">Extended Price</th>
        </tr>
      </thead>
      <tbody className="pb-4">
        {quotation.cartItems.map((item, index) => {
          const extendedPrice = item.extendedPrice || (item.quantity * item.salePrice);
          return (
            <tr key={index}>
              <td className="px-4 py-1 border-b border-gray-200 bg-white text-sm text-center">{item.description}</td>
              <td className="px-4 py-1 border-b border-gray-200 bg-white text-sm text-center">{item.quantity}</td>
              <td className="px-4 py-1 border-b border-gray-200 bg-white text-sm text-center">{formatCurrency(item.salePrice)}</td>
              <td className="px-4 py-1 border-b border-gray-200 bg-white text-sm text-center">{isNaN(extendedPrice) ? 'N/A' : formatCurrency(extendedPrice)}</td>
            </tr>
          );
        })}
      </tbody>
    </table>

    <div className="mt-4  mb-4 text-right pr-20">
      <p className="text-sm text-gray-900 font-semibold">Subtotal: {formatCurrency(totals.subtotal)}</p>
      <p className="text-sm text-gray-500">Tax: {formatCurrency(totals.taxAmount)}</p>
      <p className="text-lg text-gray-900 font-bold">Total: {formatCurrency(totals.totalSale)}</p>
    </div>

    <TermsAndConditions />

    <div className="hide-on-print">
      <button className="mt-4 mb-2 bg-blue-800 hover:bg-amber-400 text-white font-bold py-2 px-4 rounded" onClick={handlePrint}>
        Print
      </button>

      <button className="mt-4 mb-2 bg-red-600 hover:bg-amber-400 text-white font-bold py-2 px-4 rounded" onClick={onClose}>
        Close
      </button>
    </div>
  </div>
</div>

  );
};

export default PrintableQuotation;
