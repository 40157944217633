import React, { useEffect, useState, Fragment } from 'react';
import { doc, updateDoc, query, where, collection, onSnapshot } from 'firebase/firestore';
import { db } from '../../../firebase';
import { toast } from 'react-toastify';
import { Dialog, Transition } from '@headlessui/react';

const formatDate = (date) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
  return new Date(date).toLocaleString(undefined, options);
};

const JobHeader = ({ jobCard }) => {
  const [isFullyPaid, setIsFullyPaid] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [exitMileage, setExitMileage] = useState('');
  const [jobClosureComments, setJobClosureComments] = useState('');
  const [nextServiceMileage, setNextServiceMileage] = useState('');
  const [deliveryDate, setDeliveryDate] = useState('');
  const entryMileage = jobCard?.entryMileage || '';

  useEffect(() => {
    if (jobCard?.jobCardNo) {
      const q = query(collection(db, 'newInvoices'), where('jobCardNo', '==', jobCard.jobCardNo));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const invoices = snapshot.docs.map(doc => doc.data());
        const fullyPaidInvoice = invoices.find(invoice => invoice.invoiceStatus === 'Fully Paid');
        setIsFullyPaid(!!fullyPaidInvoice);
        setLoading(false);
      });
      return () => unsubscribe();
    }
  }, [jobCard?.jobCardNo]);

  const handleCloseJob = async () => {
    if (parseInt(exitMileage) < parseInt(entryMileage)) {
      toast.error('Exit mileage cannot be lower than entry mileage.');
      return;
    }

    try {
      const jobRef = doc(db, 'jobs', jobCard.id);
      await updateDoc(jobRef, {
        exitMileage,
        jobClosureComments,
        nextServiceMileage,
        deliveryDate: new Date(deliveryDate),
        status: 'Closed',
      });
      toast.success('Job closed successfully');
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error closing job:', error);
      toast.error('Failed to close job');
    }
  };

  return (
    <div className="flex flex-col md:flex-row gap-4 p-2 bg-gray-50 rounded-lg shadow">
      <div className="md:w-1/2 p-2 bg-white rounded-lg shadow-md">
        <h2 className="text-xl font-semibold text-blue-800 border-b pb-1 mb-1">
          Customer: <span className='text-black'>{jobCard?.customerName}</span> {" "}
          <span className='text-sm text-red-400'>{jobCard?.customerEmail}</span>
        </h2>
        <div className="space-y-1 text-gray-700">
          <p className='text-sm'><strong className="font-medium text-sm text-gray-900">Vehicle:</strong> {jobCard?.vehicleMake} {jobCard?.vehicleModel} ({jobCard?.registrationNumber})</p>
          <p className='text-sm'><strong className="font-medium text-sm text-gray-900">VIN:</strong> {jobCard?.VIN}</p>
          <p className='text-sm'><strong className="font-medium text-sm text-gray-900">Entry Mileage:</strong> {jobCard?.entryMileage}</p>
        </div>
      </div>

      <div className="md:w-1/2 p-2 bg-white rounded-lg shadow-md">
        <h2 className="text-xl font-semibold text-blue-800 border-b pb-1 mb-1">Job Card: {jobCard?.jobCardNo}</h2>
        <div className="space-y-1 text-gray-700">
          <p className='text-sm'><strong className="font-medium text-sm text-gray-900">Date Posted:</strong> {formatDate(jobCard?.createdAt.toDate())}</p>
          <p className='text-sm'>
            <strong className="font-medium text-sm text-gray-900">Job Status:</strong> 
            <span className={`px-2 py-1 rounded ${jobCard?.status === 'Open' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
              {jobCard?.status}
            </span>
          </p>
          <div>
            <button
              disabled={!isFullyPaid}
              onClick={() => setIsModalOpen(true)}
              className={`px-4 py-2 font-semibold text-white rounded-lg ${isFullyPaid ? 'bg-blue-600 hover:bg-blue-700' : 'bg-gray-400 cursor-not-allowed'}`}
            >
              {loading ? 'Checking Status...' : 'Close Job'}
            </button>
          </div>
        </div>
      </div>

      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setIsModalOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-sm font-medium leading-6 text-gray-900">
                    Close Job
                  </Dialog.Title>

                  <div className="mt-4">
                    <div className="mb-2">
                      <label htmlFor="exitMileage" className="block text-sm font-medium text-gray-700">Exit Mileage</label>
                      <input
                        type="number"
                        id="exitMileage"
                        value={exitMileage}
                        onChange={(e) => setExitMileage(e.target.value)}
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>

                    <div className="mb-2">
                      <label htmlFor="jobClosureComments" className="block text-sm font-medium text-gray-700">Job Closure Comments</label>
                      <textarea
                        id="jobClosureComments"
                        value={jobClosureComments}
                        onChange={(e) => setJobClosureComments(e.target.value)}
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>

                    <div className="mb-2">
                      <label htmlFor="nextServiceMileage" className="block text-sm font-medium text-gray-700">Next Service Mileage</label>
                      <input
                        type="number"
                        id="nextServiceMileage"
                        value={nextServiceMileage}
                        onChange={(e) => setNextServiceMileage(e.target.value)}
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>

                    <div className="mb-2">
                      <label htmlFor="deliveryDate" className="block text-sm font-medium text-gray-700">Delivery Date</label>
                      <input
                        type="date"
                        id="deliveryDate"
                        value={deliveryDate}
                        onChange={(e) => setDeliveryDate(e.target.value)}
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="mt-4 flex justify-end space-x-2">
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-200 border border-transparent rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      onClick={() => setIsModalOpen(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      onClick={handleCloseJob}
                    >
                      Confirm
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default JobHeader;
