import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase';
import QuotationModal from './Utils/QuotationModal';
import { JobDetails } from './partials/JobDetails';
import { CartItemsList } from './partials/CartItemsList';
import { NewItemForm } from './partials/NewItemForm';
import { TotalsDisplay } from './partials/TotalsDisplay';
import {MarginAnalysis } from './partials/MarginAnalysis';


const Quotation = () => {
  const { jobCardNo } = useParams();
  const [selectedJob, setSelectedJob] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [includeTax, setIncludeTax] = useState(false);
  const [services, setServices] = useState([]);
   const [marginSettings, setMarginSettings] = useState({
    default: { serviceMargin: 0, partsMargin: 0 },
    vehicleDefaultMargin: 0,
    serviceDefaultMargin: 0,
    vehicles: [],
    services: []
  });
  const [newPart, setNewPart] = useState({ partName: '', quantity: 1, type: 'generic' });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [totals, setTotals] = useState({
    totalCost: 0,
    subtotal: 0,
    totalSale: 0,
    taxAmount: 0
  });
  

  const fetchJobData = useCallback(async () => {
    try {
      const jobsCollectionRef = collection(db, 'jobs');
      const queryByJobCardNo = query(jobsCollectionRef, where('jobCardNo', '==', jobCardNo));
      const querySnapshot = await getDocs(queryByJobCardNo);

      if (!querySnapshot.empty) {
        const jobData = querySnapshot.docs[0].data(); // Assuming there is only one job with this jobCardNo
        setSelectedJob(jobData);
      } else {
        toast.error('Job not found');
      }
    } catch (error) {
      toast.error('Error fetching job data:', error);
    }
  }, [jobCardNo]);

  useEffect(() => {
    if (jobCardNo) {
      fetchJobData();
    }
  }, [jobCardNo, fetchJobData]); 
  
  
  const fetchServices = useCallback(async () => {
    const querySnapshot = await getDocs(collection(db, 'services'));
    const fetchedServices = querySnapshot.docs.map(doc => doc.data());
    setServices(fetchedServices);
  }, []);

  useEffect(() => {
    fetchServices();
  }, [fetchServices]);

  const fetchMarginSettings = useCallback(async () => {
    try {
      const settingsSnapshot = await getDocs(collection(db, 'marginSettings'));
      const fetchedSettings = settingsSnapshot.docs.reduce((acc, docSnap) => {
        const setting = { id: docSnap.id, ...docSnap.data() };
        switch (setting.type) {
          case 'default':
            acc.default = { ...acc.default, ...setting };
            break;
          case 'default-vehicle':
            acc.vehicleDefaultMargin = setting.margin;
            break;
          case 'default-service':
            acc.serviceDefaultMargin = setting.margin;
            break;
          case 'vehicles':
            acc.vehicles.push(setting);
            break;
          case 'services':
            acc.services.push(setting);
            break;
          default:
            break;
        }
        return acc;
      }, marginSettings);
      setMarginSettings(fetchedSettings);
    } catch (error) {
      toast.error("Error fetching margin settings: " + error.message);
    }
  }, [marginSettings]);

  useEffect(() => {
    if (selectedJob && selectedJob.partsRequired) {
      const updatedCartItems = selectedJob.partsRequired.map(part => ({
        partName: part.partName,
        quantity: part.quantity,
        costPrice: '', // Placeholder for user input
        salePrice: '', // Placeholder to be calculated
        type: part.type || 'generic'
      }));
      setCartItems(updatedCartItems);
    }
    fetchMarginSettings();
  }, [selectedJob, fetchMarginSettings]);

  const handleTypeChange = (index, value) => {
    const updatedCartItems = [...cartItems];
    updatedCartItems[index].type = value;
    setCartItems(updatedCartItems);
  };

  const calculateTotals = useCallback(() => {
    let subtotal = 0;
    let totalSale = 0;
    let totalCost = 0;
  
    cartItems.forEach(item => {
      const itemTotal = (item.salePrice || 0) * item.quantity;
      subtotal += itemTotal;
      totalCost += (item.costPrice || 0) * item.quantity;
    });
  
    if (newPart.costPrice && newPart.salePrice) {
      const newPartTotal = newPart.salePrice * newPart.quantity;
      subtotal += newPartTotal;
      totalCost += newPart.costPrice * newPart.quantity;
    }
  
    let taxAmount = 0;
    if (includeTax) {
      const taxRate = 0.075; // 7.5%
      taxAmount = subtotal * taxRate;
      totalSale = subtotal + taxAmount;
    } else {
      totalSale = subtotal;
    }
  
    return { totalCost, subtotal, totalSale, taxAmount };
  }, [cartItems, newPart, includeTax]);
  
  

  const updateTotals = useCallback(() => {
    const calculatedTotals = calculateTotals(); 
    setTotals(calculatedTotals);
  }, [calculateTotals]);
  
  useEffect(() => {
    updateTotals();
  }, [updateTotals]);
  
 
  const calculateSalePrice = (item) => {
    let margin = 0;
  
    if (item.type === 'service') {
      // Apply service-specific margin or default service margin
      const serviceMargin = marginSettings.services.find(s => s.description === item.partName);
      margin = serviceMargin ? Number(serviceMargin.margin) : Number(marginSettings.serviceDefaultMargin);
    } else if (item.type === 'vehicle-specific') {
      // Apply vehicle-specific margin or default vehicle margin
      const vehicleMargin = marginSettings.vehicles.find(v =>
        v.make === selectedJob.vehicleMake &&
        v.year === selectedJob.vehicleYear // Use vehicleYear if available in selectedJob
      );
      margin = vehicleMargin ? Number(vehicleMargin.margin) : Number(marginSettings.vehicleDefaultMargin);
    } else { // For generic parts
      margin = Number(marginSettings.default.partsMargin);
    }
      return item.costPrice * (1 + margin / 100);
  };
  
  const calculateNewPartSalePrice = () => {
    let margin = 0;
    if (newPart.type === 'service') {
      const serviceMargin = marginSettings.services.find(s => s.description === newPart.partName);
      margin = serviceMargin ? Number(serviceMargin.margin) : Number(marginSettings.serviceDefaultMargin);
    } else if (newPart.type === 'vehicle-specific') {
      const vehicleMargin = marginSettings.vehicles.find(v => v.make === selectedJob.vehicleMake);
      margin = vehicleMargin ? Number(vehicleMargin.margin) : Number(marginSettings.vehicleDefaultMargin);
    } else {
      margin = Number(marginSettings.default.partsMargin);
    }
    return newPart.costPrice * (1 + margin / 100);
  };
   

  const handleCostPriceChange = (index, value) => {
    const updatedCartItems = [...cartItems];
    updatedCartItems[index].costPrice = Number(value);
    setCartItems(updatedCartItems);
  };


  const applyOfficialMargins = () => {
    const updatedCartItems = cartItems.map(item => ({
      ...item,
      salePrice: calculateSalePrice(item)
    }));
    setCartItems(updatedCartItems);
  
    const newSalePrice = calculateNewPartSalePrice();
    setNewPart({ ...newPart, salePrice: newSalePrice });
  };
 
  const handleRemoveFromCart = (index) => {
    const updatedCartItems = cartItems.filter((_, i) => i !== index);
    setCartItems(updatedCartItems);
  };

    const addNewPartToCart = () => {
    if (!newPart.partName || !newPart.costPrice || !newPart.type) {
      toast.error('Please enter all details for the new part');
      return;
    }
    
    const newSalePrice = calculateNewPartSalePrice();
    
    if (isNaN(newSalePrice)) {
      toast.error('Invalid cost price');
      return;
    }
  
    setCartItems([...cartItems, { ...newPart, salePrice: newSalePrice }]);
    setNewPart({ partName: '', quantity: 1, costPrice: '', salePrice: '', type: 'generic' }); // Reset the form
  };
  
  const handleQuantityChange = (index, newQuantity) => {
    setCartItems(currentItems => {
        const newItems = [...currentItems];
        newItems[index] = { ...newItems[index], quantity: Number(newQuantity) };
        return newItems;
    });
};

const toggleModal = () => setIsModalOpen(!isModalOpen);


const finalizeQuotation = () => {
  if (newPart.partName && !cartItems.some(item => item.partName === newPart.partName)) {
    setCartItems([...cartItems, { ...newPart, salePrice: calculateNewPartSalePrice() }]);
  }

  // Reset newPart to initial state after adding to cartItems
  setNewPart({ partName: '', quantity: 1, costPrice: '', salePrice: '', type: 'generic' });
  
  toggleModal();
};

 const quotationDetails = {
  items: cartItems,
  newItems: [newPart], // Assuming newPart is a single object
  subtotal: calculateTotals().totalCost,
  tax: calculateTotals().taxAmount,
  total: calculateTotals().totalSale
  // Add more details as needed
};

const customerDetails = {
  name: selectedJob?.customerName,
  address: selectedJob?.customerAddress,
  email: selectedJob?.customerEmail  
};


  return (
    <div className="container mx-auto p-4 bg-white shadow-md rounded">
            <header className="mb-4">
                <h1 className="text-3xl font-bold text-gray-800 mb-2">Quotation Generator</h1>
                <div className="flex justify-between">
                  <JobDetails selectedJob={selectedJob} />
                </div>
         </header>

               <main className="flex flex-wrap mt-4">

                         <section className="w-full lg:w-4/5 lg:pr-4">
                            <CartItemsList 
                            cartItems={cartItems}
                            handleQuantityChange={handleQuantityChange}
                            handleCostPriceChange={handleCostPriceChange}
                            handleTypeChange={handleTypeChange}
                            handleRemoveFromCart={handleRemoveFromCart}
                          />          
                                                    
                                   
                              {/* Seperator */}
                              <div className="flex items-center mb-2"> 
                                <hr className="flex-grow border-t border-gray-300" /> 
                                <span className="px-3 text-sm text-gray-500"> 
                                    Add additional items to the job card quotation below
                                </span> 
                                <hr className="flex-grow border-t border-gray-300" /> 
                            </div> 
                  
                            <NewItemForm newPart={newPart} setNewPart={setNewPart} addNewPartToCart={addNewPartToCart} services={services} />
                                
                                <button
                                  onClick={applyOfficialMargins}
                                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                                >
                                  Apply Official Margins
                                </button>        
                          </section>

                                <aside className="w-full lg:w-1/5">
                                <TotalsDisplay 
                                  totals={totals}                                  
                                  includeTax={includeTax} 
                                  setIncludeTax={setIncludeTax} 
                                  finalizeQuotation={finalizeQuotation} 
                                />

                                <MarginAnalysis totals={totals} />
                                  </aside>
                      </main>
              
       
                  <ToastContainer />

                          <QuotationModal
                      isOpen={isModalOpen}
                      totals={totals} 
                      onClose={toggleModal}
                      customerDetails={customerDetails}
                      quotationDetails={quotationDetails}
                      selectedJob={selectedJob}
                      newPart={newPart} 
                    />

    </div>
  );
};

export default Quotation;