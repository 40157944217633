import React from "react";

const PrintFriendlyEngineerSection = ({ comments, recommendations }) => {
    return (
      <div className="print-engineer-section bg-white pt-10 mb-6 pb-10 print:flex print:flex-col print:justify-between print:h-a4">
       <h2 className="text-xl font-semibold mb-4 border-b pb-2">Comments and Recommendations</h2>
        <h2 className="text-lg font-semibold mb-2 bg-gray-200 text-gray-600 p-2">Engineer Comments</h2>
        <p  className="text-gray-600">{comments || "No comments provided."}</p>
        <h2 className="text-lg font-semibold mb-2 bg-gray-200 text-gray-600 p-2">Recommendations</h2>
        <p  className="text-gray-600">{recommendations || "No recommendations provided."}</p>
      </div>
    );
  };

  export default PrintFriendlyEngineerSection;