import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { formatCurrency } from '../Help/helper';
import MoonLoader from 'react-spinners/MoonLoader';
import PayableModal from './partials/PayableModal';



const Payables = () => {
  const [payables, setPayables] = useState([]);
  const [filteredPayables, setFilteredPayables] = useState([]);
  const [selectedPayable, setSelectedPayable] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
const [error, setError] = useState(null);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  // Sorting states
  const [sortField, setSortField] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');

  // Filtering states
  const [filterSupplier, setFilterSupplier] = useState('');
  const [filterPaymentStatus, setFilterPaymentStatus] = useState('');
  const [filterDateRequired, setFilterDateRequired] = useState('');

  const openModal = (payable) => {
    setSelectedPayable(payable);
    setIsModalOpen(true);
  };

  useEffect(() => {
    const fetchPayables = async () => {
      try {
        setLoading(true);
        const q = query(
          collection(db, 'payables'),
          where('paymentStatus', 'in', ['Unpaid', 'Partially Paid'])
        );
        const querySnapshot = await getDocs(q);
        const fetchedPayables = querySnapshot.docs.map((doc) => {
          const data = doc.data();
  
          // Handle dateRequired
          let dateRequired = 'N/A';
          if (data.dateRequired) {
            if (data.dateRequired.toDate) {
              // Firestore Timestamp
              dateRequired = data.dateRequired.toDate().toISOString().split('T')[0];
            } else if (data.dateRequired instanceof Date) {
              // JavaScript Date object
              dateRequired = data.dateRequired.toISOString().split('T')[0];
            } else if (typeof data.dateRequired === 'string') {
              // Date string
              dateRequired = data.dateRequired;
            } else {
              // Handle other cases or invalid formats
              dateRequired = 'Invalid Date';
            }
          }
  
          // Handle statusUpdateDate
          let statusUpdateDate = 'N/A';
          if (data.statusUpdateDate) {
            if (data.statusUpdateDate.toDate) {
              statusUpdateDate = data.statusUpdateDate.toDate().toLocaleDateString();
            } else if (data.statusUpdateDate instanceof Date) {
              statusUpdateDate = data.statusUpdateDate.toLocaleDateString();
            } else if (typeof data.statusUpdateDate === 'string') {
              statusUpdateDate = new Date(data.statusUpdateDate).toLocaleDateString();
            } else {
              statusUpdateDate = 'Invalid Date';
            }
          }
  
          return {
            id: doc.id,
            ...data,
            dateRequired,
            statusUpdateDate,
          };
        });
        setPayables(fetchedPayables);
        setFilteredPayables(fetchedPayables);
      } catch (err) {
        console.error('Error fetching payables:', err);
        setError('Failed to load payables.');
      } finally {
        setLoading(false);
      }
    };
  
    fetchPayables();
  }, []);
  
  

  // Filtering and sorting
  useEffect(() => {
    let updatedPayables = [...payables];

    // Filtering
    if (filterSupplier) {
      updatedPayables = updatedPayables.filter((payable) =>
        payable.supplier.toLowerCase().includes(filterSupplier.toLowerCase())
      );
    }

    if (filterPaymentStatus) {
      updatedPayables = updatedPayables.filter(
        (payable) => payable.paymentStatus === filterPaymentStatus
      );
    }

    if (filterDateRequired) {
      updatedPayables = updatedPayables.filter(
        (payable) => payable.dateRequired === filterDateRequired
      );
    }

    // Sorting
    if (sortField) {
      updatedPayables.sort((a, b) => {
        let aValue = a[sortField];
        let bValue = b[sortField];

        // For date fields
        if (sortField === 'dateRequired' || sortField === 'statusUpdateDate') {
          aValue = new Date(aValue);
          bValue = new Date(bValue);
        }

        if (aValue < bValue) {
          return sortDirection === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortDirection === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }

    setFilteredPayables(updatedPayables);
    setCurrentPage(1); // Reset to first page on filter or sort change
  }, [
    payables,
    filterSupplier,
    filterPaymentStatus,
    filterDateRequired,
    sortField,
    sortDirection,
  ]);

  // Pagination logic
  const indexOfLastPayable = currentPage * itemsPerPage;
  const indexOfFirstPayable = indexOfLastPayable - itemsPerPage;
  const currentPayables = filteredPayables.slice(indexOfFirstPayable, indexOfLastPayable);
  const totalPages = Math.ceil(filteredPayables.length / itemsPerPage);

  const handleSort = (field) => {
    if (sortField === field) {
      // Toggle sort direction
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  if (loading) {
    return (
      <>
       
        <div className="flex justify-center items-center h-screen">
        <div className="flex justify-center items-center h-64">
              <MoonLoader size={50} color="#000" />
            </div>
        </div>
      </>
    );
  }

  if (error) {
    return (
      <>      
        <div className="text-red-500 text-center mt-4">
          <p>{error}</p>
        </div>
      </>
    );
  }

  return (
    <> 

    <div className="container mx-auto px-4 sm:px-8">
      <div>
        <div>
          <h2 className="text-2xl font-semibold leading-tight">Payables</h2>
        </div>
        {/* Filtering Controls */}
        <div className="flex flex-wrap -mx-2 mb-2">
          <div className="w-full md:w-1/3 px-2 mb-4 md:mb-0">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="supplier">
              Supplier
            </label>
            <input
              type="text"
              id="supplier"
              value={filterSupplier}
              onChange={(e) => setFilterSupplier(e.target.value)}
              className="shadow appearance-none border rounded w-full py-1 text-sm px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Filter by supplier"
            />
          </div>
          <div className="w-full md:w-1/3 px-2 mb-4 md:mb-0">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="paymentStatus">
              Payment Status
            </label>
            <select
              id="paymentStatus"
              value={filterPaymentStatus}
              onChange={(e) => setFilterPaymentStatus(e.target.value)}
              className="shadow appearance-none border rounded w-full py-1 text-sm px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            >
              <option value="">All</option>
              <option value="Unpaid">Unpaid</option>
              <option value="Partially Paid">Partially Paid</option>
              {/* Add other statuses if needed */}
            </select>
          </div>
          <div className="w-full md:w-1/3 px-2">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="dateRequired">
              Date Required
            </label>
            <input
              type="date"
              id="dateRequired"
              value={filterDateRequired}
              onChange={(e) => setFilterDateRequired(e.target.value)}
              className="shadow appearance-none border rounded w-full py-1 text-sm px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
        </div>
        {/* Table */}
        <div className="my-1 overflow-x-auto sm:-mx-4 lg:-mx-4">
          <div className="py-1 align-middle inline-block min-w-full sm:px-4 lg:px-4">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      <button onClick={() => handleSort('procurementNumber')} className="flex items-center uppercase">
                        Procurement No
                        {sortField === 'procurementNumber' &&
                          (sortDirection === 'asc' ? ' ▲' : ' ▼')}
                      </button>
                    </th>
                    <th
                      scope="col"
                      className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      <button onClick={() => handleSort('supplier')} className="flex items-center uppercase">
                        Supplier
                        {sortField === 'supplier' && (sortDirection === 'asc' ? ' ▲' : ' ▼')}
                      </button>
                    </th>
                    <th
                      scope="col"
                      className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Purpose
                    </th>
                    <th
                      scope="col"
                      className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      <button onClick={() => handleSort('grandTotal')} className="flex items-center uppercase">
                        Total Cost
                        {sortField === 'grandTotal' && (sortDirection === 'asc' ? ' ▲' : ' ▼')}
                      </button>
                    </th>
                    <th
                      scope="col"
                      className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Payment Status
                    </th>
                    <th
                      scope="col"
                      className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      <button onClick={() => handleSort('dateRequired')} className="flex items-center uppercase">
                        Required
                        {sortField === 'dateRequired' && (sortDirection === 'asc' ? ' ▲' : ' ▼')}
                      </button>
                    </th>
                    <th
                      scope="col"
                      className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      <button
                        onClick={() => handleSort('statusUpdateDate')}
                        className="flex items-center uppercase"
                      >
                        Updated
                        {sortField === 'statusUpdateDate' && (sortDirection === 'asc' ? ' ▲' : ' ▼')}
                      </button>
                    </th>
                    
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {currentPayables.map((payable) => (
                    <tr key={payable.id}>
                      <td className="px-2 py-1 whitespace-nowrap text-sm text-gray-500">
                        {payable.procurementNumber}
                      </td>
                      <td className="px-2 py-1 whitespace-nowrap text-sm text-gray-500">
                        {payable.supplier}
                      </td>
                      <td className="px-2 py-1 whitespace-nowrap text-sm text-gray-500">
                        {payable.intendedUse}
                      </td>
                      <td className="px-2 py-1 whitespace-nowrap text-sm text-gray-500">
                        {formatCurrency(payable.grandTotal)}
                      </td>
                      <td className="px-2 py-1 whitespace-nowrap text-sm text-gray-500">
                        {payable.paymentStatus}
                      </td>
                      <td className="px-2 py-1 whitespace-nowrap text-sm text-gray-500">
                        {payable.dateRequired}
                      </td>
                      <td className="px-2 py-1 whitespace-nowrap text-sm text-gray-500">
                        {payable.statusUpdateDate}
                      </td>
                      <td className="px-2 py-1 whitespace-nowrap text-right text-sm font-medium">
                        <button
                          onClick={() => openModal(payable)}
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* Pagination Controls */}
              <div className="flex justify-between mt-4 px-6 py-3 bg-gray-50">
                <button
                  onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                  disabled={currentPage === 1}
                  className={`px-4 py-2 bg-gray-300 rounded ${
                    currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                >
                  Previous
                </button>
                <span className="text-sm text-gray-700">
                  Page {currentPage} of {totalPages}
                </span>
                <button
                  onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                  disabled={currentPage === totalPages}
                  className={`px-4 py-2 bg-gray-300 rounded ${
                    currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <PayableModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          payable={selectedPayable}
          
        />

    </div>
    </>
  );
};

export default Payables;
