import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, updateDoc, deleteDoc, doc } from "firebase/firestore";
import { db } from '../../firebase';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import StarRating from './Utils/StarRating';

const Contractors = () => {
  const [formState, setFormState] = useState({
    contractorName: '',
    contractorDescription: '',
    rating: 0,  
    review: ''
  });
  const [contractor, setContractors] = useState([]);
  const [currentId, setCurrentId] = useState('');

  const fetchContractors = async () => {
    const querySnapshot = await getDocs(collection(db, "contractors"));
    setContractors(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
  };

  useEffect(() => {
    fetchContractors();   
  }, []);

  const handleInputChange = (event) => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value
    });
  };

  const handleRatingChange = (newRating, event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    setFormState(prev => ({
      ...prev,
      rating: newRating,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!formState.contractorName.trim() || 
        !formState.contractorDescription.trim() || 
        formState.rating <= 0 || 
        !formState.review.trim()) {
      toast.error('Please complete all fields and ensure a valid rating');
      return;
    }

    const contractorData = {
      name: formState.contractorName,
      description: formState.contractorDescription,
      rating: formState.rating / 20,  // Convert rating to 5-star scale
      review: formState.review
    };

    try {
      if (currentId) {
        await updateDoc(doc(db, "contractors", currentId), contractorData);
        toast.success('Contractor updated successfully');
      } else {
        await addDoc(collection(db, "contractors"), contractorData);
        toast.success('Contractor added successfully');
      }

      setFormState({
        contractorName: '',
        contractorDescription: '',
        rating: 0,
        review: '',
      });
      setCurrentId('');
      fetchContractors();
    } catch (error) {
      console.error("Error updating or adding contractor: ", error);
      toast.error('Failed to update or add contractor');
    }
  };

  const handleUpdate = (contractor) => {
    setFormState({
      contractorName: contractor.name,
      contractorDescription: contractor.description,
      rating: contractor.rating * 20,  // Convert to 100-scale for react-simple-star-rating
      review: contractor.review
    });
    setCurrentId(contractor.id);
  };

  const handleDelete = async (id) => {
    await deleteDoc(doc(db, "contractors", id));
    fetchContractors();
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="font-bold text-2xl mb-6">Add/Edit Contractors</h2>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Left Column (Form) */}
        <div className="lg:col-span-1">
          <form onSubmit={handleSubmit} className="space-y-4 bg-white p-4 shadow rounded">
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2">Contractor Name</label>
              <input
                type="text"
                name="contractorName"
                value={formState.contractorName}
                onChange={handleInputChange}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>

            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2">Contractor Description</label>
              <select
                name="contractorDescription"
                value={formState.contractorDescription}
                onChange={handleInputChange}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              >
                <option value="">Select a specialty</option>
                <option value="Rewire">Rewire</option>
                <option value="Panel Beater">Panel Beater</option>
                <option value="General Mechanic">General Mechanic</option>
                <option value="Spray Painter">Spray Painter</option>
                <option value="Gear Specialist">Gear Specialist</option>
                <option value="Brake Specialist">Brake Specialist</option>
                <option value="AC Specialist">AC Specialist</option>
                <option value="Benz Specialist">Benz Specialist</option>
                <option value="Range Rover Specialist">Range Rover Specialist</option>
              </select>
            </div>

            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2">Review</label>
              <textarea
                name="review"
                value={formState.review}
                onChange={handleInputChange}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                rows="3"
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">Rating</label>
              <StarRating
                count={5}
                value={formState.rating}
                onChange={handleRatingChange}
                size="text-xl"
              />
            </div>

            <div className="flex items-center justify-between pt-4">
              <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                {currentId ? 'Update Contractor' : 'Add Contractor'}
              </button>
            </div>
          </form>
        </div>

        {/* Right Column (Table) */}
        <div className="lg:col-span-2">
          <div className="overflow-x-auto bg-white p-4 shadow rounded">
            <table className="min-w-full border-collapse table-auto">
              <thead>
                <tr className="bg-gray-100">
                  <th className="px-4 py-2 border">Name</th>
                  <th className="px-4 py-2 border">Description</th>
                  <th className="px-4 py-2 border">Rating</th>
                  <th className="px-4 py-2 border">Review</th>
                  <th className="px-4 py-2 border">Actions</th>
                </tr>
              </thead>
              <tbody>
                {contractor.map((contractor) => (
                  <tr key={contractor.id} className="text-center bg-white text-sm">
                    <td className="px-4 py-2 border">{contractor.name}</td>
                    <td className="px-4 py-2 border">{contractor.description}</td>
                    <td className="px-2 py-2 border">
                      <div className="items-center">
                        <StarRating
                          count={5}
                          value={contractor.rating}
                          size="text-xl"
                        />
                      </div>
                    </td>
                    <td className="px-4 py-2 border">{contractor.review}</td>
                    <td className="px-2 py-2 border">
                      <button
                        onClick={() => handleUpdate(contractor)}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-semibold text-xs py-1 px-1 rounded mr-2"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => handleDelete(contractor.id)}
                        className="bg-red-500 hover:bg-red-700 text-white text-xs font-semibold py-1 px-1 rounded"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default Contractors;
