import Modal from 'react-modal';
import { useState } from 'react';
import { updateDoc, doc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { db } from '../../../firebase';

const EditNotesModal = ({ isOpen, onClose, appointmentId, currentNotes }) => {
    const [newNotes, setNewNotes] = useState(currentNotes);

    const handleEditNotes = async () => {
        try {
            const appointmentRef = doc(db, 'appointments', appointmentId);
            await updateDoc(appointmentRef, {
                notes: newNotes,
            });
            toast.success('Appointment notes updated successfully');
            onClose();
        } catch (error) {
            console.error('Error updating appointment notes:', error);
            toast.error('Error updating appointment notes');
        }
    };

    return (
        <Modal 
          isOpen={isOpen} 
          onRequestClose={onClose}
          className="max-w-md mx-auto mt-10 overflow-hidden rounded-lg shadow-lg bg-white p-6"
        >
            <h2 className="text-lg font-semibold mb-4">Edit Appointment Notes</h2>
            <div className="mb-4">
                <textarea 
                    value={newNotes} 
                    onChange={(e) => setNewNotes(e.target.value)}
                    className="w-full px-3 py-2 border rounded shadow-sm"
                    rows="4"
                ></textarea>
            </div>
            <div className="flex justify-between">
                <button 
                    onClick={handleEditNotes} 
                    className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded"
                >
                    Update Notes
                </button>
                <button 
                    onClick={onClose} 
                    className="px-4 py-2 bg-gray-500 hover:bg-gray-600 text-white font-semibold rounded"
                >
                    Close
                </button>
            </div>
        </Modal>
    );
};

export default EditNotesModal;
