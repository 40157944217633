import { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { db } from '../../firebase';
import { collection, onSnapshot, addDoc, increment, updateDoc, getDocs, getDoc, doc, runTransaction, Timestamp } from 'firebase/firestore';
import VehicleForm from './ManageVehicleImports/VehicleForm';
import VehicleTable from './ManageVehicleImports/VehicleTable';
import { generateVehicleGIN } from '../helpers/GIN';
import ScheduleAppointment from '../Tasks/Modals/ScheduleAppointment';
import BreadCrumb from '../BreadCrumb';
;



const pages = [
  { name: 'Service Advisor', to: '/vehicles', component: Link, current: false },
  { name: 'Manage Vehicles', to: '/vehicles/manage', component: Link, current: true },
];

const ManageVehicle = () => {
  const navigate = useNavigate();
  const [vehicles, setVehicles] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [isAppointmentModalOpen, setIsAppointmentModalOpen] = useState(false);
  const [selectedVehicleForAppointment, setSelectedVehicleForAppointment] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [formState, setFormState] = useState({
    vehicleMake: '',
    vehicleModel: '',
    year: '',
    VIN: '',
    registrationNumber: '',
  });

  useEffect(() => {
    const unsubscribeFromVehicles = onSnapshot(collection(db, 'vehicles'), (snapshot) => {
      const vehicleList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setVehicles(vehicleList);
    });

    const unsubscribeFromCustomers = onSnapshot(collection(db, 'customers'), (snapshot) => {
      const customerList = snapshot.docs.map((doc) => ({
        value: doc.id,
        label: `${doc.data().firstName} ${doc.data().lastName}`,
      }));
      setCustomers(customerList);
    });

    return () => {
      unsubscribeFromVehicles();
      unsubscribeFromCustomers();
    };
  }, []);



  const handleInputChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const addVehicle = async (e) => {
    e.preventDefault();

    if (!selectedCustomer) {
      alert('Please select a customer first.');
      return;
    }

    const customerRef = doc(db, 'customers', selectedCustomer.value);
    const customerDoc = await getDoc(customerRef);

    if (!customerDoc.exists()) {
      alert('Customer not found.');
      return;
    }

    if (!formState.vehicleMake || !formState.vehicleModel || !formState.year || !formState.VIN || !formState.registrationNumber) {
      alert('Please fill all the fields.');
      return;
    }

     // Fetch existing vehicles for this customer
     const vehiclesSnapshot = await getDocs(collection(db, 'vehicles'));
     const customerVehicles = vehiclesSnapshot.docs.filter(vehicleDoc => vehicleDoc.data().customerId === selectedCustomer.value);
   
      // Generate the vehicle-specific GIN
    const newVehicleGIN = generateVehicleGIN(selectedCustomer.value, customerVehicles.length + 1);

    const vehicleData = {
      ...formState,
      customerId: selectedCustomer.value,
      GIN: newVehicleGIN,
      registeredAt: Timestamp.fromDate(new Date()),
      serviceHistory: [],
      ...(customerDoc.data().agentId && { agentId: customerDoc.data().agentId }),
    };

    try {
      await addDoc(collection(db, 'vehicles'), vehicleData);

      await updateDoc(customerRef, {
        vehicleCount: increment(1),
      });

      setFormState({
        vehicleMake: '',
        vehicleModel: '',
        year: '',
        VIN: '',
        registrationNumber: '',
      });
      setSelectedCustomer(null);
    } catch (error) {
      console.error('Error adding vehicle:', error);
    }
  };

  const deleteVehicle = async (vehicleId, customerId) => {
    const vehicleRef = doc(db, 'vehicles', vehicleId);
    const customerRef = doc(db, 'customers', customerId);

    await runTransaction(db, async (transaction) => {
      transaction.delete(vehicleRef);

      transaction.update(customerRef, {
        vehicleCount: increment(-1),
      });
    });
  };

  const viewInspectionReport = async (vehicle) => {
    try {
      const inspectionDocRef = doc(db, 'inspections', vehicle.registrationNumber);
      const inspectionDocSnap = await getDoc(inspectionDocRef);

      let inspectionData = {};
      if (inspectionDocSnap.exists()) {
        inspectionData = inspectionDocSnap.data();
      } else {
        console.log('No inspection data found for this vehicle.');
      }

      navigate('/vehicles/inspectionReport', { state: { vehicle, formData: inspectionData } });
    } catch (error) {
      console.error('Error fetching inspection data:', error);
    }
  };

  const handleYearSelection = (event) => {
    setFormState({
      ...formState,
      year: event.target.value,
    });
  };

  const handleScheduleAppointment = (vehicle) => {
    setSelectedVehicleForAppointment(vehicle);
    setIsAppointmentModalOpen(true);
  };


 

  return (
    <>
      <BreadCrumb pages={pages} />
      <div className="p-4 max-w-7xl mx-auto flex flex-col md:flex-row gap-4">
        <VehicleForm
          customers={customers}
          selectedCustomer={selectedCustomer}
          setSelectedCustomer={setSelectedCustomer}
          formState={formState}
          handleInputChange={handleInputChange}
          handleYearSelection={handleYearSelection}
          addVehicle={addVehicle}
        />
        <VehicleTable
          vehicles={vehicles}
          selectedCustomer={selectedCustomer}
          navigate={navigate}
          handleScheduleAppointment={handleScheduleAppointment}
          viewInspectionReport={viewInspectionReport}
          deleteVehicle={deleteVehicle}
        />
      </div>

     

      {isAppointmentModalOpen && (
        <ScheduleAppointment
          vehicle={selectedVehicleForAppointment}
          customer={customers.find(c => c.value === selectedVehicleForAppointment.customerId)}
          onClose={() => setIsAppointmentModalOpen(false)}
        />
      )}
    </>
  );
};

export default ManageVehicle;