import React, { useState } from 'react';
import { collection, addDoc, query, where, getDocs } from "firebase/firestore";
import { db } from '../../../firebase'; 
import Modal from 'react-modal';
import { toast, ToastContainer } from 'react-toastify';

Modal.setAppElement('#root'); 

const ScheduleAppointment = ({ vehicle, customer, onClose }) => {
    const [appointmentDetails, setAppointmentDetails] = useState({
      vehicleId: vehicle.id, 
      customerId: customer.value, 
      vehicleMake: vehicle.vehicleMake,
      vehicleModel: vehicle.vehicleModel,
      vehicleYear: vehicle.year,
      customerName: `${customer.firstName} ${customer.lastName}`,
      date: '',
      time: '',
      notes: ''
    });



  const handleInputChange = (e) => {
    setAppointmentDetails({ ...appointmentDetails, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Validate form data
    if (!appointmentDetails.date || !appointmentDetails.time) {
      toast.error("Please fill in all fields.");
      return;
    }
  
    try {
      // Check for existing scheduled appointments for the same customer
      const appointmentsRef = collection(db, "appointments");
      const q = query(appointmentsRef, where("customerId", "==", customer.value), where("status", "==", "Scheduled"));
      const querySnapshot = await getDocs(q);
  
      if (!querySnapshot.empty) {
        toast.error("This customer already has a scheduled appointment.");
        return;
      }
  
      // Add the appointment to Firestore
      await addDoc(appointmentsRef, {
        vehicleId: vehicle.id,
        customerId: customer.value,
        vehicleMake: vehicle.vehicleMake,
        vehicleModel: vehicle.vehicleModel,
        vehicleYear: vehicle.year,
        vehicleRegistrationNumber: vehicle.registrationNumber,
        customerName: customer.label,
        date: appointmentDetails.date,
        time: appointmentDetails.time,
        notes: appointmentDetails.notes,
        status: 'Scheduled'
      });
  
      // Show success toast message
      toast.success("Appointment scheduled successfully.");
      onClose(); // Close the modal after successful submission
    } catch (error) {
      console.error("Error scheduling appointment: ", error);
      toast.error("Failed to schedule appointment.");
    }
  };
  


  return (

    <Modal isOpen={true} onRequestClose={onClose} className="max-w-lg mx-auto mt-12 p-6 bg-white rounded-lg shadow-xl">
      <h2 className="text-lg font-semibold mb-4">Schedule Appointment for {vehicle.vehicleMake} {vehicle.vehicleModel} ({vehicle.year})</h2>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-1">Date</label>
        <input type="date" name="date" value={appointmentDetails.date} onChange={handleInputChange} className="w-full px-3 py-2 border rounded shadow-sm" />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-1">Time</label>
        <input type="time" name="time" value={appointmentDetails.time} onChange={handleInputChange} className="w-full px-3 py-2 border rounded shadow-sm" />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-1">Notes</label>
        <textarea name="notes" value={appointmentDetails.notes} onChange={handleInputChange} className="w-full px-3 py-2 border rounded shadow-sm" rows="3"></textarea>
      </div>

      <div className="flex justify-between items-center">
        <button onClick={onClose} className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition">Close</button>
        <button onClick={handleSubmit} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition">Schedule</button>
      </div>
      <ToastContainer />
    </Modal>



  );
};

export default ScheduleAppointment;
