import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../../firebase';
import CustomerDetails from './CustomerDetails';
import { Link } from 'react-router-dom';
import BreadCrumb from '../../BreadCrumb';

const pages = [
  { name: 'Customer Care', to: '/customers', component: Link, current: false },
  { name: 'Customer Reports', to: '/customers/reports', component: Link, current: true },
];

const CustomerSelector = () => {
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  useEffect(() => {
    // Fetch all customers from Firebase
    const fetchCustomers = async () => {
      const customerSnapshot = await getDocs(collection(db, 'customers'));
      const customerList = customerSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCustomers(customerList);
    };

    fetchCustomers();
  }, []);

  const handleCustomerSelect = (e) => {
    const selectedCustomerId = e.target.value;
    const customer = customers.find(
      (customer) => customer.id === selectedCustomerId
    );
    setSelectedCustomer(customer);
  };

  return (
    <>
      <BreadCrumb pages={pages} />
      <div className="max-w-6xl mx-auto p-6 bg-white shadow-md rounded-lg">
        <h2 className="text-3xl font-semibold mb-6 text-gray-800">
          Customer Reports
        </h2>

        <div className="mb-8">
          <label
            htmlFor="customer-select"
            className="block text-lg font-medium text-gray-700 mb-2"
          >
            Choose a Customer
          </label>
          <select
            id="customer-select"
            onChange={handleCustomerSelect}
            className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          >
            <option value="">-- Select Customer --</option>
            {customers.map((customer) => (
              <option key={customer.id} value={customer.id}>
                {customer.firstName} {customer.lastName} ({customer.customerNumber})
              </option>
            ))}
          </select>
        </div>

        {selectedCustomer && (
          <div className="mt-6">
            <CustomerDetails customer={selectedCustomer} />
          </div>
        )}
      </div>
    </>
  );
};

export default CustomerSelector;
