import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { collection, addDoc, getDocs, query, where, doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { PlusCircleIcon, XCircleIcon } from '@heroicons/react/20/solid';
import { toast, ToastContainer } from 'react-toastify';
import { formatCurrency } from '../Help/helper';
import { generateProcurementNumber } from '../Help/helper';
import Select from 'react-select';
import BreadCrumb from '../BreadCrumb';


const pages = [
 
  { name: 'Procurement', to: '/procurement', component: Link, current: false },
  { name: 'Requisition', to: '/procurement/buy-item', component: Link, current: true },
  
];

const Requisition = () => {
  const [customers, setCustomers] = useState([]);
  const navigate = useNavigate();
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [invoices, setInvoices] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [invoiceDetails, setInvoiceDetails] = useState(null);
  const [suppliers, setSuppliers] = useState([]);
  const [formState, setFormState] = useState({
    customerName: '',
     category: '',    
    supplier: '',
    grandTotal: 0,
    dateOfPurchase: '',
    paymentStatus: 'Unpaid', // Set default value as "Unpaid"
    additionalNotes: '',
    status: 'Requested', // Set default value as "Requested"
    statusUpdateDate: new Date().toISOString().split('T')[0],
  });
  
  const [cartItems, setCartItems] = useState([]);
  const [includeTax, setIncludeTax] = useState(false);

  useEffect(() => {
    const fetchSuppliers = async () => {
      const suppliersCollection = collection(db, 'suppliers');
      const suppliersSnapshot = await getDocs(suppliersCollection);
      const suppliersList = suppliersSnapshot.docs.map(doc => doc.data());
      setSuppliers(suppliersList);
    };

    fetchSuppliers();
  }, []);


  useEffect(() => {
    const fetchCustomers = async () => {
        const querySnapshot = await getDocs(collection(db, 'customers'));
        const customersList = querySnapshot.docs.map(doc => {
            const data = doc.data();
            return { value: doc.id, label: `${data.firstName} ${data.lastName}` };
        });
        setCustomers(customersList);
    };

    fetchCustomers();
}, []);


useEffect(() => {
  const procurementNumber = generateProcurementNumber();
  setFormState(prevState => ({
    ...prevState,
    procurementNumber: procurementNumber,
  }));
}, []); 



const handleCustomerChange = selectedOption => {
  setSelectedCustomer(selectedOption);
  setInvoices([]); 
  setSelectedInvoice(null); 
  setInvoiceDetails(null);
};

const fetchInvoicesForCustomer = async () => {
  if (!selectedCustomer) {
    toast.error("Please select a customer first.");
    return;
  }

  const invoicesQuery = query(collection(db, 'newInvoices'), where("customerName", "==", selectedCustomer.label));
  const querySnapshot = await getDocs(invoicesQuery);

  if (!querySnapshot.empty) {
  const invoicesList = querySnapshot.docs.map(doc => {
    const invoiceData = doc.data();
    return {
      value: doc.id,
      label: invoiceData.invoiceNumber,
      jobCardNo: invoiceData.jobCardNo, // Include job card number here
      invoiceNumber: invoiceData.invoiceNumber // Include invoice number for clarity
    };
  });
  setInvoices(invoicesList);
  toast.success(`${invoicesList.length} invoices fetched successfully.`);
} else {
  toast.info('No invoices found for the selected customer.');
}
};

const handleInvoiceChange = async selectedOption => {
  setSelectedInvoice(selectedOption);
// Fetch the invoice details when an invoice is selected
const invoiceDocRef = doc(db, 'newInvoices', selectedOption.value);
const invoiceDoc = await getDoc(invoiceDocRef);

if (invoiceDoc.exists()) {
  setInvoiceDetails(invoiceDoc.data());
} else {
  toast.error('Failed to fetch invoice details.');
}
};

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let updatedValue = value;

    if (name === 'quantity' || name === 'unitPrice') {
      updatedValue = parseFloat(value) || 0;
    }

    setFormState(prevState => ({
      ...prevState,
      [name]: updatedValue,
     
    }));
  };


  const supplierOptions = suppliers.map(supplier => ({
    value: supplier.id, 
    label: supplier.companyName, 
  }));
  
  
  const handleSubmit = async (event) => {
    event.preventDefault();  
       
    if (cartItems.length === 0) {
      toast.error("Please add at least one item to the cart.");
      return;
    }
        
      // Fetch the supplier's name from Firestore
      const supplierRef = doc(db, "suppliers", formState.supplier);
      const supplierSnap = await getDoc(supplierRef);
  
      if (!supplierSnap.exists()) {
        throw new Error("Supplier does not exist.");
      }
    

      const supplierName = supplierSnap.data().companyName;
      const subtotal = calculateCartTotal();
      const taxes = calculateTax();
      const grandTotal = calculateReceivable();

    try {
    const procurementData = {
      ...formState,
      supplier: supplierName,
      customerName: selectedCustomer ? selectedCustomer.label : "", 
      jobCardNo: selectedInvoice ? selectedInvoice.jobCardNo : '', 
      invoiceNumber: selectedInvoice ? selectedInvoice.invoiceNumber : '', 
      paymentStatus: formState.paymentStatus || "Unpaid", 
      status: formState.status || "Requested", 
      dateRequired: formState.dateRequired || new Date().toISOString().split('T')[0],       
      cartItems: cartItems.map(item => ({ 
      description: item.description,
      price: parseFloat(item.price),
      quantity: parseInt(item.quantity, 10)
    })),
    subtotal,
    taxes,
    grandTotal,
    };
      
      await addDoc(collection(db, "procurements"), procurementData);
      toast.success('Request Submitted successfully');     
      navigate('/procurement/requests');
      resetFormAndSelectionStates();
    } catch (error) {
      toast.error('Error adding procurement: ' + error.message);
      console.error(error);
    }
  };
  
  const resetFormAndSelectionStates = () => {
    setFormState({
      
      category: '',
      supplier: '',
      dateOfPurchase: '',
      paymentStatus: 'Unpaid',
      additionalNotes: '',
      status: 'Requested',
      statusUpdateDate: new Date().toISOString().split('T')[0],
    });
    setSelectedCustomer(null);
    setSelectedInvoice(null);
    setCartItems([]);
    setIncludeTax(false);
    
  };

 
const handleCartItemChange = (index, field, value) => {
    const updatedCartItems = cartItems.map((item, i) => {
      if (i === index) {
        return { ...item, [field]: value };
      }
      return item;
    });
    setCartItems(updatedCartItems);
  };

  const handleAddCartItem = () => {
    setCartItems([...cartItems, { description: '', price: '', quantity: 1 }]);
  };

  const handleRemoveCartItem = (index) => {
    const updatedCartItems = cartItems.filter((_, i) => i !== index);
    setCartItems(updatedCartItems);
  };

  const calculateCartTotal = () => {
    return cartItems.reduce((total, item) => total + (item.price * item.quantity), 0);
  };

  const calculateTax = () => {
    return includeTax ? calculateCartTotal() * 0.075 : 0;
  };

  const calculateReceivable = () => {
    return calculateCartTotal() + calculateTax();
  };



  return (   
    <>
    <BreadCrumb pages={pages} />

    <div className="container mx-auto p-4">
        <h1 className="text-lg font-semibold text-gray-800 uppercase">Procurement/ Requisition Request</h1>  
        <form onSubmit={handleSubmit}>
        <div className="grid md:grid-cols-2 gap-4">    
                     <div className="bg-white p-4 shadow rounded">                
                                               
                                        <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                          <div className="sm:col-span-3">
                                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="supplier">
                                                Supplier
                                            </label>
                                            <Select
                                                   className=" text-sm"
                                                   options={supplierOptions}
                                                  onChange={selectedOption => {
                                                    setFormState(prevState => ({
                                                      ...prevState,
                                                      supplier: selectedOption.value,
                                                    }));
                                                  }}
                                                  placeholder="Select Supplier"
                                                  isSearchable={true}
                                                  required
                                                />                                        

                                            </div>
                                        
                                        {/* Date of Purchase */}
                                        <div className="sm:col-span-3">
                                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="dateRequired">
                                        Date Required
                                        </label>
                                        <input
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        id="dateRequired"
                                        name="dateRequired"
                                        type="date"
                                        value={formState.dateRequired}
                                        onChange={handleInputChange}
                                        required
                                        />
                                    </div>
                                    </div>

                                    <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                    {/* Category */}
                                    <div className="sm:col-span-3">
                                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="category">
                                                Select Category
                                            </label>
                                        <select
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        name="category"
                                        value={formState.category}
                                        onChange={handleInputChange}
                                        required
                                        >
                                        <option value="">Select Category</option>
                                        <option value="goods">Goods</option>
                                        <option value="services">Services</option>
                                        </select>
                                       </div>
                                    
                                       <div className="sm:col-span-3">
                                       <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="intendedUse">
                                                Planned Usage
                                            </label>
                                    <select
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        name="intendedUse"
                                        value={formState.intendedUse}
                                        onChange={handleInputChange}
                                        required
                                        >
                                    <option value="">Select Intended Use</option>
                                    <option value="Office Consumable">Office Consumable</option>
                                    <option value="Customer Use">Customer Use</option>
                                    </select>
                                    </div>


                                    {formState.intendedUse === 'Customer Use' && (
                                          <>
                                            <div className="sm:col-span-3">
                                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="customers">
                                                Select Customer
                                            </label>
                                              <Select
                                                  options={customers}
                                                  onChange={handleCustomerChange}
                                                  placeholder="Select Customer"
                                                  isSearchable={true}
                                                  className='text-sm'
                                              />

                                              <button
                                                type="button"
                                                onClick={fetchInvoicesForCustomer}
                                                className="flex items-center space-x-2 bg-green-500 text-sm text-white p-2 rounded mt-2 hover:bg-green-900"
                                                >
                                                <PlusCircleIcon className="h-5 w-5" />
                                                <span className='text-sm'>Fetch Invoices</span>
                                                </button>

                                             </div>
                                              
                                              {selectedCustomer && (
                                                <div className="sm:col-span-3">
                                                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="invoices">
                                                Selected Invoice
                                            </label>
                                                  <Select
                                                      options={invoices}
                                                      onChange={handleInvoiceChange}
                                                      placeholder="Select Invoice"
                                                      isSearchable={true}
                                                      className='text-sm'
                                                  />                                                  
                                                  </div>
                                              )}                                              
                                          </>                                        
                                      )}
                                    </div>
                                      
                                    {invoiceDetails && (
                                            <div className="bg-white p-4 shadow rounded">
                                              <h3 className="font-semibold text-gray-700 mb-4 uppercase">Invoice Details</h3>
                                              <p><strong>Invoice Number:</strong> {invoiceDetails.invoiceNumber}</p>
                                              <p><strong>Job Card No:</strong> {invoiceDetails.jobCardNo}</p>
                                              <p><strong>Customer Name:</strong> {invoiceDetails.customerName}</p>
                                              <h4 className="mt-4 font-semibold">Items:</h4>
                                              <ul className="list-disc ml-4">
                                                {invoiceDetails.cartItems.map((item, index) => (
                                                  <li key={index}>{item.description} - {item.quantity} @ {formatCurrency(item.price)}</li>
                                                ))}
                                              </ul>
                                              <p className="mt-2"><strong>Total Sale:</strong> {formatCurrency(invoiceDetails.totalSale)}</p>
                                            </div>
                                          )}

                                   <div className="mb-4">
                                    <label className="block text-sm font-medium leading-6 mt-2 text-gray-900" htmlFor="additionalNotes">
                                    Additional Notes
                                    </label>
                                    <textarea
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    id="additionalNotes"
                                    rows={5}
                                    name="additionalNotes"
                                    value={formState.additionalNotes}
                                    onChange={handleInputChange}
                                    />
                                     <p className="text-xs italic text-gray-500">Write details about the nature of the purchase.</p>
                                </div>                                                                       
                    
                             </div>

                                        {/* Right Side */}
                                    <div className="bg-white p-4 shadow rounded">
                                        <div className="flex-1 overflow-auto max-h-96">                        
                                                 <h3 className="font-semibold text-gray-700 mb-4 uppercase">Detailed Breakdown of Vendor Costs</h3>

                                                {cartItems.map((item, index) => (
                                                        <div key={index} className="flex items-center space-x-2 pb-2">
                                                            {/* Description */}
                                            <div className="flex-grow w-3/5">
                                              <input
                                                type="text"
                                                value={item.description}
                                                onChange={(e) => handleCartItemChange(index, 'description', e.target.value)}
                                                placeholder="Description"
                                                className="block w-full text-xs rounded-md p-2 border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                              />
                                            </div>

                                              {/* Price */}
                                              <div className="w-1/3">
                                                <div className="relative">
                                                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                                    <span className="text-gray-500 sm:text-xs">₦</span>
                                                  </div>
                                                  <input
                                                    type="text"
                                                    name="price"
                                                    value={item.price}
                                                    onChange={(e) => handleCartItemChange(index, 'price', e.target.value)}
                                                    id="price"
                                                    className="block w-full pl-7 pr-12 py-1.5 text-xs rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                                    placeholder="0.00"
                                                    aria-describedby="price-currency"
                                                  />
                                                  <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                                                    <span className="text-gray-500 sm:text-xs" id="price-currency">NGN</span>
                                                  </div>
                                                </div>
                                              </div>

                                          {/* Quantity */}
                                          <div className="w-1/5">
                                            <select
                                              value={item.quantity}
                                              onChange={(e) => handleCartItemChange(index, 'quantity', e.target.value)}
                                              className="block w-full p-2 text-xs rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                            >
                                              <option value="" disabled>Qty</option>
                                              {[...Array(100).keys()].map((num) => (
                                                <option key={num + 1} value={num + 1}>{num + 1}</option>
                                              ))}
                                            </select>
                                          </div>

                                          {/* Delete Button */}
                                          <div className="flex items-center justify-center w-1/5">
                                            <button
                                              type="button"
                                              onClick={() => handleRemoveCartItem(index)}
                                              className="text-red-500 hover:text-red-700"
                                            >
                                              <XCircleIcon className="h-5 w-5" />
                                            </button>
                                          </div>
                                        </div>
                                                 ))}

                                              </div>
                           
                                                <button
                                                type="button"
                                                onClick={handleAddCartItem}
                                                className="flex items-center space-x-2 bg-blue-500 text-sm text-white p-2 rounded mt-2 hover:bg-blue-600"
                                                >
                                                <PlusCircleIcon className="h-5 w-5" />
                                                <span className='text-sm'>Add Item</span>
                                                </button>


                                            <div className="mt-4">
                                            <label className="flex items-center space-x-3">
                                                <input
                                                type="checkbox"
                                                checked={includeTax}
                                                onChange={(e) => setIncludeTax(e.target.checked)}
                                                className="form-checkbox rounded text-blue-600 shadow-sm"
                                                />
                                                <span className="text-sm text-gray-700">Include 7.5% Tax</span>
                                                </label>
                                                <div className="mt-4 text-sm">
                                                    <p>SUBTOTAL: {formatCurrency(calculateCartTotal())}</p>
                                                    <p>VAT (7.5%): {formatCurrency(calculateTax())}</p>
                                                    <p className="font-bold text-xl">Grand Total: {formatCurrency(calculateReceivable())}</p>
                                                </div>
                                                </div>
                                                                                                            
                                                         
                                           <div className="mb-4">
                                              <button
                                                  type="submit" onClick={handleSubmit}
                                                  className="ml-2 px-4 py-2 mt-2 bg-red-700 hover:bg-green-600 text-white rounded font-bold">
                                                  Submit Request
                                              </button>
                                          </div>
                                          </div>                                     
                                             
                           
                  </div>

                      </form>
                  
                  <ToastContainer />
               </div>                    
                      
          </>
           
  );
};

export default Requisition;                                             
                                                    

                                   
                                        
                                
                          
                                
                             