import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { db } from '../../firebase';
import {formatCurrency} from '../Help/helper';
import PaymentModal from './PaymentModal';

const Receivable = ({ invoice }) => {
  const [invoices, setInvoices] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  

  useEffect(() => {
    const fetchData = async () => {
        const invoicesQuery = query(
        collection(db, 'invoices'),
        where('approvalStatus', '==', 'Yes'),
        where('receivable', '>', 0),
        orderBy('receivable'),
        orderBy('date')
      );
      const invoiceSnapshot = await getDocs(invoicesQuery);
      const invoiceData = invoiceSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setInvoices(invoiceData);
    };

    fetchData();
  }, []);

  return (
    <>
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Invoice Number
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Customer
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Approval Date
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Receivable
          </th>
          
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {invoices.map((invoice) => (
          <tr key={invoice.id}>
            <td className="px-6 py-2 whitespace-nowrap text-sm">
              {invoice.invoiceNumber}
            </td>
            <td className="px-6 py-2 whitespace-nowrap text-sm">
              {invoice.customerName}
            </td>
                        
            <td className="px-6 py-2 whitespace-nowrap text-sm">
              {invoice.approvalDate.toDate().toLocaleString()} {/* Convert Firebase timestamp to JS Date */}
            </td>
            <td className="px-6 py-2 whitespace-nowrap text-sm">
              {formatCurrency(invoice.receivable)}
            </td>
            <td>
              <button
               onClick={() => setSelectedInvoice(invoice)}
               className="btn btn-primary text-xs"
               style={{ borderRadius: '8px', fontSize: '0.75rem', padding: '0.5rem 1rem' }}
             >
               View Details
             </button>
             
              
            
            </td>
                
            {/* Render other fields as needed */}
          </tr>
        ))}
      </tbody>
    </table>

    {selectedInvoice && (
        <PaymentModal 
          invoice={selectedInvoice} 
          isOpen={!!selectedInvoice} 
          onRequestClose={() => setSelectedInvoice(null)}
        />
    )}
</>
  );
};

export default Receivable;
