import React, { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import BreadCrumb from '../BreadCrumb';
import TaskDashboard from '../Tasks/TaskDashboard'
import TaskCompletionReport from '../Tasks/TaskCompletionReport';
import TaskCalendar from '../Tasks/TaskCalendar';
import CreateTask from '../Tasks/CreateTask';
import { useAuth } from '../Auth/Auth';

const pages = [
    { name: 'Mechanic Supervisor', to: '/supervisor', component: Link, current: true },
     ];

const Supervisor = () => {
  const [activeComponent, setActiveComponent] = useState('dashboard');
  const { role, loading } = useAuth();

 

  const renderComponent = () => {
    switch (activeComponent) {
      case 'dashboard':
        return <TaskDashboard />;
      case 'completionReport':
        return <TaskCompletionReport />;
      case 'calendar':
        return <TaskCalendar />;
        case 'createTask':
            return <CreateTask />;

      default:
        return <TaskDashboard />;
    }
  };


  if (loading) {
    return <div>Loading...</div>; 
  }

  if (role !== 'Service Advisor' && role !== 'Admin' && role !== 'Supervisor') {
   
    return <Navigate to="/unauthorized" replace />; 
  }


  return (
    <>
    <BreadCrumb pages={pages} />
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold text-gray-800">Mechanic Administration</h1>
      <div className="bg-white shadow rounded-lg p-4">
        <nav className="mb-4">
          <ul className="flex space-x-4 justify-center border-b-2 border-gray-200 pb-2">
            <li>
              <button
                onClick={() => setActiveComponent('dashboard')}
                className={`px-4 py-2 rounded transition-colors duration-300 ${activeComponent === 'dashboard' ? 'text-white bg-blue-600' : 'text-gray-700 bg-white hover:bg-blue-50'}`}
              >
                Task Dashboard
              </button>
            </li>
            <li>
              <button
                onClick={() => setActiveComponent('completionReport')}
                className={`px-4 py-2 rounded transition-colors duration-300 ${activeComponent === 'completionReport' ? 'text-white bg-blue-600' : 'text-gray-700 bg-white hover:bg-blue-50'}`}
              >
                Task Completion Report
              </button>
            </li>
            <li>
              <button
                onClick={() => setActiveComponent('calendar')}
                className={`px-4 py-2 rounded transition-colors duration-300 ${activeComponent === 'calendar' ? 'text-white bg-blue-600' : 'text-gray-700 bg-white hover:bg-blue-50'}`}
              >
                Mechanic Task Calendar
              </button>
            </li>
            <li>
              <button
                onClick={() => setActiveComponent('createTask')}
                className={`px-4 py-2 rounded transition-colors duration-300 ${activeComponent === 'createTask' ? 'text-white bg-blue-600' : 'text-gray-700 bg-white hover:bg-blue-50'}`}
              >
                Create Mechanic Task
              </button>
            </li>
          </ul>
        </nav>
        <div className="bg-gray-100 p-4 rounded-lg shadow-inner">
          {renderComponent()}
        </div>
      </div>
    </div>

    </>
  );

};

export default Supervisor;
