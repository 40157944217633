import React, { useEffect, useState } from 'react';
import { collection, addDoc, getDocs, doc, updateDoc, arrayUnion, increment } from 'firebase/firestore';
import { db } from '../../firebase';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';

const revenue = [
  'Mechanical',
  'Body works',
  'Electrical',
  'AC',
  'Quick service',
  'JobCard',
  'Oven (rent)',
];

const expenses = [
  'Spare parts',
  'Contractor',
  'Supplier',
  'Engine oil',
  'Diesel',
  'JobCard',
  'Labour/attending cost',
  'Administrative Repairs',
  'Miscellaneous',
  'Logistics/transportation',
  'Entertainment',
  'Utilities',
  'Welfare',
  'Taxes',
  'Commission',
];

const paymentModes = [
  'Bank Transfer - Stanbic Opex Account',
  'Bank Transfer - Stanbic Revenue Account',
  'Bank Transfer - Stanbic - CAPEX Account',
  'Cash',
  'POS',
];

const TransactionForm = ({ setShowModal }) => {
  const [invoices, setInvoices] = useState([]);
  const [formState, setFormState] = useState({
    type: '',
    amount: '',
    transactionDate: '',
    description: '',
    category: '',
    invoiceId: '',
    modeOfPayment: '',
  });

  useEffect(() => {
    const fetchInvoices = async () => {
      const querySnapshot = await getDocs(collection(db, 'newInvoices'));
      const loadedInvoices = querySnapshot.docs.map(doc => ({
        id: doc.id,
        invoiceNumber: doc.data().invoiceNumber,
        jobCardNo: doc.data().jobCardNo,
      }));
      setInvoices(loadedInvoices);
    };

    fetchInvoices();
  }, []);

  const handleInputChange = event => {
    const { name, value } = event.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const invoiceOptions = invoices.map(invoice => ({
    value: invoice.id,
    label: `${invoice.invoiceNumber} - ${invoice.jobCardNo}`,
  }));

  const handleSubmit = async event => {
    event.preventDefault();

    try {
      const selectedInvoice = invoices.find(invoice => invoice.id === formState.invoiceId);
      const invoiceNo = selectedInvoice ? selectedInvoice.invoiceNumber : '';

      const transactionData = {
        ...formState,
        amount: parseFloat(formState.amount),
        invoiceNo,
      };

      const docRef = await addDoc(collection(db, 'transactions'), transactionData);

      if (formState.invoiceId) {
        const invoiceRef = doc(db, 'newInvoices', formState.invoiceId);
        await updateDoc(invoiceRef, {
          expenses: arrayUnion(docRef.id),
          totalExpenses: increment(transactionData.amount),
        });
      }

      setFormState({
        type: '',
        amount: '',
        transactionDate: '',
        description: '',
        category: '',
        modeOfPayment: '',
        invoiceId: '',
      });

      toast.success('Transaction posted successfully');
      setShowModal(false);
    } catch (error) {
      console.error('Failed to post transaction: ', error);
      toast.error('Failed to post transaction');
    }
  };

  return (
    <div className="fixed inset-0 z-10 overflow-y-auto">
      <ToastContainer />
      <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75"></div>

        <div className="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
            <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
              Add New Transaction
            </h3>
            <div className="mt-2">
              <form onSubmit={handleSubmit} className="space-y-4">
                <div className="mb-4">
                  <label htmlFor="type" className="block text-sm font-medium text-gray-700">
                    Select Income/Expenditure
                  </label>
                  <select
                    id="type"
                    required
                    name="type"
                    value={formState.type}
                    onChange={handleInputChange}
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  >
                    <option value="">Select Type</option>
                    <option value="Income">Income</option>
                    <option value="Expenditure">Expenditure</option>
                  </select>
                </div>

                <div className="mb-4">
                  <label htmlFor="category" className="block text-sm font-medium text-gray-700">
                    Category
                  </label>
                  <select
                    id="category"
                    name="category"
                    required
                    value={formState.category}
                    onChange={handleInputChange}
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  >
                    <option value="">Select Category</option>
                    {formState.type === 'Income' &&
                      revenue.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    {formState.type === 'Expenditure' &&
                      expenses.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                  </select>
                </div>

                {formState.category === 'JobCard' && (
                  <div className="mb-4">
                    <label htmlFor="invoiceId" className="block text-sm font-medium text-gray-700">
                      Invoice
                    </label>
                    <Select
                      id="invoiceId"
                      name="invoiceId"
                      value={invoiceOptions.find(option => option.value === formState.invoiceId)}
                      onChange={selectedOption =>
                        setFormState(prevState => ({
                          ...prevState,
                          invoiceId: selectedOption ? selectedOption.value : '',
                        }))
                      }
                      options={invoiceOptions}
                      className="mt-1 block w-full py-2 px-3 sm:text-sm"
                      placeholder="Select Invoice"
                    />
                    <p className="text-xs ml-4 font-semibold leading-6 text-gray-600">
                      Job Card No - Invoice No Displayed above
                    </p>
                  </div>
                )}

                {(formState.type === 'Income' || formState.type === 'Expenditure') && (
                  <div className="mb-4">
                    <label htmlFor="modeOfPayment" className="block text-sm font-medium text-gray-700">
                      Mode of {formState.type === 'Income' ? 'Payment' : 'Disbursement'}
                    </label>
                    <select
                      id="modeOfPayment"
                      name="modeOfPayment"
                      value={formState.modeOfPayment}
                      onChange={handleInputChange}
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    >
                      <option value="">
                        Select Mode of {formState.type === 'Income' ? 'Payment' : 'Disbursement'}
                      </option>
                      {paymentModes.map((mode, index) => (
                        <option key={index} value={mode}>
                          {mode}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                  <div className="mb-4">
                    <label htmlFor="amount" className="block text-sm font-medium text-gray-700">
                      Amount
                    </label>
                    <input
                      type="number"
                      required
                      id="amount"
                      name="amount"
                      value={formState.amount}
                      onChange={handleInputChange}
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div>

                  <div className="mb-4">
                    <label htmlFor="transactionDate" className="block text-sm font-medium text-gray-700">
                      Transaction Date
                    </label>
                    <input
                      type="date"
                      id="transactionDate"
                      required
                      name="transactionDate"
                      value={formState.transactionDate}
                      onChange={handleInputChange}
                      className="mt-1 block w-full py-2 px-3 text-sm border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div className="mb-4">
                  <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                    Description
                  </label>
                  <input
                    type="text"
                    required
                    id="description"
                    name="description"
                    value={formState.description}
                    onChange={handleInputChange}
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none text-xs focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>

                <div className="mt-5 sm:mt-6">
                  <button
                    type="submit"
                    className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:border-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    Post Transaction
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div className="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              onClick={() => setShowModal(false)}
              className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:border-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionForm;
