import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { doc, setDoc, Timestamp } from "firebase/firestore"; 
import { CogIcon } from '@heroicons/react/20/solid';
import { useLocation } from 'react-router-dom';
import AC from './AC';
import Chasis from './Chasis';
import InteriorAmenities from './InteriorAmenities';
import ElectronicsDrive from './ElectronicsDrive';
import ExteriorBody from './ExteriorBody';
import Suspension from './Suspension';
import Transmission from './Transmission';
import Exhaust from './Exhaust';
import RoadTest from './RoadTest';
import Completion from './Completion';
import './ImageStyle.css';

const InspectionContainer = () => {
  const location = useLocation();
  const vehicle = location.state.vehicle;
  const [isLoading, setIsLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState('Step 1');
  const [formData, setFormData] = useState({});
  const [successMessage, setSuccessMessage] = useState(null);

  const handleLoadACForm = () => {
    setIsLoading(false);
  };
  
  useEffect(() => {
    handleLoadACForm();
  }, []);

  const handleFormDataChange = (newData) => {
    setFormData({ ...formData, ...newData });
  };

  const handleComplete = async () => {
    const docRef = doc(db, "inspections", vehicle.registrationNumber); 
  
    const inspectionData = {
      vehicleRegistrationNumber: vehicle.registrationNumber,
      vehicleMake: vehicle.vehicleMake,
      vehicleModel: vehicle.vehicleModel,
      inspection: formData,
      inspectionDate: Timestamp.now(),
    };
  
    await setDoc(docRef, inspectionData);
    setSuccessMessage("Vehicle Inspection Data Saved Successfully!");
  };
    
  const steps = [
    {
      id: 'Step 1',
      name: 'AC & Cooling',
      component: (
        <AC
          formData={formData}
          vehicle={vehicle}
          onFormDataChange={handleFormDataChange}
          onNext={() => setCurrentStep('Step 2')}
        />
      ),
    },
    {
      id: 'Step 2',
      name: 'Brake Inspection',
      component: (
        <Chasis
          formData={formData}
          vehicle={vehicle}
          onFormDataChange={handleFormDataChange}
          onBack={() => setCurrentStep('Step 1')}
          onNext={() => setCurrentStep('Step 3')}
        />
      ),
    },
    {
      id: 'Step 3',
      name: 'Interior & Amenities',
      component: (
        <InteriorAmenities
          formData={formData}
          vehicle={vehicle}
          onFormDataChange={handleFormDataChange}
          onBack={() => setCurrentStep('Step 2')}
          onNext={() => setCurrentStep('Step 4')}
        />
      ),
    },
    {
      id: 'Step 4',
      name: 'Electronics Drive',
      component: (
        <ElectronicsDrive
          formData={formData}
          vehicle={vehicle}
          onFormDataChange={handleFormDataChange}
          onBack={() => setCurrentStep('Step 3')}
          onNext={() => setCurrentStep('Step 5')}
        />
      ),
    },
    {
      id: 'Step 5',
      name: 'Exterior & Body',
      component: (
        <ExteriorBody
          formData={formData}
          vehicle={vehicle}
          onFormDataChange={handleFormDataChange}
          onBack={() => setCurrentStep('Step 4')}
          onNext={() => setCurrentStep('Step 6')}
        />
      ),
    },
    {
      id: 'Step 6',
      name: 'Suspension',
      component: (
        <Suspension
          formData={formData}
          vehicle={vehicle}
          onFormDataChange={handleFormDataChange}
          onBack={() => setCurrentStep('Step 5')}
          onNext={() => setCurrentStep('Step 7')}
        />
      ),
    },
    {
      id: 'Step 7',
      name: 'Transmission',
      component: (
        <Transmission
          formData={formData}
          vehicle={vehicle}
          onFormDataChange={handleFormDataChange}
          onBack={() => setCurrentStep('Step 6')}
          onNext={() => setCurrentStep('Step 8')}
        />
      ),
    },
    {
      id: 'Step 8',
      name: 'Exhaust',
      component: (
        <Exhaust
          formData={formData}
          vehicle={vehicle}
          onFormDataChange={handleFormDataChange}
          onBack={() => setCurrentStep('Step 7')}
          onNext={() => setCurrentStep('Step 9')}
        />
      ),
    },
    {
      id: 'Step 9',
      name: 'Road Test',
      component: (
        <RoadTest
          formData={formData}
          vehicle={vehicle}
          onFormDataChange={handleFormDataChange}
          onBack={() => setCurrentStep('Step 8')}
          onNext={() => setCurrentStep('Step 10')}
        />
      ),
    },
    {
      id: 'Step 10',
      name: 'Completion',
      component: (
        <Completion
          formData={formData}
          onFormDataChange={handleFormDataChange}
          onBack={() => setCurrentStep('Step 9')}
          onComplete={handleComplete}
          vehicle={vehicle}
        />
      ),
    },
  ];

  const getCurrentStep = () => {
    return steps.find(step => step.id === currentStep);
  };

  return (
    <>
      <nav aria-label="Progress">
        <ol className="space-y-4 md:flex md:space-x-1 md:space-y-0 mt-4 mb-2 text-center gap-10 px-4 sm:px-4">
          {steps.map((step) => (
            <li key={step.name} className="md:flex-1">
              {step.id < currentStep ? (
                <div className="group flex flex-col border-l-2 border-indigo-600 py-2 pl-1 text-center hover:border-indigo-800 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-2">
                  <span className="text-sm font-medium text-indigo-600 group-hover:text-indigo-800">{step.id}</span>
                  <span className="text-sm font-medium ">{step.name}</span>
                </div>
              ) : step.id === currentStep ? (
                <div className="flex flex-col border-l-4 border-indigo-600 py-2 pl-2 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-2">
                  <span className="text-sm font-medium text-indigo-600">{step.id}</span>
                  <span className="text-sm font-medium">{step.name}</span>
                </div>
              ) : (
                <div className="group flex flex-col border-l-4 border-gray-200 py-2 pl-2 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-2">
                  <span className="text-sm font-medium text-gray-500 group-hover:text-gray-700">{step.id}</span>
                  <span className="text-sm font-medium">{step.name}</span>
                </div>
              )}
            </li>
          ))}
        </ol>
      </nav>

      {successMessage && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
          <strong className="font-bold">Success!</strong>
          <span className="block sm:inline"> {successMessage}</span>
        </div>
      )}

      <div className="flex justify-center items-center py-2">
        <div className="w-full">
          <h2>Comprehensive Inspection for {vehicle.vehicleMake} {vehicle.vehicleModel}{" "}
            <span className="font-bold text-red-600 text-sm"> {vehicle.registrationNumber}</span>
          </h2>

          {!isLoading ? (
            getCurrentStep().component
          ) : (
            <div className="flex justify-center items-center">
              <CogIcon className="animate-spin h-20 w-20 text-red-500 mt-20" />
              <span className='items-center text-2xl'>Loading ...</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default InspectionContainer;
