// MarginAnalysis.js
import React from 'react';
import { formatCurrency } from '../../Help/helper';

export const MarginAnalysis = ({ totals }) => {
  const margin = totals.totalSale - totals.totalCost;

  return (
    <div className="p-1 bg-gray-50 rounded shadow mt-4 print:hidden">
      <h2 className="text-xl font-semibold text-gray-800 mb-4">Margin Analysis</h2>
      <div className="text-sm">
        <p>Total Sale Amount: {formatCurrency(totals.totalSale)}</p>
        <p>Total Cost Amount: {formatCurrency(totals.totalCost)}</p>
        <p>Margin: {formatCurrency(margin)}</p>
      </div>
    </div>
  );
};

