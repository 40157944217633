import { Routes } from 'react-router-dom';
import Layout from '../Layout';


export default function LayoutRoutes({ children }) {
  return (
    <Layout>
      <Routes>
        {children}
      </Routes>
    </Layout>
  );
}
