import React, { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';

const DQReport = ({ updateData, checklistData, formData }) => {
    const [localFormData, setLocalFormData] = useState({
        technicianObservation: formData?.technicianObservation || '',
        workRequired: formData?.workRequired || '',
        markedItemComments: formData?.markedItemComments || {},
    });

    const debouncedUpdateData = useCallback(
        debounce((data) => {
            updateData(data);
        }, 300),
        [updateData]
    );

    useEffect(() => {
        debouncedUpdateData(localFormData);
        return () => {
            debouncedUpdateData.cancel();
        };
    }, [localFormData, debouncedUpdateData]);

    const handleMarkedItemCommentChange = (item, value) => {
        setLocalFormData(prevState => ({
            ...prevState,
            markedItemComments: {
                ...prevState.markedItemComments,
                [item]: value,
            }
        }));
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setLocalFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const markedItems = checklistData 
        ? Object.entries(checklistData).flatMap(([section, items]) =>
            Object.keys(items).filter(item => items[item] === 'X')
          )
        : [];

    return (
        <form onSubmit={(e) => e.preventDefault()} className="space-y-6">
            <div>
                <label className="block text-sm font-medium text-gray-700">Technician Observation</label>
                <textarea
                    name="technicianObservation"
                    value={localFormData.technicianObservation}
                    onChange={handleInputChange}
                    rows={4}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
            </div>
            <div>
                <label className="block text-sm font-medium text-gray-700">Work Required</label>
                <textarea
                    name="workRequired"
                    value={localFormData.workRequired}
                    onChange={handleInputChange}
                    rows={4}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
            </div>
            {markedItems.length > 0 && (
                <div className='border-gray-300 shadow-sm bg-blue-50 p-4'>
                    <h3 className="text-lg font-medium mb-4">Marked Inspection Items:</h3>
                    <ul className="space-y-4">
                        {markedItems.map((item, index) => (
                            <li key={index} className="flex items-center space-x-4">
                                <div className="flex-1">
                                    <span className="block text-sm font-medium text-gray-700">{item}</span>
                                </div>
                                <div className="flex-1">
                                    <textarea
                                        value={localFormData.markedItemComments[item] || ''}
                                        onChange={(e) => handleMarkedItemCommentChange(item, e.target.value)}
                                        rows={2}
                                        placeholder="Enter comment"
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </form>
    );
};
export default DQReport;
