import React, { useState, useEffect } from 'react';
import { getDocs, collection } from 'firebase/firestore';
import { db } from '../../../firebase'; 
import { YearSelect } from '../../helpers/YearSelect';
import formattedVehicleManufacturers from '../../Vehicles/vehicleManufacturers';

const MarginSettingsForm = ({ formState, handleInputChange, handleMakeChange, handleSubmit, handleYearChange }) => {
 
  const [services, setServices] = useState([]);
  
  useEffect(() => {
    const fetchServices = async () => {
      const querySnapshot = await getDocs(collection(db, 'services'));
      const fetchedServices = querySnapshot.docs.map(doc => doc.data());
      setServices(fetchedServices);
    };

    fetchServices();
  }, []);

  function MarginField() {
    return (
      <div>
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="margin">
          Margin (%)
        </label>
        <input
          className="block w-1/2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          id="margin"
          name="margin"
          type="number"
          value={formState.margin}
          onChange={handleInputChange}
          required
        />
      </div>
    );
  }    
 
  return (
    <div>        
                                      <form onSubmit={handleSubmit} className="mb-8">
                                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                                        <div>
                                          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="type">
                                            Margin Type
                                          </label>
                                          <select
                                             className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            id="type"
                                            name="type"
                                            value={formState.type}
                                            onChange={handleInputChange}
                                          >
                                            <option value="default">Default</option>
                                            <option value="default-vehicle">Default Vehicle Margin</option>
                                            <option value="default-service">Default Service Margin</option>
                                            <option value="vehicles">Vehicles</option>
                                            <option value="services">Services</option>
                                          </select>
                                        </div>

                        {/* Conditional Fields Based on Margin Type */}
                        {formState.type === 'vehicles' && (
                          <>
                            <div>
                              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="make">
                                Vehicle Manufacturer
                              </label>
                              <select
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                id="make"
                                name="make"
                                onChange={handleMakeChange}
                                value={formState.make}
                              >
                                <option value="">Select Make</option>
                                {formattedVehicleManufacturers.map(manufacturer => (
                                  <option key={manufacturer.value} value={manufacturer.value}>
                                    {manufacturer.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div>
                              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="year">Year</label>
                              <YearSelect onChange={handleYearChange} value={formState.year} />
                            </div>
                            <MarginField />
                          </>
                        )}

                          {formState.type === 'services' && (
            <>
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
                  Service Description
                </label>
                <select
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  id="description"
                  name="description"
                  value={formState.description}
                  onChange={handleInputChange}
                >
                  <option value="">Select Service</option>
                  {services.map((service, index) => (
                    <option key={index} value={service.name}>
                      {service.name}
                    </option>
                  ))}
                </select>
              </div>
              <MarginField />
            </>
                            )}
                            

                        {formState.type.includes('default') && <MarginField />}
                      </div>

                                    <button
                                    type="submit"
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                  >
                                    Add Margin 
                                  </button>
                                </form>

    </div>
  )
}
export default MarginSettingsForm