import React, { useState, useEffect } from 'react';
import { collection, getDocs, addDoc, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const CreateLeaveEntry = () => {
  const [employeeId, setEmployeeId] = useState('');
  const [leaveType, setLeaveType] = useState('');
  const [selectedDates, setSelectedDates] = useState([null, null]); // Initialize with [null, null] to handle range selection
  const [tempDesignateId, setTempDesignateId] = useState('');
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    // Fetch all employees from Firestore
    const fetchEmployees = async () => {
      try {
        const employeesSnapshot = await getDocs(collection(db, 'employees'));
        const employeesList = employeesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEmployees(employeesList);
      } catch (error) {
        toast.error('Failed to fetch employees: ' + error.message);
      }
    };

    fetchEmployees();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const [startDate, endDate] = selectedDates;

    if (!startDate || !endDate) {
      toast.error('Please select a valid date range.');
      return;
    }

    try {
      // Add the new leave request to Firestore
      await addDoc(collection(db, 'leaveRequests'), {
        employeeId,
        leaveType,
        leaveDates: [
          Timestamp.fromDate(startDate),
          ...getAllDatesInRange(startDate, endDate).map(date => Timestamp.fromDate(date))
        ],
        tempDesignateId,
        status: 'Approved', // Directly approve since it's created by HR
        approvalDate: Timestamp.now(), // Add the approvalDate timestamp
      });

      toast.success('Leave entry created successfully');
      resetForm();
    } catch (error) {
      toast.error('Failed to create leave entry: ' + error.message);
    }
  };

  const getAllDatesInRange = (start, end) => {
    const dateArray = [];
    let currentDate = new Date(start);
    while (currentDate <= end) {
      dateArray.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dateArray;
  };

  const resetForm = () => {
    setEmployeeId('');
    setLeaveType('');
    setSelectedDates([null, null]);
    setTempDesignateId('');
  };

  const handleClearDates = () => {
    setSelectedDates([null, null]);
  };

  return (
    <div className="p-4 bg-white rounded-md shadow-md">
      <h2 className="text-lg font-medium text-gray-900">Create Leave Entry</h2>
      <form onSubmit={handleSubmit}>
        <div className="mt-4">
          <label className="block text-sm font-medium text-gray-700">Employee</label>
          <select 
            value={employeeId} 
            onChange={(e) => setEmployeeId(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            required
          >
            <option value="">Select Employee</option>
            {employees.map(employee => (
              <option key={employee.id} value={employee.id}>
                {employee.firstName} {employee.lastName}
              </option>
            ))}
          </select>
        </div>
        <div className="mt-4">
          <label className="block text-sm font-medium text-gray-700">Leave Type</label>
          <select 
            value={leaveType} 
            onChange={(e) => setLeaveType(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            required
          >
            <option value="">Select Leave Type</option>
            <option value="Annual Leave">Annual Leave</option>
            <option value="Casual Leave">Casual Leave</option>
            <option value="Paternity Leave">Paternity Leave</option>
            <option value="Maternity Leave">Maternity Leave</option>
            <option value="Compassionate Leave">Compassionate Leave</option>
          </select>
        </div>
        <div className="mt-4">
          <label className="block text-sm font-medium text-gray-700">Select Dates</label>
          <DatePicker
            selected={selectedDates[0]}
            onChange={(dates) => setSelectedDates(dates)}
            startDate={selectedDates[0]}
            endDate={selectedDates[1]}
            selectsRange
            inline
            className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          <div className="mt-2">
            <p className="text-sm text-gray-700">Selected Dates: {selectedDates[0] && selectedDates[1] ? `${selectedDates[0].toLocaleDateString()} - ${selectedDates[1].toLocaleDateString()}` : 'None'}</p>
            <button 
              type="button" 
              onClick={handleClearDates} 
              className="mt-2 bg-red-500 text-white px-2 py-1 rounded-md text-sm"
            >
              Clear Dates
            </button>
          </div>
        </div>
        <div className="mt-4">
          <label className="block text-sm font-medium text-gray-700">Temporary Designate</label>
          <select 
            value={tempDesignateId} 
            onChange={(e) => setTempDesignateId(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            required
          >
            <option value="">Select Temporary Designate</option>
            {employees.map(employee => (
              <option key={employee.id} value={employee.id}>
                {employee.firstName} {employee.lastName}
              </option>
            ))}
          </select>
        </div>
        <div className="mt-6 flex justify-end">
          <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md">
            Create Leave Entry
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateLeaveEntry;
