import React, { useState, useEffect } from 'react';
import { collection, addDoc, Timestamp, getDocs, doc, getDoc } from "firebase/firestore";
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { PlusCircleIcon,MinusCircleIcon, CheckCircleIcon } from '@heroicons/react/24/solid';
import {db} from '../../firebase';
import { generateJobCardNo } from './generateJobCardNo';
import './JobCard.css';
import { toast, ToastContainer } from 'react-toastify';
import BreadCrumb from '../BreadCrumb';

const pages = [
  { name: 'Service Advisor', to: '/vehicles', component: Link, current: false },
  { name: 'New Job Card', to: '/vehicles/jobs', component: Link, current: true },  
];

const JobCard = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const vehicle = location.state ? location.state.vehicle : null;
    const [contractors, setContractors] = useState([]);
    const [formState, setFormState] = useState({
      ownerRequest: '',
      technicianObservation: '',
      serviceType: [],
      partsRequired: [{ partName: '', quantity: '' }],
      servicesRequired: [{ serviceType: '', technicianType: '', serviceQuantity: '', serviceCost: '' }],
      estimatedDuration: '',
      durationUnit: 'minutes',
      workRequired: '',
      contractors: [], 
    });
         
      const [services, setServices] = useState([]); 
      const [formSubmitted, setFormSubmitted] = useState(false);


  useEffect(() => {
    const fetchServices = async () => {
      const servicesCollection = collection(db, 'services');
      const servicesSnapshot = await getDocs(servicesCollection);
      const servicesList = servicesSnapshot.docs.map(doc => doc.data().name);
      setServices(servicesList);
    };

    fetchServices();
  });

  useEffect(() => {
    const fetchContractors = async () => {
      const contractorsCollection = collection(db, 'contractors');
      const contractorsSnapshot = await getDocs(contractorsCollection);
      const contractorsList = contractorsSnapshot.docs.map(doc => ({
        id: doc.id, 
        ...doc.data(),
      }));
      setContractors(contractorsList);
    };
  
    fetchContractors();
  });
  
  const handleContractorChange = (event) => {
    const contractorId = event.target.value;
    if (event.target.checked) {
      setFormState((prevState) => ({
        ...prevState,
        contractors: prevState.contractors
          ? [...prevState.contractors, contractorId]
          : [contractorId], 
      }));
    } else {
      setFormState((prevState) => ({
        ...prevState,
        contractors: prevState.contractors
          ? prevState.contractors.filter((id) => id !== contractorId)
          : [], 
      }));
    }
  };

  const handleServiceInputChange = (event, index) => {
    const { name, value } = event.target;
    const list = [...formState.servicesRequired];
    list[index] = { ...list[index], [name]: value };
    setFormState(prevState => ({
        ...prevState,
        servicesRequired: list
    }));
};

  const handleAddServiceClick = () => {
    setFormState(prevState => ({
      ...prevState,
      servicesRequired: [...prevState.servicesRequired, { serviceType: '', technicianType: '', estimatedDuration: '', serviceCost: '' }]
    }));
  };
  
  const handleRemoveServiceClick = index => {
    const list = [...formState.servicesRequired];
    list.splice(index, 1);
    setFormState(prevState => ({
      ...prevState,
      servicesRequired: list
    }));
  };
  
  const handleDurationUnitChange = (event) => {
    setFormState({
      ...formState,
      durationUnit: event.target.value,
    });
  };

  const handlePartsInputChange = (event, index) => {
    const { name, value } = event.target;
    const list = [...formState.partsRequired];
    
    // Check if the field is quantity or price and convert the value to a number
    if (name === 'quantity') {
      list[index][name] = parseInt(value, 10);
       } else {
      list[index][name] = value;
    }
  
    setFormState(prevState => ({
      ...prevState,
      partsRequired: list
    }));
  };
  
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
 

  const handleRemoveClick = index => {
    const list = [...formState.partsRequired];
    list.splice(index, 1);
    setFormState(prevState => ({
      ...prevState,
      partsRequired: list
    }));
  };

  const handleAddClick = () => {
    setFormState(prevState => ({
      ...prevState,
      partsRequired: [...prevState.partsRequired, { partName: '', quantity: ''}]
    }));
  };

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      setFormState({
        ...formState,
        serviceType: [...formState.serviceType, event.target.value],
      });
    } else {
      setFormState({
        ...formState,
        serviceType: formState.serviceType.filter(service => service !== event.target.value),
      });
    }
  };

  const validateForm = () => {
    if (!formState.ownerRequest || !formState.technicianObservation) {
        toast.error("Please fill in all required fields");
        return false;
    }
    if (formState.partsRequired.some(part => !part.partName || !part.quantity)) {
        toast.error("Please complete all parts required information");
        return false;
    }
    return true;
};

const resetForm = () => {
  setFormState({
      ownerRequest: '',
      technicianObservation: '',
      serviceType: [],
      partsRequired: [{ partName: '', quantity: '' }],
      estimatedDuration: '',
      durationUnit: 'minutes',
      workRequired: '',
      contractors: []
  });
  setFormSubmitted(false);
};

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;

    try {

    const customerRef = doc(db, "customers", vehicle.customerId);
    const customerSnap = await getDoc(customerRef);
    const customerData = customerSnap.exists() ? customerSnap.data() : {};

    const customerName = `${customerData.firstName} ${customerData.lastName}`;
    const customerEmail = customerData.email;
    const customerAddress = customerData.address;
    const jobData = {
      ...formState,
      customerName,
      customerEmail,
      customerAddress,
      vehicleMake: vehicle.vehicleMake || '', 
      vehicleModel: vehicle.vehicleModel || '',
      vehicleYear: vehicle.year || '',
      VIN : vehicle.VIN || '',
      registrationNumber: vehicle.registrationNumber || '',
      estimatedDuration: `${formState.estimatedDuration} ${formState.durationUnit}`,
      vehicleId: vehicle.id,
      createdAt: Timestamp.fromDate(new Date()),
      jobCardNo : generateJobCardNo(),
      status : "Open",
      procurementPricing: "Not Set",
      contractors: formState.contractors, 
      agentId: vehicle?.agentId || null,
      tracker: [],
      approvalStatus: 'No',
      approvalDate : null 
    };
    
    delete jobData.durationUnit;
    await addDoc(collection(db, "jobs"), jobData);
    toast.success("Job Card Created Successfully");
    resetForm();
    navigate('/vehicles/openjobs');
} catch (error) {
    toast.error("Error submitting job card: " + error.message);
}
};

const increment = (index) => {
  const newList = [...formState.partsRequired];
  newList[index].quantity = (newList[index].quantity || 0) + 1;
  setFormState({ ...formState, partsRequired: newList });
};

const decrement = (index) => {
  const newList = [...formState.partsRequired];
  if (newList[index].quantity > 0) {
    newList[index].quantity -= 1;
  }
  setFormState({ ...formState, partsRequired: newList });
};
  
  return (
    <>
    <BreadCrumb pages={pages} />
    <div className="max-w-4xl mx-auto bg-white p-6 shadow-lg rounded-lg dark:bg-neutral-800">
    <h2 className="text-xl font-semibold text-gray-800 dark:text-white mb-4">
      Job Card: {vehicle.vehicleMake || ''} {vehicle.vehicleModel || ''} <span className="text-gray-600 dark:text-gray-400">{vehicle.registrationNumber || ''}</span>
    </h2>
        <form onSubmit={handleSubmit} className="job-card-form">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">   
                    <div>
                        <label className="text-gray-700 dark:text-gray-300 block mb-2 font-medium">Customer Complaint</label>
                        <textarea
                          name="ownerRequest"
                          value={formState.ownerRequest}
                          onChange={handleInputChange}
                          className="form-textarea mt-1 block w-full text-sm border-gray-300 shadow-sm dark:border-gray-600 dark:bg-neutral-700
                          focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md" rows="4">
                          </textarea>
                      </div>

                      <div>
                        <label className="text-gray-700 dark:text-gray-300 block mb-2 font-medium">Technical Diagnosis</label>
                        <textarea
                          name="technicianObservation"
                          value={formState.technicianObservation}
                          onChange={handleInputChange}
                          className="form-textarea mt-1 block w-full border-gray-300 shadow-sm text-sm dark:border-gray-600 dark:bg-neutral-700
                          focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md" rows="4">
                          </textarea>
                      </div>            
                </div>

                        <div className="border-t border-gray-200 dark:border-gray-600 pt-4 bg-blue-50 pb-4 pl-4">
                          <h3 className="text-lg font-medium text-gray-900 dark:text-white">Assign Technicians</h3>
                          <div className="space-y-4 mt-4">
                            {contractors.map((contractor) => (
                              <div key={contractor.id} className="flex items-center">
                                <input
                                  type="checkbox"
                                  name="contractor"
                                  value={contractor.id.toString()}
                                  checked={formState.contractors.includes(contractor.id.toString())}
                                  onChange={handleContractorChange}
                                  className="text-indigo-600 border-gray-300 rounded focus:ring-indigo-500 dark:border-gray-600 dark:bg-neutral-700 dark:focus:ring-indigo-600"
                                />
                                <label className="ml-2 text-sm text-gray-700 dark:text-gray-300">
                                  {contractor.name} - {contractor.description}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>

                            {formSubmitted && (
                              <div className="flex items-center space-x-2 text-green-500">
                                <CheckCircleIcon className="h-6 w-6" />  <span>Job Card Created Successfully</span>
                              </div>
                            )}
                   
                              <div>
                              <label className="block text-gray-700 text-sm font-bold mb-2">Service Type</label>
                              <div className="grid grid-cols-4 text-sm mb-1 gap-4">
                              {services.map(service => (
                                  <div key={service} className="flex items-center">
                                  <input type="checkbox" name="serviceType" value={service} checked={formState.serviceType.includes(service)}
                                      onChange={handleCheckboxChange}  className="mr-2"  />
                                  <label>{service}</label>
                                  </div>
                              ))}
                              </div>
                             </div>


                             <div className='mt-4 bg-gray-50 mb-2 pl-4 pb-2'>
                                <label className="block text-gray-500 text-lg font-bold mb-2 uppercase pt-2">Parts Required</label>
                                {formState.partsRequired.map((item, index) => {
                                return (
                                    <div className="flex mb-2">

                                        <div className="mr-2 flex-grow">
                                        <label className="block text-gray-700 text-sm font-bold text-center mb-2">Part Name</label>
                                        <input
                                        name="partName"
                                        value={item.partName}
                                        onChange={(event) => handlePartsInputChange(event, index)}
                                        className="shadow appearance-none border rounded w-full py-2 text-sm px-3 text-center text-gray-700 leading-tight 
                                        focus:outline-none focus:shadow-outline" />
                                        </div>

                                       <div className="flex-grow">
                                            <label className="block text-gray-700 text-sm font-bold text-center mb-2">Type</label>
                                            <div className='flex items-center justify-center'>
                                            <select
                                              name="type"
                                              value={item.type || 'generic'} 
                                              onChange={(event) => handlePartsInputChange(event, index)}
                                              className="shadow appearance-none border rounded ml-10 py-2 px-3 text-sm text-center w-full text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            >
                                              <option value="generic">Generic Spare Part</option>
                                              <option value="vehicle-specific">Vehicle Specific Spare Part</option>
                                               </select>
                                          </div>
                                          </div>

                                          <div className="mr-2 flex-grow-7">
                                         <label className=" block text-gray-700 text-sm font-bold text-center mb-2">Quantity</label>
                                              <div className='flex items-center justify-center'>
                                              <button
                                                type="button"
                                                onClick={() => decrement(index)} // Implement decrement function
                                                className="bg-gray-200  text-gray-600 hover:text-gray-700 ml-4 hover:bg-gray-300 h-10 w-8 rounded-l cursor-pointer"
                                              >
                                                -
                                              </button>
                                              <input
                                                type="text"
                                                name="quantity" // or "estimatedDuration" for the other field
                                                className="shadow appearance-none border rounded text-sm py-2 px-3 text-center w-1/5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                value={item.quantity} // or item.estimatedDuration for the other field
                                                onChange={(event) => handlePartsInputChange(event, index)} // Use the appropriate handler
                                              />
                                              <button
                                                type="button"
                                                onClick={() => increment(index)} // Implement increment function
                                                className="bg-gray-200 text-gray-600  hover:text-gray-700 mr-4 hover:bg-gray-300 h-10 w-8 rounded-r cursor-pointer"
                                              >
                                                +
                                              </button>
                                              </div>
                                              </div>
                                            
                                                                     
                                            <div className="flex flex-col justify-between">
                                                {formState.partsRequired.length !== 1 && 
                                                <MinusCircleIcon 
                                                    className="h-6 w-6 text-red-500 cursor-pointer" 
                                                    onClick={() => handleRemoveClick(index)}
                                                />
                                                }
                                                {formState.partsRequired.length - 1 === index && 
                                                <PlusCircleIcon 
                                                    className="h-6 w-6 text-green-500 cursor-pointer" 
                                                    onClick={handleAddClick}
                                                />
                                                }
                                              </div>
                                              </div>
                                                );
                                            })}
                                       </div>
                              
                                       <hr className="my-12 h-0.5 border-t-0 bg-neutral-100 dark:bg-white/10" />

                                      <div className='mt-4 bg-blue-50 mb-2 pt-2 pl-4 pb-2'>
                                      <label className="block text-gray-700 text-lg font-bold mb-2 uppercase">Services Required</label>
                                      {formState.servicesRequired.map((item, index) => (
                                        <div className="flex mb-2" key={index}>

                                        <div className="mr-2 flex-grow">
                                            <label className="block text-gray-700 text-sm font-bold text-center mb-2">Description</label>
                                              <input
                                                name="serviceType"
                                                placeholder="Description"
                                                value={item.serviceType}
                                                onChange={(event) => handleServiceInputChange(event, index)}
                                                className="shadow appearance-none border rounded w-full py-2 text-sm px-3 text-center text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                              />
                                          </div>

                                               <div className="mr-2 flex-grow">
                                                  <label className="block text-gray-700 text-sm font-bold text-center mb-2">Type of Technician Required</label>
                                                  <div className='flex items-center justify-center'>
                                                    <select
                                                      name="technicianType"
                                                      value={item.technicianType}
                                                      onChange={(event) => handleServiceInputChange(event, index)}
                                                      className="shadow appearance-none border rounded w-full ml-10 mr-10 text-sm py-2 px-3 text-center text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    >
                                                      <option value="">Select Technician Type</option>
                                                      {services.map(service => (
                                                        <option value={service} key={service}>{service}</option>
                                                      ))}
                                                    </select>
                                                    </div>
                                                  </div>
                                        
                                                  <div className="mr-2 flex-grow-7">
                                                    <label className="block text-gray-700 text-sm font-bold text-center mb-2">Qty or Duration(Hours)</label>
                                                      <input type="number" name="serviceQuantity"  placeholder="Quantity or Duration"
                                                        value={item.serviceQuantity} onChange={(event) => handleServiceInputChange(event, index)}
                                                        className="shadow appearance-none border rounded w-full text-sm py-2 px-3 text-center text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                      />
                                                  </div>   

                                                  <div className="mr-2 flex-grow-7">
                                                  <input type="number"
                                                      name="serviceCost"
                                                      value={item.serviceCost}
                                                      placeholder="Service Cost"
                                                      onChange={(event) => handleServiceInputChange(event, index)}
                                                      className="shadow appearance-none border rounded w-full text-sm py-2 px-3 text-center text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                  />
                                                  </div>


                                          
                                          <div className="flex flex-col justify-between">
                                          {formState.servicesRequired.length !== 1 && (
                                            <MinusCircleIcon
                                              onClick={() => handleRemoveServiceClick(index)}
                                              className="h-6 w-6 text-green-500 cursor-pointer" 
                                            />
                                          )}
                                          {formState.servicesRequired.length - 1 === index && (
                                            <PlusCircleIcon
                                              onClick={handleAddServiceClick}
                                              className="h-6 w-6 text-green-500 cursor-pointer" 
                                            />                                        

                                          )}
                                            </div>
                                        </div>
                                      ))}
                                    </div>

                                    <hr className="my-6 h-0.5 border-t-0 bg-neutral-100 dark:bg-white/10" />

                                    <div className='flex mb-3'>
                                        <div>
                                            <label className="block text-gray-700 text-sm font-bold mb-2">Overall Estimated Duration</label>
                                            <div className="flex">
                                            <input
                                                type="number"
                                                name="estimatedDuration"
                                                value={formState.estimatedDuration}
                                                onChange={handleInputChange}
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mr-2"
                                            />
                                            
                                            <select
                                                name="durationUnit"
                                                value={formState.durationUnit}
                                                onChange={handleDurationUnitChange}
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            >
                                                <option value="minutes">Minutes</option>
                                                <option value="hours">Hours</option>
                                                <option value="days">Days</option>
                                           </select>                                          
                                            </div>
                                        </div>
                                        <div className='pl-10 w-full'>
                                            <label className="block text-gray-700 text-sm font-bold mb-2 "> Details of Repair or Service Required</label>
                                            <textarea
                                            name="workRequired"
                                            value={formState.workRequired}
                                            onChange={handleInputChange}
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            rows="4"
                                            />
                                        </div>                                         
                                    </div>   
                                    
                    <div className="form-actions"><button type="submit" className="form-submit-btn">{formSubmitted ? "Submitted" : "Submit"}</button> </div>
            </form>

            <ToastContainer />
          </div>
          </>
        );
        
      };

export default JobCard;