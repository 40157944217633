// NotAuthorizedPage.js
import React from 'react';

const NotAuthorizedPage = () => {
  return (
    <div className="h-screen flex justify-center items-center bg-gray-50">
      <div className="bg-white p-8 rounded shadow-lg text-center">
        <h2 className="text-2xl font-bold mb-6 text-red-600">Access Denied</h2>
        <p className="text-gray-700 mb-6">
          Sorry, you don't have permission to access this page.
        </p>
        <a href="/login" className="bg-blue-500 text-white px-4 py-2 rounded">Go to Login</a>
      </div>
    </div>
  );
};

export default NotAuthorizedPage;
