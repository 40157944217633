import React from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Fragment } from 'react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const VehicleTable = ({ vehicles, selectedCustomer, navigate, handleScheduleAppointment, viewInspectionReport, deleteVehicle }) => (
  <div className="flex-1 px-4 sm:px-6 lg:px-8">
    <h2 className="text-2xl font-semibold text-gray-800 mt-10 mb-6">Cars Owned by Selected Customer</h2>
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-4 py-3 text-left text-sm font-medium text-gray-600 uppercase tracking-wider">Make & Model</th>
            <th className="px-4 py-3 text-left text-sm font-medium text-gray-600 uppercase tracking-wider">Year</th>
            <th className="px-4 py-3 text-left text-sm font-medium text-gray-600 uppercase tracking-wider">GIN</th>
            <th className="px-4 py-3 text-left text-sm font-medium text-gray-600 uppercase tracking-wider">Actions</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {vehicles.filter(vehicle => vehicle.customerId === (selectedCustomer?.value || '')).map((vehicle) => (
            <tr key={vehicle.id}>
              <td className="px-4 py-4 whitespace-nowrap">
                <div className="text-sm font-medium text-gray-900">{vehicle.vehicleMake} {vehicle.vehicleModel}</div>
              </td>
              <td className="px-4 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-600">{vehicle.year}</div>
              </td>
              <td className="px-4 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-600">{vehicle?.GIN}</div>
              </td>
              <td className="px-4 py-4 whitespace-nowrap text-sm font-medium">
                <div className="inline-flex rounded-md shadow-sm">
                  <button
                    type="button"
                    className="inline-flex items-center rounded-l-md bg-blue-600 px-4 py-2 text-sm font-semibold text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    Actions
                  </button>
                  <Menu as="div" className="relative">
                    <Menu.Button className="inline-flex items-center rounded-r-md bg-white px-4 py-2 text-gray-500 ring-1 ring-inset ring-gray-300 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500">
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                      <span className="sr-only">Open options</span>
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-20 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          {[
                            { name: 'Diagnosis History', action: () => navigate('/vehicles/diagnosisHistory', { state: { vehicleId: vehicle.id } }) },
                            { name: 'Schedule Appointment', action: () => handleScheduleAppointment(vehicle) },
                            { name: 'Inspection Report', action: () => viewInspectionReport(vehicle) },
                            { name: 'Delete Vehicle', action: () => deleteVehicle(vehicle.id, vehicle.customerId) },
                            { name: 'Inspect', action: () => navigate('/inspection', { state: { vehicle } }) },
                          ].map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <button
                                  onClick={item.action}
                                  className={classNames(
                                    active ? 'bg-gray-200 text-gray-900' : 'text-gray-700',
                                    'block w-full px-4 py-2 text-left text-sm hover:bg-blue-500 hover:text-white'
                                  )}
                                >
                                  {item.name}
                                </button>
                              )}
                            </Menu.Item>
                          ))}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
);

export default VehicleTable;
