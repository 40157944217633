import React, { useState, useEffect } from 'react';
import { doc, getDoc, getDocs, collection, query, where, updateDoc, Timestamp } from "firebase/firestore";
import { db } from '../../firebase';
import { useNavigate, useParams } from 'react-router-dom';
import JobUpdateModal from './JobUpdateModal';
import TestResultModal from './TestResultModal';
import { toast, ToastContainer } from 'react-toastify';

const JobDetail = () => {
  const { jobCardNo } = useParams();
  const [job, setJob] = useState({ steps: [] });
  const navigate = useNavigate();
  const [approvalStatus, setApprovalStatus] = useState('');
  const [isCompleted, setIsCompleted] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isTestResultModalOpen, setIsTestResultModalOpen] = useState(false);
  const [testResult, setTestResult] = useState(''); // Ensure this is used in the component

  useEffect(() => {
    const fetchJob = async () => {
      const jobsCollection = collection(db, 'jobs');
      const jobQuery = query(jobsCollection, where('jobCardNo', '==', jobCardNo));
      const jobSnapshot = await getDocs(jobQuery);

      if (!jobSnapshot.empty) {
        const jobDoc = jobSnapshot.docs[0];
        const jobData = jobDoc.data();
        const contractorNames = await Promise.all(
          jobData.contractors.map(async (id) => {
            const contractorSnapshot = await getDoc(doc(db, 'contractors', id));
            return contractorSnapshot.data()?.name;
          })
        );

        const vehicleRef = doc(db, 'vehicles', jobData.vehicleId);
        const vehicleSnapshot = await getDoc(vehicleRef);

        if (!vehicleSnapshot.exists()) {
          console.error(`No vehicle found with ID: ${jobData.vehicleId}`);
          return;
        }

        const vehicleData = vehicleSnapshot.data();
        const customerRef = doc(db, 'customers', vehicleData.customerId);
        const customerSnapshot = await getDoc(customerRef);

        if (!customerSnapshot.exists()) {
          console.error(`No customer found with ID: ${vehicleData.customerId}`);
          return;
        }

        const customerData = customerSnapshot.data();

        if (jobData.tracker?.some((entry) => entry.State === 'completed')) {
          setIsCompleted(true);
        }

        const steps = [
          { title: 'Inspection', status: jobData.status === 'Open' ? 'open' : 'pending' },
          { title: 'Quotation', status: jobData.tracker?.some((entry) => entry.State === 'Quotation Raised') ? 'open' : 'pending' },
          { title: 'Approval', status: jobData.approvalStatus === 'Yes' ? 'open' : 'pending' },
          { title: 'Repair', status: jobData.approvalStatus === 'Yes' ? 'open' : 'pending' },
          { title: 'Testing', status: isCompleted ? 'open' : 'pending' },
          { title: 'Close', status: testResult === 'Test Passed' ? 'open' : 'pending' },
        ];

        setJob({
          id: jobDoc.id,
          ...jobData,
          contractorNames,
          customerName: `${customerData.firstName} ${customerData.lastName}`,
          steps,
          approvalStatus: jobData.approvalStatus,
          approvalDate: jobData.approvalDate,
        });

        setApprovalStatus(jobData.approvalStatus);
      }
    };

    fetchJob();
  }, [jobCardNo, isCompleted, testResult]); // Added missing dependencies

  const handleOpenModal = () => {
    if (job) {
      navigate(`/finance/quotation/${jobCardNo}`);
    }
  };

  const handleApprovalChange = async (event) => {
    const selectedStatus = event.target.value;
    if (selectedStatus === 'Yes') {
      const confirmChange = window.confirm('Do you really want to approve?');
      if (confirmChange) {
        setApprovalStatus(selectedStatus);
        const approvalDate = new Date();

        if (job && job.jobCardNo) {
          const jobRef = doc(db, 'jobs', job.id);

          await updateDoc(jobRef, {
            approvalStatus: selectedStatus,
            approvalDate: Timestamp.fromDate(approvalDate),
          });

          const invoiceQuery = query(collection(db, 'invoices'), where('invoiceNumber', '==', job.jobCardNo));
          const invoiceSnapshot = await getDocs(invoiceQuery);

          if (!invoiceSnapshot.empty) {
            const invoiceData = invoiceSnapshot.docs[0];
            const invoiceRef = doc(db, 'invoices', invoiceData.id);
            await updateDoc(invoiceRef, {
              approvalStatus: selectedStatus,
              approvalDate: Timestamp.fromDate(approvalDate),
            });
          }
        }
      }
    } else {
      setApprovalStatus(selectedStatus);
    }
  };

  const handleOpenUpdateModal = () => {
    setIsUpdateModalOpen(true);
  };

  const handleCloseUpdateModal = () => {
    setIsUpdateModalOpen(false);
  };

  const handleUpdateSubmit = ({ textAreaValue, selectValue }) => {
    console.log(textAreaValue, selectValue);
  };

  const handleTestResultSubmit = async ({ textAreaValue, selectValue }) => {
    console.log(textAreaValue, selectValue);
    if (selectValue === 'Test Failed') {
      setIsCompleted(false);
    } else {
      setIsCompleted(true);
      setTestResult(selectValue); // Use setTestResult to update the state
    }
    setIsTestResultModalOpen(false);
  };
  

  const handleCloseJob = async () => {
    try {
      const jobRef = doc(db, 'jobs', job.id);
      await updateDoc(jobRef, { status: 'Closed' });
      toast.success('Job status updated successfully to "Closed"');
    } catch (error) {
      console.error('Error updating job status: ', error);
      toast.error('Failed to update job status');
    }
  };


  return (
    <div className="p-4 bg-gray-100 min-h-screen">
      <div className="bg-white rounded-lg p-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
          <div className="border p-2">
            <p className="font-semibold text-sm mb-2">Vehicle: <span className='text-gray-500 text-sm'> {job.vehicleMake} {" "} {job.vehicleModel}  </span></p>
            <p><span className="font-semibold text-sm mb-2">Reg No: </span> <span className='text-sm text-gray-500'>  {job.registrationNumber} </span></p>
            <p><span className="font-semibold text-sm mb-2">VIN: </span> <span className='text-sm text-gray-500'>  {job.VIN?  job.VIN: 'N/A'} </span></p>
            <p><span className="font-semibold text-sm mb-2">Technician:</span> <span className='text-sm text-gray-500'> {job.contractorNames ?job.contractorNames.join(', ') : 'N/A'} </span></p>
            <p><span className="font-semibold text-sm mb-2">Date Opended: </span> <span className='text-sm text-gray-500'>  {job.createdAt?  job.createdAt.toDate().toLocaleString(): 'N/A'} </span></p>
          </div>
          <div className="border p-2">
            <p className="font-semibold text-sm mb-2"> Service Type: <br/> <span className='text-sm text-gray-500'> {job.serviceType ? job.serviceType.join(', ') : 'N/A'} </span> </p>
          </div>
          <div className="border p-2">
            <p className="font-semibold text-sm mb-2">Work Required: <br/> <span className='text-sm text-gray-500 whitespace-pre-wrap'>  {job.workRequired} </span></p>
          </div>
          <div className="border p-2">
            <p className="font-semibold text-sm mb-2">Other Observations: <br/> <span className='text-sm text-gray-500'> {job.additionalObservations ?job.additionalObservations : 'N/A'} </span> </p>
          </div>
        </div>

<ToastContainer />

        <div className="flex flex-col lg:flex-row justify-start mt-4 space-y-4">
          {job.steps && job.steps.map((step, index) => (
                <div key={index} className="bg-white shadow-md rounded-lg p-4 w-full md:w-48 mb-4 transition-transform transform hover:scale-105">
              <div className="flex items-center justify-between mb-2">
                <div
                  className={`w-full p-2 border rounded-lg text-center text-sm ${
                    step.status === 'open'
                      ? 'border-green-300 text-green-700 bg-green-50'
                      : step.status === 'in_progress'
                      ? 'border-blue-300 text-blue-700 bg-blue-100'
                      : 'border-gray-300 text-gray-900 bg-gray-100'
                  }`}
                  role="alert"  >
              <div className="flex items-center justify-center">
                <span className="sr-only">{step.title}</span>
                <h3 className="font-medium">{`${index + 1}. ${step.title}`}</h3>
                {index !== job.steps.length - 1 && (
                  <svg
                    className={`w-2 h-4 ml-1 ${
                      step.status === 'open'
                        ? 'text-green-700'
                        : step.status === 'in_progress'
                        ? 'text-amber-700'
                        : 'text-red-900'
                    }`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 16 12"
                  >
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5.917 5.724 10.5 15 1.5" />
                  </svg>
                )}
              </div>
            </div>            
          </div>
          <div className="w-full mt-2 p-2 border rounded-lg">
      {index === 0 && (
        <p className='text-xs'> <span className='font-semibold text-sm'>Date Created:</span> {job.createdAt.toDate().toLocaleString()}
          <br/>
          <p className='uppercase text-xs mt-2 font-bold'> Job Details</p>
          <span className='text-xs'> {job.workRequired} </span>
        </p>
      )}
      {index === 1 && step.status !== 'open' && !job.tracker.some(entry => entry.State === "Quotation Raised") && (
        <button onClick={handleOpenModal} className="py-2 px-4 bg-indigo-900 font-semibold text-xs text-white rounded">
          Generate Quotation
        </button>
      )}
      {index === 1 && step.status === 'open' && job.tracker.some(entry => entry.State === "Quotation Raised") && (
        <p className='text-sm '>Quotation Raised: {job.tracker.find(entry => entry.State === "Quotation Raised").Date.toDate().toLocaleString()}</p>
      )}
      {index === 2 && (
        approvalStatus === 'Yes' ? (
          <p className='text-sm'>Approval Date: {job.approvalDate ? job.approvalDate.toDate().toLocaleString() : 'Not Available'}</p>
        ) : (
          <div>
            <p className='text-xs font-semibold'>Approval Status</p>
            <div>
              <input 
                type="radio" 
                id="approvalYes" 
                name="approval" 
                value="Yes"
                className='text-xs mr-4  '
                checked={approvalStatus === 'Yes'}
                onChange={handleApprovalChange} 
              />
              <label htmlFor="approvalYes" className='w-full py-4 ms-2 text-xs font-medium text-gray-900 dark:text-gray-300'>Yes</label>
              <input 
                type="radio" 
                id="approvalNo" 
                name="approval" 
                className='text-xs ml-6 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                value="No"
                checked={approvalStatus === 'No'}
                onChange={handleApprovalChange} 
              />
              <label htmlFor="approvalNo">No</label>
            </div>
          </div>
        )
      )}
      {index === 3 && approvalStatus === 'Yes' && (

          
        <div>
         <button  id="step4Button" disabled={isCompleted} onClick={handleOpenUpdateModal} className="py-2 px-4 mb-4 bg-blue-500 text-white text-sm rounded flex items-center">
  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
  </svg>
  Job Update
</button>

     
        </div>
      )}
      {index === 4 && isCompleted && (
  <div>
    <button onClick={() => setIsTestResultModalOpen(true)} className="py-2 px-4 bg-blue-500 text-white rounded">
      Test Result
    </button>
    <TestResultModal
      isOpen={isTestResultModalOpen}
      onClose={() => setIsTestResultModalOpen(false)}
      onSubmit={handleTestResultSubmit}
      jobId={job.id}
    />
  </div>
)}
{index === 3 && isCompleted && testResult === 'Test Failed' && (
  <div>
    <p className='text-sm text-red-500'>Test Failed. Please redo the job and test again.</p>
    <button onClick={handleOpenUpdateModal} className="py-2 px-4 bg-blue-500 text-white rounded">
      Reopen Job
    </button>
  
  </div>
)}

{index === 5 && (
          <div>
            {job.tracker && job.tracker.find(entry => entry.State === "Test Passed") ? (
              <div>
                <p className='text-sm'>Test Result: {job.tracker.find(entry => entry.State === "Test Passed").State}</p>
                <p className='text-sm'>Test Date: {job.tracker.find(entry => entry.State === "Test Passed").Date.toDate().toLocaleString()}</p>
                <p className='text-sm'>Comments: {job.tracker.find(entry => entry.State === "Test Passed").comments}</p>
                {job.status !== 'Closed' && (
                  <div>
                    <button onClick={handleCloseJob} className="py-2 px-4 bg-blue-500 text-white rounded">
                      Close Job
                    </button>
                    <p className='text-sm'>Please close the job since the test has passed.</p>
                  </div>
                )}
              </div>
            ) : (
              <p className='text-sm'>No test results found.</p>
            )}
          </div>
        )}

    </div>
  </div>
))}
      </div>
      
      <JobUpdateModal 
        isOpen={isUpdateModalOpen} 
        onClose={handleCloseUpdateModal} 
        onSubmit={handleUpdateSubmit} 
        jobId={job.id} 
      />
    </div>
  );
};

export default JobDetail;
