// ExpenseTracker.jsx
import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase';
import { Link, Navigate } from 'react-router-dom';
import TransactionForm from './TransactionForm';
import { formatCurrency } from '../Help/helper';
import BreadCrumb from '../BreadCrumb';
import TransactionTable from './partials/TransactionTable';
import Payables from './Payables';
import Receivables from './partials/Receivables';
import Invoices from './Invoices';
import Quotations from './Quotations';
import ProcurementsTable from '../Procurement/ProcurementsTable';
import AdminMarginSettings from '../Procurement/AdminMarginSettings';
import { useAuth } from '../Auth/Auth';


const pages = [
  { name: 'Finance', to: '/finance', component: Link, current: false },
  { name: 'Finance Control Center', to: '/finance/bookkeeping', component: Link, current: true },
];

const ExpenseTracker = () => {
  const [transactions, setTransactions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeComponent, setActiveComponent] = useState('transactions');
  const [totalAccountsReceivable, setTotalAccountsReceivable] = useState(0);
  const [totalAccountsPayable, setTotalAccountsPayable] = useState(0);
  const { role, loading } = useAuth();

  useEffect(() => {
    const fetchTransactions = async () => {
      setIsLoading(true);
      const transactionsCollection = collection(db, 'transactions');
      const transactionsSnapshot = await getDocs(transactionsCollection);
      const transactionsList = transactionsSnapshot.docs.map(doc => doc.data());
      setTransactions(transactionsList);
      setIsLoading(false);
    };

    fetchTransactions();
  }, []);

  useEffect(() => {
    const fetchFinancialData = async () => {
      setIsLoading(true);

      const invoicesSnapshot = await getDocs(collection(db, 'newInvoices'));
      const totalReceivable = invoicesSnapshot.docs.reduce((total, doc) => {
        const invoiceData = doc.data();
        return total + (invoiceData.receivable || 0);
      }, 0);

      setTotalAccountsReceivable(totalReceivable);
      setIsLoading(false);
    };

    fetchFinancialData();
  }, []);

  useEffect(() => {
    const fetchPayables = async () => {
      const payablesRef = collection(db, 'payables');
      const q = query(payablesRef, where('paymentStatus', 'in', ['Unpaid', 'Partially Paid']));
      const querySnapshot = await getDocs(q);
      let total = 0;

      querySnapshot.forEach(doc => {
        const data = doc.data();
        if (data.grandTotal) {
          total += data.grandTotal;
        }
      });

      setTotalAccountsPayable(total);
    };

    fetchPayables();
  }, []);

  const totalIncome = transactions.reduce((total, transaction) => {
    return transaction.type === 'Income' ? total + transaction.amount : total;
  }, 0);

  const totalExpenditure = transactions.reduce((total, transaction) => {
    return transaction.type === 'Expenditure' ? total + transaction.amount : total;
  }, 0);

  const accountBalance = totalIncome - totalExpenditure;



  const renderComponent = () => {
    switch (activeComponent) {
      case 'payables': 
        return <Payables  />;
      case 'receivables': 
        return <Receivables />;
      case 'invoices': 
        return <Invoices  />;
      case 'quotations': 
        return <Quotations />;
        case 'procurement': 
        return <ProcurementsTable />;
        case 'margins': 
        return <AdminMarginSettings />;
      
        case 'transactions':
          return   <TransactionTable transactions={transactions} isLoading={isLoading} />
      default:
        return <TransactionTable  />;
    }
  };



  if (loading) {
    return <div>Loading...</div>; 
  }

  if (role !== 'Finance' && role !== 'Admin') {
   
    return <Navigate to="/unauthorized" replace />; 
  }


  return (
    <>
      <BreadCrumb pages={pages} />
    

      <div className="flex flex-wrap">
        {/* Account Summary Cards */}
        {/* Account Balance */}
        <div className="w-full md:w-1/5 xl:w-1/5 p-3">
          <div className="bg-white border rounded shadow p-2">
            <div className="flex flex-row items-center">
              <div className="flex-shrink pr-4">
                <div className="rounded p-3 bg-green-600">
                  <i className="fa fa-wallet fa-2x fa-fw fa-inverse"></i>
                </div>
              </div>
              <div className="flex-1 text-right md:text-center">
                <h5 className="font-bold uppercase text-gray-500">Balance</h5>
                <h3 className="text-xl">
                  {formatCurrency(accountBalance)}{' '}
                  <span className="text-green-500">
                    <i className="fas fa-caret-up"></i>
                  </span>
                </h3>
              </div>
            </div>
          </div>
        </div>
        {/* Total Expenditure */}
        <div className="w-full md:w-1/5 xl:w-1/5 p-3">
          <div className="bg-white border rounded shadow p-2">
            <div className="flex flex-row items-center">
              <div className="flex-shrink pr-4">
                <div className="rounded p-3 bg-pink-600">
                  <i className="fas fa-users fa-2x fa-fw fa-inverse"></i>
                </div>
              </div>
              <div className="flex-1 text-right md:text-center">
                <h5 className="font-bold uppercase text-gray-500">Expenditure</h5>
                <h3 className="text-xl">
                  {formatCurrency(totalExpenditure)}{' '}
                  <span className="text-pink-500">
                    <i className="fas fa-exchange-alt"></i>
                  </span>
                </h3>
              </div>
            </div>
          </div>
        </div>
        {/* Total Income */}
        <div className="w-full md:w-1/5 xl:w-1/5 p-3">
          <div className="bg-white border rounded shadow p-2">
            <div className="flex flex-row items-center">
              <div className="flex-shrink pr-4">
                <div className="rounded p-3 bg-yellow-600">
                  <i className="fas fa-user-plus fa-2x fa-fw fa-inverse"></i>
                </div>
              </div>
              <div className="flex-1 text-right md:text-center">
                <h5 className="font-bold uppercase text-gray-500">Income</h5>
                <h3 className="text-xl">
                  {formatCurrency(totalIncome)}{' '}
                  <span className="text-yellow-600">
                    <i className="fas fa-caret-up"></i>
                  </span>
                </h3>
              </div>
            </div>
          </div>
        </div>
        {/* All Receivables */}
        <div className="w-full md:w-1/5 xl:w-1/5 p-3">
          <div className="bg-white border rounded shadow p-2">
            <div className="flex flex-row items-center">
              <div className="flex-shrink pr-4">
                <div className="rounded p-3 bg-blue-600"></div>
              </div>
              <div className="flex-1 text-right md:text-center">
                <h5 className="font-bold uppercase text-gray-500">Receivables</h5>
                <h3 className="text-xl">{formatCurrency(totalAccountsReceivable)}</h3>
              </div>
            </div>
          </div>
        </div>
        {/* Accounts Payable */}
        <div className="w-full md:w-1/5 xl:w-1/5 p-3">
          <Link to="/procurement/manage" className="block">
            <div className="bg-white border rounded shadow p-2">
              <div className="flex flex-row items-center">
                <div className="flex-shrink pr-4">
                  <div className="rounded p-3 bg-red-600"></div>
                </div>
                <div className="flex-1 text-right md:text-center">
                  <h5 className="font-bold uppercase text-gray-500"> Payable</h5>
                  <h3 className="text-xl">{formatCurrency(totalAccountsPayable)}</h3>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>

  

      <nav className="mb-1">
            <ul className="flex space-x-4 justify-center border-b-2 border-gray-200 pb-1 text-sm">
              <li>
                <button
                  onClick={() => setActiveComponent('payables')}
                  className={`px-2 py-2 rounded transition-colors duration-300 ${activeComponent === 'payables' ? 'text-white bg-blue-600' : 'text-gray-700 bg-white hover:bg-blue-50'}`}
                >
                  Vendor Payables
                </button>
              </li>
              <li>
                <button
                  onClick={() => setActiveComponent('receivables')}
                  className={`px-2 py-2 rounded transition-colors duration-300 ${activeComponent === 'receivables' ? 'text-white bg-blue-600' : 'text-gray-700 bg-white hover:bg-blue-50'}`}
                >
                  Receivables
                </button>
              </li>
              <li>
                <button
                  onClick={() => setActiveComponent('invoices')}
                  className={`px-2 py-2 rounded transition-colors duration-300 ${activeComponent === 'invoices' ? 'text-white bg-blue-600' : 'text-gray-700 bg-white hover:bg-blue-50'}`}
                >
                  Invoices
                </button>
              </li>
              <li>
                <button
                  onClick={() => setActiveComponent('quotations')}
                  className={`px-2 py-2 rounded transition-colors duration-300 ${activeComponent === 'quotations' ? 'text-white bg-blue-600' : 'text-gray-700 bg-white hover:bg-blue-50'}`}
                >
                  Quotations
                </button>
              </li>

              <li>
                <button
                  onClick={() => setActiveComponent('procurement')}
                  className={`px-2 py-2 rounded transition-colors duration-300 ${activeComponent === 'procurement' ? 'text-white bg-blue-600' : 'text-gray-700 bg-white hover:bg-blue-50'}`}
                >
                  Procurement Requests 
                </button>
              </li>

              <li>
                <button
                  onClick={() => setActiveComponent('transactions')}
                  className={`px-2 py-2 rounded transition-colors duration-300 ${activeComponent === 'transactions' ? 'text-white bg-blue-600' : 'text-gray-700 bg-white hover:bg-blue-50'}`}
                >
                  Transactions
                </button>
              </li>
              <li>
                <button
                  onClick={() => setActiveComponent('margins')}
                  className={`px-2 py-2 rounded transition-colors duration-300 ${activeComponent === 'margins' ? 'text-white bg-blue-600' : 'text-gray-700 bg-white hover:bg-blue-50'}`}
                >
                  Margin Admin
                </button>
              </li>

              <li>
                          {/* Post Transaction Button */}
                  <div className="p-1">
                    <div className="flex items-center justify-between ">
                      <button
                        onClick={() => setShowModal(true)}
                        className="px-2 py-1 text-sm bg-green-700 text-white rounded hover:bg-amber-600 transition duration-200"
                      >
                        New Posting
                      </button>
                    </div>      

                    {showModal && <TransactionForm setShowModal={setShowModal} />}
                  </div>
              </li>


            </ul>
    
          </nav>

          <div className="bg-gray-100 p-4 rounded-lg shadow-inner">
            {renderComponent()}
          </div>


    </>
  );
};

export default ExpenseTracker;
