// InventoryList.js

import React, { useState } from 'react';
import { formatCurrency } from '../Help/helper';
import { useNavigate } from 'react-router-dom';

const InventoryList = ({
  inventoryItems,
  openAllocationModal,
  handleEdit,
  handleDelete,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');
  const navigate = useNavigate();

  // Function to get field value for sorting
  const getFieldValue = (item, field) => {
    if (field === 'name') {
      return item.type === 'part' ? item.partName : item.description;
    } else {
      return item[field];
    }
  };

  // Filtered inventory items based on search term
  const filteredInventoryItems = inventoryItems.filter((item) => {
    const itemName = item.type === 'part' ? item.partName : item.description;
    const matchesSearch = itemName.toLowerCase().includes(searchTerm.toLowerCase());
    const hasPositiveQuantity = item.quantity > 0;
    return matchesSearch && hasPositiveQuantity;
  });
  

  // Sorted inventory items based on sort field and direction
  const sortedInventoryItems = [...filteredInventoryItems].sort((a, b) => {
    let fieldA = getFieldValue(a, sortField);
    let fieldB = getFieldValue(b, sortField);

    // Handle null or undefined values
    if (fieldA == null) fieldA = '';
    if (fieldB == null) fieldB = '';

    // If both fields are strings, compare as strings
    if (typeof fieldA === 'string' && typeof fieldB === 'string') {
      fieldA = fieldA.toLowerCase();
      fieldB = fieldB.toLowerCase();
      if (fieldA < fieldB) return sortDirection === 'asc' ? -1 : 1;
      if (fieldA > fieldB) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    }

    // If both fields are numbers, compare as numbers
    if (typeof fieldA === 'number' && typeof fieldB === 'number') {
      if (fieldA < fieldB) return sortDirection === 'asc' ? -1 : 1;
      if (fieldA > fieldB) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    }

    // If types are different, convert both to strings and compare
    fieldA = fieldA.toString().toLowerCase();
    fieldB = fieldB.toString().toLowerCase();
    if (fieldA < fieldB) return sortDirection === 'asc' ? -1 : 1;
    if (fieldA > fieldB) return sortDirection === 'asc' ? 1 : -1;
    return 0;
  });

  const handleViewDetails = (itemId) => {
    navigate(`/inventory/${itemId}`);
  };

  return (
    <>
      {/* Search and Sort Controls */}
      <div className="flex flex-col md:flex-row items-start md:items-center justify-between mb-4">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search items..."
          className="text-sm border rounded w-full md:w-1/3 mb-2 md:mb-0 p-2"
        />

        <div className="flex items-center space-x-2">
          <label className="mr-2">Sort by:</label>
          <select
            value={sortField}
            onChange={(e) => setSortField(e.target.value)}
            className="p-2 border rounded text-sm"
          >
            <option value="name">Name</option>
            <option value="quantity">Quantity</option>
            <option value="costPrice">Cost Price</option>
          </select>
          <button
            onClick={() =>
              setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
            }
            className="border rounded p-2"
          >
            {sortDirection === 'asc' ? 'Asc' : 'Desc'}
          </button>
        </div>
      </div>

      {/* Inventory Items Table */}
      <div className="overflow-x-auto">
      {sortedInventoryItems.length > 0 ? (
        <table className="min-w-full divide-y divide-gray-200 border">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Cost Price
              </th>
              <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Quantity
              </th>
              <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Supplier
              </th>
              <th className="px-2 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {sortedInventoryItems.map((item) => (
              <tr key={item.id}>
                <td className="px-2 py-1 whitespace-nowrap">
                  <button
                    onClick={() => handleViewDetails(item.id)}
                    className="text-blue-500 hover:underline text-sm"
                  >
                    {item.type === 'part' ? item.partName : item.description}
                  </button>
                </td>
                <td className="px-2 py-1 text-sm whitespace-nowrap">
                  {formatCurrency(item.costPrice)}
                </td>
                <td className="px-2 py-1 text-sm whitespace-nowrap">
                  {item.quantity}
                </td>
                <td className="px-2 py-1 text-sm whitespace-nowrap">
                  {item.supplier || 'N/A'}
                </td>
                <td className="px-2 py-1 whitespace-nowrap">
                  <div className="flex space-x-2">
                    <button
                      onClick={() => handleEdit(item)}
                      className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-1 px-1 text-sm rounded"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => openAllocationModal(item.id)}
                      className="bg-green-500 hover:bg-green-600 text-white font-bold py-1 px-1 rounded text-sm"
                    >
                      Allocate
                    </button>
                    <button
                      onClick={() => handleDelete(item.id)}
                      className="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-1 rounded text-sm"
                    >
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
          <p className="text-center text-gray-500 mt-4">
            No items available in inventory.
          </p>
        )}
      </div>
    </>
  );
};

export default InventoryList;
