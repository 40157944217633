import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../../firebase'; 

const CustomerDropdown = ({ onCustomerSelect }) => {
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    const fetchCustomers = async () => {
      const querySnapshot = await getDocs(collection(db, 'customers'));
      const customersData = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          value: doc.id,
          label: `${data.firstName} ${data.lastName}`,
          email: data.email,
          name: `${data.firstName} ${data.lastName}`,
          phone: data.phoneNumber
        };
      });
      setCustomers(customersData);
    };

    fetchCustomers();
  }, []);

  const handleChange = (selectedOption) => {
       if (onCustomerSelect && selectedOption) {
      onCustomerSelect(selectedOption);
    }
  };

  return <Select 
  options={customers} 
  onChange={handleChange} 
  className="form-select text-sm rounded border-gray-300 w-1/2 p-2 mt-4 mb-2" 
  
  />;
};

export default CustomerDropdown;
