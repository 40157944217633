// ActionButtons.js
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Fragment } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

// Helper function for class names
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const ActionsButton = ({
  customer,
  handleVehiclesButtonClick,
  openEditModal,
  deleteCustomer,
}) => {
  return (
    <div className="inline-flex rounded-md shadow-sm">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-2 py-1 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none">
            Actions
            <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-95"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right rounded-md shadow-lg bg-blue-500 ring-1 ring-black ring-opacity-5 focus:outline-none z-20">
            <div className="py-1">
              {/* New Profile Menu Item */}
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to={`/customers/profile/${customer.id}`} // Pass customer ID in the URL
                    className={classNames(
                      active ? 'bg-indigo-500' : '',
                      'block w-full text-left px-4 py-2 text-sm text-white'
                    )}
                  >
                    Profile
                  </Link>
                )}
              </Menu.Item>
              {/* Existing Vehicles Menu Item */}
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => handleVehiclesButtonClick(customer)}
                    className={classNames(
                      active ? 'bg-green-500' : '',
                      'block w-full text-left px-4 py-2 text-sm text-white'
                    )}
                  >
                    Vehicles
                  </button>
                )}
              </Menu.Item>
              {/* Existing Edit Menu Item */}
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => openEditModal(customer)}
                    className={classNames(
                      active ? 'bg-amber-500' : '',
                      'block w-full text-left px-4 py-2 text-sm text-white'
                    )}
                  >
                    Edit
                  </button>
                )}
              </Menu.Item>
              {/* Existing Delete Menu Item */}
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => deleteCustomer(customer.id)}
                    className={classNames(
                      active ? 'bg-purple-500' : '',
                      'block w-full text-left px-4 py-2 text-sm text-white'
                    )}
                  >
                    Delete
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default ActionsButton;
