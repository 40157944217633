import React from 'react';

const ProcessOverview = () => {
  const steps = [
    'Vehicle Check-In',
    'Initial Inspection',
    'Diagnostic Testing',
    'Estimate Preparation',
    'Customer Approval',
    'Repair and Maintenance Work',
    'Quality Control',
    'Vehicle Check-Out',
    'Follow-Up',
  ];

  return (
    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <h2 className="font-bold text-2xl mb-4">Automobile Service/Maintenance Workflow</h2>
      <div className="space-y-4">
        {steps.map((step, index) => (
          <div key={step} className="flex items-center">
            <div className="text-white bg-blue-500 rounded-full h-8 w-8 flex items-center justify-center">{index + 1}</div>
            <div className="ml-4 text-gray-700">{step}</div>
            {index < steps.length - 1 && (
              <svg className="h-5 w-5 mx-2 text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
              </svg>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProcessOverview;
