import React, { useState, useEffect } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { format, parse, startOfWeek, getDay } from 'date-fns';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const locales = {
  'en-US': require('date-fns/locale/en-US'),
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const LeaveCalendar = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchLeaveRequests = async () => {
      try {
        const leaveRequestsSnapshot = await getDocs(collection(db, 'leaveRequests'));
        const leaveEvents = [];

        for (const leaveDoc of leaveRequestsSnapshot.docs) {
          const data = leaveDoc.data();

          // Fetch employee name
          const employeeDocRef = doc(db, 'employees', data.employeeId);
          const employeeDocSnap = await getDoc(employeeDocRef);
          const employeeName = employeeDocSnap.exists()
            ? `${employeeDocSnap.data().firstName} ${employeeDocSnap.data().lastName}`
            : 'Unknown Employee';

          // Fetch designate name
          const designateDocRef = doc(db, 'employees', data.tempDesignateId);
          const designateDocSnap = await getDoc(designateDocRef);
          const designateName = designateDocSnap.exists()
            ? `${designateDocSnap.data().firstName} ${designateDocSnap.data().lastName}`
            : 'Unknown Designate';

          // Handle multiple dates for leave
          if (Array.isArray(data.leaveDates)) {
            data.leaveDates.forEach((leaveDate) => {
              leaveEvents.push({
                title: `${data.leaveType} - ${employeeName} (Designate: ${designateName})`,
                start: new Date(leaveDate.seconds * 1000),
                end: new Date(leaveDate.seconds * 1000),
              });
            });
          } else {
            leaveEvents.push({
              title: `${data.leaveType} - ${employeeName} (Designate: ${designateName})`,
              start: new Date(data.startDate.seconds * 1000),
              end: new Date(data.endDate.seconds * 1000),
            });
          }
        }

        setEvents(leaveEvents);
      } catch (error) {
        console.error('Failed to fetch leave requests:', error);
      }
    };

    fetchLeaveRequests();
  }, []);

  return (
    <div className="p-4 bg-white rounded-md shadow-md">
      <h2 className="text-lg font-medium text-gray-900">AYDENS Garage Leave Calendar</h2>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500, margin: '50px' }}
      />
    </div>
  );
};

export default LeaveCalendar;
