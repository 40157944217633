import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { Bar } from 'react-chartjs-2';

const TaskDashboard = () => {
  const [taskStats, setTaskStats] = useState({ new: 0, inProgress: 0, completed: 0 });

  useEffect(() => {
    const fetchTasks = async () => {
      const querySnapshot = await getDocs(collection(db, 'tasks'));
      const tasksData = querySnapshot.docs.map(doc => doc.data());

      const stats = tasksData.reduce(
        (acc, task) => {
          acc[task.status] += 1;
          return acc;
        },
        { new: 0, inProgress: 0, completed: 0 }
      );

      setTaskStats(stats);
    };

    fetchTasks();
  }, []);

  const data = {
    labels: ['New', 'In Progress', 'Completed'],
    datasets: [
      {
        label: 'Tasks',
        data: [taskStats.new, taskStats.inProgress, taskStats.completed],
        backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(75, 192, 192, 0.2)'],
        borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)', 'rgba(75, 192, 192, 1)'],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-lg font-semibold text-gray-900">Task Dashboard</h2>
      <div className="bg-white p-4 rounded-lg shadow">
        <Bar data={data} />
      </div>
    </div>
  );
};

export default TaskDashboard;
