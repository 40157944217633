import React, { useState, useEffect, useCallback } from 'react';
import JobCardDropdown from './JobCardDropdown';
import { db } from '../../../firebase'; 
import { collection, getDocs, doc, getDoc, query, where, Timestamp, addDoc } from 'firebase/firestore';
import CustomerDropdown from './CustomerDropdown';
import { PlusCircleIcon, XCircleIcon } from '@heroicons/react/20/solid';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { generateInvoiceNumber } from './generateInvoiceNumber';
import Spinner from './Spinner';
import { formatCurrency } from '../../Help/helper';

export const NewInvoiceModal = ({ closeModal }) => {
  const [selection, setSelection] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [customerPhone, setCustomerPhone] = useState('');
  const [vehicleMake, setVehicleMake] = useState('');
  const [vehicleModel, setVehicleModel] = useState('');
  const [registrationNumber, setRegistrationNumber] = useState('');
  const [cartItems, setCartItems] = useState([]);
  const [selectedJobCard, setSelectedJobCard] = useState(null);
  const [customerVehicles, setCustomerVehicles] = useState([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [includeTax, setIncludeTax] = useState(false);
  const [invoiceDate, setInvoiceDate] = useState('');

  // Function to calculate total receivable
  const calculateReceivable = useCallback(() => {
    const total = cartItems.reduce((acc, item) => {
      const price = parseFloat(item.price) || 0;
      const quantity = parseFloat(item.quantity) || 0;
      return acc + (price * quantity);
    }, 0);

    const taxAmount = includeTax ? total * 0.075 : 0; // 7.5% tax
    return total + taxAmount;
  }, [cartItems, includeTax]);

  useEffect(() => {
    // This useEffect is necessary to recalculate the total when cartItems or tax changes
    calculateReceivable();
  }, [cartItems, includeTax, calculateReceivable]);

  const calculateCartTotal = () => {
    return cartItems.reduce((acc, item) => {
      const price = parseFloat(item.price) || 0;
      const quantity = parseFloat(item.quantity) || 0;
      return acc + (price * quantity);
    }, 0);
  };
  
  // Function to calculate tax
  const calculateTax = () => {
    const cartTotal = calculateCartTotal();
    return includeTax ? cartTotal * 0.075 : 0; // 7.5% tax
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {

      const uniqueInvoiceNumber = await generateInvoiceNumber(db);
      const totalReceivable = calculateReceivable();


    const newInvoice = {
      approvalStatus: "Yes", 
      approvalDate:Timestamp.fromDate(new Date()), 
      cartItems: cartItems.map(item => ({
        costPrice: item.costPrice || "0", // Default to "0" if costPrice is not provided
        description: item.description,
        price: item.price,
        quantity: item.quantity
      })),
      commissionPaid: "No", 
      commissionPayable: 0,
      customerEmail: customerEmail,
      invoiceDate: Timestamp.fromDate(new Date(invoiceDate)),
      customerName: customerName,
      customerPhone: customerPhone,
      datePosted: Timestamp.fromDate(new Date()), 
      invoiceNumber: uniqueInvoiceNumber,
      invoiceStatus: "Pending", 
      paymentsReceived: [], 
      receivable: totalReceivable, 
      registrationNumber: registrationNumber,
      vehicleMake: vehicleMake,
      vehicleModel: vehicleModel,      
    };

    // Include jobCardNo if the selection is 'jobCard'
    if (selection === 'jobCard' && selectedJobCard) {
      newInvoice.jobCardNo = selectedJobCard.jobCardNo;
    }
  
      await addDoc(collection(db, 'newinvoices'), newInvoice);
      setLoading(false);
      toast.success("Invoice created successfully");
   
    } catch (error) {
      console.error("Error adding document: ", error);
      toast.error("Error creating invoice");
      setLoading(false);
    }
  };


  const handleCustomerSelect = (selectedCustomer) => {
     setCustomerEmail(selectedCustomer.email);
  setCustomerName(selectedCustomer.name);
  setCustomerPhone(selectedCustomer.phone);
  setSelectedCustomerId(selectedCustomer.value); // Ensure this is correctly set
  // Reset vehicle information
  setVehicleMake('');
  setVehicleModel('');
  setRegistrationNumber('');
  };

  const handleJobCardSelect = (jobCard) => {
    setSelectedJobCard(jobCard);
    setVehicleMake(jobCard.vehicleMake);
    setVehicleModel(jobCard.vehicleModel);
    setRegistrationNumber(jobCard.registrationNumber);
  };

  const fetchCustomerDetails = async () => {
    if (!selectedJobCard) {
      toast.error("Please select a job card first.");
      return;
    }
       
    const vehicleDocRef = doc(db, 'vehicles', selectedJobCard.vehicleId);
    const vehicleDocSnapshot = await getDoc(vehicleDocRef);
    
  
    if (vehicleDocSnapshot.exists()) {
      const vehicleData = vehicleDocSnapshot.data();
     
  
      // Fetch customer data using the customerId from vehicle data
      const customerDocRef = doc(db, 'customers', vehicleData.customerId);
      const customerDocSnapshot = await getDoc(customerDocRef);
    
  
      if (customerDocSnapshot.exists()) {
        const customerData = customerDocSnapshot.data();
       
        setCustomerName(`${customerData.firstName} ${customerData.lastName}`);
        setCustomerEmail(customerData.email);
        setCustomerPhone(customerData.phoneNumber);
      } else {
        toast.error("Customer details not found.");
      }
    } else {
      toast.error("Vehicle details not found.");
    }
  };
  

  const fetchVehiclesForCustomer = async () => {
    if (!selectedCustomerId) {
      toast.error("Please select a customer first.");
      return;
    }
    const vehiclesQuery = query(collection(db, 'vehicles'), where('customerId', '==', selectedCustomerId));
    const querySnapshot = await getDocs(vehiclesQuery);  
    if (!querySnapshot.empty) {
      const vehicles = querySnapshot.docs.map(doc => ({
        id: doc.id,
        vehicleMake: doc.data().vehicleMake,
        vehicleModel: doc.data().vehicleModel,
        registrationNumber: doc.data().registrationNumber
      }));
      setCustomerVehicles(vehicles);
    } else {
      toast.error("No vehicles found for the selected customer.");
    }
  };
  

  const handleVehicleSelect = (vehicleId) => {
    const selectedVehicle = customerVehicles.find(vehicle => vehicle.id === vehicleId);
    if (selectedVehicle) {
      setVehicleMake(selectedVehicle.vehicleMake);
      setVehicleModel(selectedVehicle.vehicleModel);
      setRegistrationNumber(selectedVehicle.registrationNumber);
    }
  };
  
  
  
// Shopping Cart
  const handleAddCartItem = () => {
    setCartItems([...cartItems, { description: '', price: '', quantity: '' }]);
  };

  const handleRemoveCartItem = (index) => {
    setCartItems(cartItems.filter((_, i) => i !== index));
  };
  
  const handleCartItemChange = (index, field, value) => {
    const newCartItems = [...cartItems];
    newCartItems[index][field] = value;
    setCartItems(newCartItems);
  };


  if (loading) {
    return <Spinner />; // Show spinner when loading
  }



  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 ">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-5xl w-full mx-4 ">
      <div className="sticky top-0 z-10 bg-white p-4 shadow">
        <div className="flex justify-between items-center pt-6">
          <h2 className="text-2xl font-bold text-gray-800">Create New Invoice</h2>
          
          <button 
            type='button' 
            onClick={closeModal} 
            className="bg-red-500 text-white px-4 rounded hover:bg-red-700 transition duration-150 ease-in-out">
            Close
          </button>
         
        </div>
        <p className="mt-1 text-sm leading-6 text-gray-600">
          Note that Invoices are Automatically Generated from Approved JobCard Quotation.<br/>
          Indicate if this is a supplementary Invoice to an existing Job Card or a Standalone Bill  for a Registered Customer.</p>
            </div>
            
        <form onSubmit={handleSubmit} className="space-y-1">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Left Column */}
                        <div className='mt-20'>
                      <label className="block text-sm font-medium text-gray-700 mb-1 mt-4"> Select Invoice Type</label>
                      <div className="flex gap-2">
                
                  <select 
                    value={selection} 
                    onChange={(e) => setSelection(e.target.value)}
                    className="form-select text-sm rounded border-gray-300 w-full p-2 mt-4 mb-2"
                  >
                    <option value="">Select Option</option>
                    <option value="jobCard">For Job Card</option>
                    <option value="customer">For Customer</option>
                  </select>
                </div>

                {selection === 'jobCard' && (
                  <div className="flex items-center">
                      <JobCardDropdown onJobCardSelect={handleJobCardSelect} />
                    <button type="button"
                      onClick={fetchCustomerDetails}
                      className="ml-2 bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition duration-150 ease-in-out"
                        >
                          Get Customer Details
                    </button>                
                  </div>
                   )}
  
              {selection === 'customer' && (
                <div className="flex items-center">
                  <CustomerDropdown onCustomerSelect={handleCustomerSelect} />
                  <button
                    type="button"
                    onClick={fetchVehiclesForCustomer}
                    className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition duration-150 ease-in-out"
                  >
                    Get Vehicles
                  </button>
                </div>
              )}
  
              {selection === 'customer' && customerVehicles.length > 0 && (
                <div className="mb-4">
                  <select 
                    className="form-select text-sm rounded border-gray-300 w-full p-2"
                    onChange={(e) => handleVehicleSelect(e.target.value)}
                  >
                    <option value="">Select a Vehicle</option>
                    {customerVehicles.map((vehicle) => (
                      <option key={vehicle.id} value={vehicle.id}>
                        {`${vehicle.vehicleMake} ${vehicle.vehicleModel} - ${vehicle.registrationNumber}`}
                      </option>
                              ))}
                              </select>
                            </div>
                          )}
                  
                          {/* Customer and Vehicle Details */}
                          <div className="space-y-4">

                              {/* Customer Name */}
                              <div>
                              <label className="block text-sm font-medium text-gray-700">Full Name</label>
                              <input
                                type="text"
                                value={customerName}
                                onChange={(e) => setCustomerName(e.target.value)}
                                placeholder="Customer Name"
                                className="block w-full text-sm rounded-md p-2 border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                              />
                            </div>


                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            {/* Customer Email */}
                            <div className="sm:col-span-3">
                              <label className="block text-sm font-medium text-gray-700">Email</label>
                              <input
                                type="email"
                                value={customerEmail}
                                onChange={(e) => setCustomerEmail(e.target.value)}
                                placeholder="Customer Email"
                                className="block w-full text-sm rounded-md p-2 border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                />                              
                            </div>
                  
                          
                  
                            {/* Customer Phone */}
                            <div className="sm:col-span-3">
                              <label className="block text-sm font-medium text-gray-700">Phone</label>
                              <input
                                type="text"
                                value={customerPhone}
                                onChange={(e) => setCustomerPhone(e.target.value)}
                                placeholder="Customer Phone"
                                className="block w-full text-sm rounded-md p-2 border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                              />
                            </div>
                            </div>
                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                      
                            {/* Vehicle Make */}
                            <div className="sm:col-span-2">
                              <label className="block text-sm font-medium text-gray-700">Vehicle Make</label>
                              <input
                                type="text"
                                value={vehicleMake}
                                onChange={(e) => setVehicleMake(e.target.value)}
                                placeholder="Vehicle Make"
                                className="block w-full text-sm rounded-md p-2 border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                              />
                            </div>
                  
                            {/* Vehicle Model */}
                            <div className="sm:col-span-2">
                              <label className="block text-sm font-medium text-gray-700">Vehicle Model</label>
                              <input
                                type="text"
                                value={vehicleModel}
                                onChange={(e) => setVehicleModel(e.target.value)}
                                placeholder="Vehicle Model"
                                className="block w-full text-sm rounded-md p-2 border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                              />
                            </div>
                  
                            {/* Registration Number */}
                            <div className="sm:col-span-2">
                              <label className="block text-sm font-medium text-gray-700">Plate Number</label>
                              <input
                                type="text"
                                value={registrationNumber}
                                onChange={(e) => setRegistrationNumber(e.target.value)}
                                placeholder="Plate Number"
                                className="block w-full text-sm rounded-md p-2 border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                              />
                            </div>
                          </div>


                          <div className="sm:col-span-3">
                          <label htmlFor="invoiceDate" className="block text-sm font-medium text-gray-700">Invoice Date</label>
                          <input
                            type="date"
                            name="invoiceDate"
                            required
                            id="invoiceDate"
                            value={invoiceDate}
                            onChange={(e) => setInvoiceDate(e.target.value)}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>


                          </div>


                        </div>
                  
                        {/* Right Column */}
                        <div className='py-4 mt-20'>
                           <div className="flex-1 overflow-auto max-h-96">
                            <h3 className="text-lg font-semibold text-gray-800">Invoice Items</h3>

                                {cartItems.map((item, index) => (
                                          <div key={index} className="flex items-center space-x-2 pb-2">
                                            {/* Description */}
                                            <div className="flex-grow w-3/5">
                                              <input
                                                type="text"
                                                value={item.description}
                                                onChange={(e) => handleCartItemChange(index, 'description', e.target.value)}
                                                placeholder="Description"
                                                className="block w-full text-xs rounded-md p-2 border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                              />
                                            </div>

                                              {/* Price */}
                                              <div className="w-1/3">
                                                <div className="relative">
                                                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                                    <span className="text-gray-500 sm:text-xs">₦</span>
                                                  </div>
                                                  <input
                                                    type="text"
                                                    name="price"
                                                    value={item.price}
                                                    onChange={(e) => handleCartItemChange(index, 'price', e.target.value)}
                                                    id="price"
                                                    className="block w-full pl-7 pr-12 py-1.5 text-xs rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                                    placeholder="0.00"
                                                    aria-describedby="price-currency"
                                                  />
                                                  <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                                                    <span className="text-gray-500 sm:text-xs" id="price-currency">NGN</span>
                                                  </div>
                                                </div>
                                              </div>

                                          {/* Quantity */}
                                          <div className="w-1/5">
                                            <select
                                              value={item.quantity}
                                              onChange={(e) => handleCartItemChange(index, 'quantity', e.target.value)}
                                              className="block w-full p-2 text-xs rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                            >
                                              <option value="" disabled>Qty</option>
                                              {[...Array(100).keys()].map((num) => (
                                                <option key={num + 1} value={num + 1}>{num + 1}</option>
                                              ))}
                                            </select>
                                          </div>

                                          {/* Delete Button */}
                                          <div className="flex items-center justify-center w-1/5">
                                            <button
                                              type="button"
                                              onClick={() => handleRemoveCartItem(index)}
                                              className="text-red-500 hover:text-red-700"
                                            >
                                              <XCircleIcon className="h-5 w-5" />
                                            </button>
                                          </div>
                                        </div>
                                   ))}


                           
                            <button
                              type="button"
                              onClick={handleAddCartItem}
                              className="flex items-center space-x-2 bg-blue-500 text-white p-2 rounded mt-2 hover:bg-blue-600"
                            >
                              <PlusCircleIcon className="h-5 w-5" />
                              <span>Add to Cart</span>
                            </button>
                  
                           
                              </div>

                              <div className="mt-4">
                              <label className="flex items-center space-x-3">
                                <input
                                  type="checkbox"
                                  checked={includeTax}
                                  onChange={(e) => setIncludeTax(e.target.checked)}
                                  className="form-checkbox rounded text-blue-600 shadow-sm"
                                  />
                                  <span className="text-sm text-gray-700">Include 7.5% Tax</span>
                                  </label>
                                  <div className="mt-4 text-sm">
                                    <p>SUBTOTAL: {formatCurrency(calculateCartTotal())}</p>
                                    <p>VAT (7.5%): {formatCurrency(calculateTax())}</p>
                                    <p className="font-bold text-2xl">Grand Total: {formatCurrency(calculateReceivable())}</p>
                                  </div>
                                </div>
                            </div>
                          </div>
                          
                          <div className="flex justify-end pb-20">
                            <button 
                              type="submit" 
                              className="bg-green-500 text-white px-5 py-2 rounded shadow mb-4 hover:bg-green-600 transition duration-150 ease-in-out"
                            >
                              Create Invoice
                            </button>
                          </div>
                        </form>
                      </div>
                      </div>
);

                            }