import React, { useState, useEffect } from 'react';
import { collection, query, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../../../firebase'; 
import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/20/solid';
import AddFeedback from './AddFeedback';
import FeedbackDetails from './FeedBackDetails';
import Modal from 'react-modal';


Modal.setAppElement('#root');

const AllFeedback = () => {
    const [feedbackList, setFeedbackList] = useState([]);
    const [sortField, setSortField] = useState('date'); 
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; 
    const totalPages = Math.ceil(feedbackList.length / itemsPerPage);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const [isAddFeedbackModalOpen, setIsAddFeedbackModalOpen] = useState(false);
    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
    const [selectedFeedback, setSelectedFeedback] = useState(null);

    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }


    const handleDetails = (feedback) => {
        setSelectedFeedback(feedback);
        setIsDetailsModalOpen(true);
      };

    useEffect(() => {
        const fetchFeedback = async () => {
            setIsLoading(true);
            try {
                const q = query(collection(db, "feedbacks"), orderBy(sortField));
                const querySnapshot = await getDocs(q);
                const feedbacks = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setFeedbackList(feedbacks);
            } catch (error) {
                console.error("Error fetching feedback: ", error);
            }
            setIsLoading(false);
        };

        fetchFeedback();
    }, [sortField]);

    const handleSortChange = (e) => {
        setSortField(e.target.value);
    };

    
    const handleNewFeedback = () => {
        setIsAddFeedbackModalOpen(true);
    }

    const handleClose = () => setIsAddFeedbackModalOpen(false);

    useEffect(() => {
        const fetchCustomersAndFeedback = async () => {
            setIsLoading(true);
            try {
                // Fetch customers
                const customerSnapshot = await getDocs(collection(db, "customers"));
                const customers = customerSnapshot.docs.reduce((acc, doc) => ({
                    ...acc,
                    [doc.id]: { ...doc.data() }
                }), {});
                
                // Fetch feedback
                const q = query(collection(db, "feedbacks"), orderBy(sortField));
                const feedbackSnapshot = await getDocs(q);
                const feedbacksWithCustomerName = feedbackSnapshot.docs.map(doc => {
                    const feedbackData = doc.data();
                    const customerData = customers[feedbackData.customerId];
                    // Combine feedback with customer name
                    return {
                        ...feedbackData,
                        customerName: customerData ? `${customerData.firstName} ${customerData.lastName}` : "Unknown",
                        id: doc.id
                    };
                });
                setFeedbackList(feedbacksWithCustomerName);
            } catch (error) {
                console.error("Error fetching data: ", error);
            } finally {
                setIsLoading(false);
            }
        };
    
        fetchCustomersAndFeedback();
    }, [sortField]);
    

    if (isLoading) {
        return (
        <div className="flex justify-center items-center">
            <div className="animate-spin rounded-full h-22 w-22 border-b-2 border-gray-900"></div>
        </div>);
    }


    return (
        <div className="container mx-auto p-4">
            <div className="mb-4">
               
                <label htmlFor="sortField" className="block text-sm font-medium leading-6 text-gray-900">Sort By:</label>
                <select onChange={handleSortChange} value={sortField} 
                className="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option value="date">Date</option>
                    <option value="status">Status</option>
                    <option value="customerName">Customer Name</option>
                </select>
                               
                    <button type='button'
                    onClick={handleNewFeedback}
                      className="rounded bg-indigo-600 px-2 py-1 ml-8 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Add Feedback
                    </button>                

            </div>

            
            <div className="overflow-x-auto">
                <table className="min-w-full leading-normal">
                <thead>
                        <tr>
                            <th className="px-2 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Customer Name</th>
                            <th className="px-2 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Feedback Type</th>
                            <th className="px-2 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Status</th>
                            <th className="px-2 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Date</th>
                            <th className="px-2 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Update</th>
                        </tr>
                    </thead>
                    <tbody>
                        {feedbackList.slice(indexOfFirstItem, indexOfLastItem).map(feedback => (
                            <tr key={feedback.id}>
                                <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm">{feedback.customerName}</td>
                                <td className={`px-2 py-2 border-b border-gray-200 text-sm ${feedback.feedbackType === "Complaint" ? "bg-red-500 text-white font-semibold" : ""} ${feedback.feedbackType === "Commendation" ? "bg-green-500 text-white font-semibold" : ""}`}>
                                    {feedback.feedbackType}
                                </td>
                                <td className={`px-2 py-2 border-b border-gray-200 text-sm ${feedback.status === "Open" ? "bg-yellow-100" : ""} ${feedback.status === "Resolved" ? "bg-green-500 text-white font-semibold" : ""}`}>
                                    {feedback.status}
                                </td>
                                <td className="px-2 py-2 border-b border-gray-200 bg-white text-sm">{feedback.date.toDate().toLocaleDateString()}</td>
                                <td>
                                    <button type='button' onClick={() => handleDetails(feedback)} className="rounded bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Details</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <nav className="flex items-center justify-between border-t mt-4 border-gray-200 px-4 sm:px-0">
                <div className="-mt-px flex w-0 flex-1">
                    <button onClick={() => paginate(currentPage - 1)} className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700">
                        <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                        Previous
                    </button>
                </div>
                <div className="hidden md:-mt-px md:flex">
                    {pageNumbers.map(number => (
                        <button key={number} onClick={() => paginate(number)} className={`inline-flex items-center border-t-2 ${currentPage === number ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'} px-4 pt-4 text-sm font-medium`}>
                            {number}
                        </button>
                    ))}
                </div>
                <div className="-mt-px flex w-0 flex-1 justify-end">
                    <button onClick={() => paginate(currentPage + 1)} className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700">
                        Next
                        <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                    </button>
                </div>
            </nav>

            {isAddFeedbackModalOpen && (
                <Modal isOpen={isAddFeedbackModalOpen} onRequestClose={handleClose}>
                    <AddFeedback onRequestClose={handleClose} />
                </Modal>
            )}

            {isDetailsModalOpen && selectedFeedback && (
                <FeedbackDetails isOpen={isDetailsModalOpen} onRequestClose={() => setIsDetailsModalOpen(false)} feedback={selectedFeedback} customerName={selectedFeedback.customerName} />
            )}
        </div>
    );
};

export default AllFeedback;