
import React, { useEffect } from 'react';
import { collection, getDocs, updateDoc, doc, query, where } from 'firebase/firestore';
import { db } from '../../firebase'; 



function generateCustomerNumber(firstName, lastName, sequentialNumber = 1) {
  const timestamp = new Date().toISOString().slice(2, 10).replace(/-/g, '');
  const initials = (firstName.slice(0, 2) + lastName.slice(0, 2)).toUpperCase();
  const randomChar = String.fromCharCode(65 + Math.floor(Math.random() * 26));
  const sequentialStr = String(sequentialNumber).padStart(3, '0');
  return `${timestamp}${initials}${randomChar}${sequentialStr}`;
}

// Function to check if a customer number is unique
async function isCustomerNumberUnique(customerNumber) {
  const customersRef = collection(db, 'customers');
  const q = query(customersRef, where('customerNumber', '==', customerNumber));
  const querySnapshot = await getDocs(q);
  return querySnapshot.empty; // Returns true if no documents are found
}

const UpdateCustomerNumbers = () => {

  useEffect(() => {
    const updateCustomerNumbers = async () => {
      try {
        const customersRef = collection(db, 'customers');
        const customersSnapshot = await getDocs(customersRef);
        
        for (const customerDoc of customersSnapshot.docs) {
          const customerData = customerDoc.data();
          
          // Check if customerNumber doesn't exist
          if (!customerData.customerNumber) {
            let uniqueCustomerNumber = null;
            let isUnique = false;
            let attempts = 0;

            // Generate a unique customer number
            while (!isUnique && attempts < 5) {
              const newCustomerNumber = generateCustomerNumber(customerData.firstName, customerData.lastName);
              isUnique = await isCustomerNumberUnique(newCustomerNumber);
              
              if (isUnique) {
                uniqueCustomerNumber = newCustomerNumber;
              } else {
                console.log(`Duplicate customerNumber found: ${newCustomerNumber}, regenerating...`);
              }
              
              attempts++;
            }

            if (!uniqueCustomerNumber) {
              console.error(`Failed to generate a unique customer number for ${customerDoc.id} after 5 attempts.`);
              continue;
            }

            // Update the customer document with the unique customer number
            const customerRef = doc(db, 'customers', customerDoc.id);
            await updateDoc(customerRef, {
              customerNumber: uniqueCustomerNumber,
            });

            console.log(`Updated customer ${customerDoc.id} with customerNumber: ${uniqueCustomerNumber}`);
          }
        }

        console.log('Customer numbers update completed.');
      } catch (error) {
        console.error('Error updating customer numbers:', error);
      }
    };

    updateCustomerNumbers();
  }, []);

  return (
    <div>
      <h1>Updating Customer Numbers...</h1>
      <p>This operation will run only once. Check the console for progress.</p>
    </div>
  );
};

export default UpdateCustomerNumbers;